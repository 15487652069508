import React, { useMemo } from "react";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { useCloudMonitoringData } from "../../hooks/useRecommendationsV2";

const DetailedInsightGraphs = ({ selectedVm }) => {
  const getCloudMonitoringDataQuery = useCloudMonitoringData(selectedVm?.instance_id, selectedVm?.instance_name);

  const graphData = getCloudMonitoringDataQuery.data?.[0] ?? [];

  const cpuMax = useMemo(() =>
      graphData.cpu ? Math.max(...graphData.cpu.map((d) => parseInt(d.maximum) || 0)) : 0,
    [graphData.cpu],
  );

  const memoryMax = useMemo(() =>
      graphData.memory ? Math.max(...graphData.memory.map((d) => parseInt(d.used_memory_mb) || 0)) : 0,
    [graphData.memory],
  );

  const networkInMax = useMemo(() =>
      graphData.network?.in ? Math.max(...graphData.network.in.map((d) => parseInt(d.mb) || 0)) : 0,
    [graphData.network?.in],
  );

  const networkOutMax = useMemo(() =>
      graphData.network?.out ? Math.max(...graphData.network.out.map((d) => parseInt(d.mb) || 0)) : 0,
    [graphData.network?.out],
  );

  const diskReadMax = useMemo(() =>
      graphData.disk?.read ? Math.max(...graphData.disk.read.map((d) => parseInt(d.mb) || 0)) : 0,
    [graphData.disk?.read],
  );

  const diskWriteMax = useMemo(() =>
      graphData.disk?.write ? Math.max(...graphData.disk.write.map((d) => parseInt(d.mb) || 0)) : 0,
    [graphData.disk?.write],
  );


  if (graphData.length === 0) {
    return (
      <Paper
        elevation={3}
        sx={{
          height: "100%",
          boxShadow: "none",
        }}
      >
        <Box
          style={{
            border: "1px solid rgba(33, 150, 243, 0.5)",
            padding: "10px",
            borderRadius: "4px",
            marginTop: "20px",
          }}
        >
          {getCloudMonitoringDataQuery.isLoading ? "Insights data loading..." : "Graph Data is not available."}
        </Box>
      </Paper>
    );
  }


  return (
    <Paper
      elevation={3}
      sx={{
        // height: "100%",
        boxShadow: "none",
      }}
    >
      <Typography variant={"h5"} marginTop={"20px"}>Detailed Insights</Typography>
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "20px",
          height: "100%",
          margin: "20px 0 20px 0",
        }}
      >

        {graphData.cpu && graphData.cpu.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">CPU usage</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.cpu.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                  interval="preserveStartEnd"
                />
                <YAxis
                  label={{ value: "Utilization(%)", angle: -90, position: "left", dy: -40 }}
                  domain={[0, cpuMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="maximum"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="Maximum"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.network?.in && graphData.network.in.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Network In</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.network?.in.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                  interval="preserveStartEnd"
                />
                <YAxis
                  label={{ value: "MB", angle: -90, position: "left" }}
                  domain={[0, networkInMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="mb"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="bytes"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.disk?.read && graphData.disk.read.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Read Operations</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.disk?.read.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                />
                <YAxis
                  label={{ value: "MB", angle: -90, position: "left" }}
                  domain={[0, diskReadMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="mb"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="bytes"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.memory && graphData.memory.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Memory Usage Monitoring</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.memory.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                />
                <YAxis
                  label={{ value: "MB", angle: -90, position: "left" }}
                  domain={[0, memoryMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="used_memory_mb"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="bytes"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.network?.out && graphData.network?.out.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Network Out</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.network?.out.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                  interval="preserveStartEnd"
                />
                <YAxis
                  label={{ value: "MB", angle: -90, position: "left" }}
                  domain={[0, networkOutMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="mb"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="bytes"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.disk?.write && graphData.disk?.write.length > 0 ? (
          <Box
            width="100%"
            height="300px"
            sx={{
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Write Operations</Typography>
            </Box>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart data={graphData.disk?.write.filter((_, idx) => idx % 5 === 0) ?? []}
                         margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <XAxis
                  dataKey="date"
                  label={{ value: "Duration", position: "bottom", dy: 25 }}
                  tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                  tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                />
                <YAxis
                  label={{ value: "MB", angle: -90, position: "left" }}
                  domain={[0, diskWriteMax]}
                  min={0}
                />
                <Tooltip />

                <Legend
                  wrapperStyle={{
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  iconType={"circle"}
                />

                <Line
                  type="monotone"
                  dataKey="mb"
                  stroke="#1E90FF"
                  strokeWidth={1}
                  name="bytes"
                  dot={false}
                  connectNulls={true}
                />
                )
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : null}
        {graphData.volume?.map((volume) => {
          const maxRead = Math.max(...volume.read.map((d) => parseInt(d.mb) || 0));
          const maxWrite = Math.max(...volume.write.map((d) => parseInt(d.mb) || 0));
          return (
            <>
              <Box
                width="100%"
                height="300px"
                sx={{
                  padding: "10px",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <Box marginBottom={2}>
                  <Typography variant="h5">{volume.volume_id} : Read Operations</Typography>
                </Box>
                <ResponsiveContainer width="100%" height="70%">
                  <LineChart data={volume?.read.filter((_, idx) => idx % 5 === 0) ?? []}
                             margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <XAxis
                      dataKey="date"
                      label={{ value: "Duration", position: "bottom", dy: 25 }}
                      tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                      tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                    />
                    <YAxis
                      label={{ value: "MB", angle: -90, position: "left" }}
                      domain={[0, maxRead]}
                      min={0}
                    />
                    <Tooltip />

                    <Legend
                      wrapperStyle={{
                        top: -10,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      iconType={"circle"}
                    />

                    <Line
                      type="monotone"
                      dataKey="mb"
                      stroke="#1E90FF"
                      strokeWidth={1}
                      name="bytes"
                      dot={false}
                      connectNulls={true}
                    />
                    )
                  </LineChart>
                </ResponsiveContainer>
              </Box>
              <Box
                width="100%"
                height="300px"
                sx={{
                  padding: "10px",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <Box marginBottom={2}>
                  <Typography variant="h5">{volume.volume_id} : Write Operations</Typography>
                </Box>
                <ResponsiveContainer width="100%" height="70%">
                  <LineChart data={volume?.write.filter((_, idx) => idx % 5 === 0) ?? []}
                             margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <XAxis
                      dataKey="date"
                      label={{ value: "Duration", position: "bottom", dy: 25 }}
                      tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                      tickFormatter={(value, index) => index % 10 === 0 ? `${value.split(" ")[0]}` : ""}
                    />
                    <YAxis
                      label={{ value: "MB", angle: -90, position: "left" }}
                      domain={[0, maxWrite]}
                      min={0}
                    />
                    <Tooltip />

                    <Legend
                      wrapperStyle={{
                        top: -10,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                      iconType={"circle"}
                    />

                    <Line
                      type="monotone"
                      dataKey="mb"
                      stroke="#1E90FF"
                      strokeWidth={1}
                      name="bytes"
                      dot={false}
                      connectNulls={true}
                    />
                    )
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </>
          );
        })}
      </Box>
    </Paper>
  );
};
export default DetailedInsightGraphs;
