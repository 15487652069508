import { useQuery } from "@tanstack/react-query";

import { fetch } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { QUERY_KEY } from "../utils/queryKeys";

const checkIfDataFetched = async () => {
  try {
    const response = await fetch("/dashboard/check-if-data-fetched/");
    return response.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useData = (stopPolling = false) => {
  const checkIfDataFetchedQuery = useQuery({
    queryKey: [QUERY_KEY.CHECK_IF_DATA_FETCHED],
    queryFn: checkIfDataFetched,
    refetchInterval: 30000, // 30 seconds
    enabled: !stopPolling,
  });

  return {
    checkIfDataFetchedQuery,
  };
};
