import React, { useMemo } from "react";
import {
  Box,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Alert,
} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useCostByAccountV2 } from "../../../hooks/useCostsV2";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
} from "../../../utils/constants";

const CardContentStyle = {
  padding: 0,
  margin: 0,
  height: "100%",
};

const GRAPH_HEIGHT = 300;

const truncateText = (text) => {
  return text.length > 10 ? text.substring(0, 5) + "..." : text;
};

function BarChartView({ top5Accounts, isLoading }) {
  const maxCost = useMemo(
    () =>
      top5Accounts ? Math.max(...top5Accounts.map((item) => item.cost)) : 0,
    [top5Accounts]
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={GRAPH_HEIGHT} />;
  }

  return (
    <>
      <Box sx={{ width: "100%", height: GRAPH_HEIGHT }}>
        <ResponsiveContainer>
          <BarChart
            data={top5Accounts !== null ? top5Accounts : []}
            height={GRAPH_HEIGHT}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="label"
              tick={{
                dy: 10,
              }}
              tickSize={0}
              textAnchor="start"
              interval={0}
              tickFormatter={truncateText}
              scale="band"
            />
            <YAxis domain={[0, maxCost]} />
            <Tooltip
              cursor={false}
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <Box
                      style={{
                        backgroundColor: "white",
                        padding: "4px",
                        border: "1px solid #2196F3",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography variant="bodySmall">{label}</Typography>
                      <br />
                      <Typography variant="bodySmall">
                        cost : {payload[0].value}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              }}
            />
            <Bar
              dataKey="cost"
              fill="#2196F3"
              barSize={20}
              activeBar={{ fill: "#1565C0" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

function TabularView({ allAccounts, isLoading }) {
  if (isLoading) {
    return <Skeleton variant="rectangular" height={GRAPH_HEIGHT} />;
  }

  return (
    <Box style={{ maxHeight: GRAPH_HEIGHT, overflowY: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account Names</TableCell>
            <TableCell>Cost Percentage</TableCell>
            <TableCell>Cost {`($)`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allAccounts?.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell>{row?.percentage}</TableCell>
              <TableCell>{row.cost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const CostByAccountV2 = (props) => {
  const { cloudProvider, startDate, endDate, showTabularView, activeKey } =
    props;

  const {
    data: costByAccount,
    isLoading,
    isFetching,
    error,
  } = useCostByAccountV2(cloudProvider, startDate, endDate, activeKey);

  const data = useMemo(() => {
    if (costByAccount && costByAccount?.length > 0) {
      if (cloudProvider === CLOUD_PROVIDER_AWS) {
        return costByAccount.map((item) => ({
          label: item.account,
          percentage: item.percentage,
          cost: item.cost,
        }));
      }
      if (cloudProvider === CLOUD_PROVIDER_AZURE) {
        return costByAccount.map((item) => ({
          label: item.resource_group,
          percentage: item.percentage,
          cost: item.cost,
        }));
      }
      if (cloudProvider === CLOUD_PROVIDER_GCP) {
        return costByAccount.map((item) => ({
          label: item.project,
          percentage: item.percentage,
          cost: item.cost,
        }));
      }
      if (cloudProvider === CLOUD_PROVIDER_OCI) {
        return costByAccount.map((item) => ({
          label: item.compartment,
          percentage: item.percentage,
          cost: item.cost,
        }));
      }
    }
    return [];
  }, [costByAccount, cloudProvider]);

  const top5Accounts = data?.sort((a, b) => b.cost - a.cost).slice(0, 5) ?? [];

  return (
    <Box style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <CardHeader
          style={{ margin: 0, padding: "12px 16px" }}
          title="Cost By Account"
        />
        {!showTabularView && (
          <Box
            style={{
              border: "1px solid #2196F3",
              borderRadius: "8px",
              padding: "8px",
              height: "fit-content",
            }}
          >
            <Typography variant="labelLarge">Top 5</Typography>
          </Box>
        )}
      </Box>
      <CardContent style={CardContentStyle}>
        {error ? (
          <Box>
            <Alert severity="warning" sx={{ my: 1 }}>
              {error}
            </Alert>
          </Box>
        ) : (
          <>
            {showTabularView ? (
              <TabularView
                allAccounts={data}
                isLoading={isLoading || isFetching}
              />
            ) : (
              <BarChartView
                top5Accounts={top5Accounts}
                isLoading={isLoading || isFetching}
              />
            )}
          </>
        )}
      </CardContent>
    </Box>
  );
};

export default CostByAccountV2;
