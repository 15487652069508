import React from "react";
import Alert from "@mui/material/Alert";
import { CardHeader, CardContent, Divider } from "@material-ui/core";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import history from "../../utils/history";
import {
  ROUTE_CROSS_CLOUD_RECOMMENDATIONS,
  ROUTE_RECOMMENDATION_DETAILS,
} from "../../routers/routes";
import { Card } from "../../ui/Card";
import { useDashBoardRecommendationsV2 } from "../../hooks/useRecommendationsV2";
import CrossCloudRecommendation from "../../assets/png/cross_cloud_recommendation.png";

const CardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
  p: 0,
  m: 0,
  boxShadow:
    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
};

const getDataObject = (item) => {
  switch (item.type.id) {
    case 1:
      return {
        count: item.type.number_of_instances,
        labelSplit: item.type.label.split(" "),
      };
    case 2:
      return {
        count: item.type.number_of_databases,
        labelSplit: item.type.label.split(" "),
      };
    case 3:
      return {
        count: item.type.number_of_storages,
        labelSplit: item.type.label.split(" "),
      };
    default: {
      return;
    }
  }
};

const RecommendationCard = ({ item, selectedCloudProvider, selectedKey }) => {
  const modifiedData = getDataObject(item);
  const labelBeforeCountOfItems = `${modifiedData.labelSplit[0]} ${modifiedData.labelSplit[1]} `;
  const labelAfterCountOfItems = `${modifiedData.labelSplit[2]}`;

  let exploreButtonUrl = `${ROUTE_RECOMMENDATION_DETAILS}?service_type=${item.type.label
    ?.split(" ")[2]
    ?.toLowerCase()}`;

  if (selectedCloudProvider) {
    exploreButtonUrl = `${exploreButtonUrl}&cloud_provider=${selectedCloudProvider}`;
  }

  if (selectedKey) {
    exploreButtonUrl = `${exploreButtonUrl}&key_id=${selectedKey}`;
  }

  return (
    <Card style={CardStyle}>
      <CardContent style={{ padding: "10px" }}>
        <Box textAlign="center">
          <Typography
            color="#358A01"
            sx={{
              fontWeight: 600,
            }}
          >
            Save
          </Typography>
          <Typography
            color="#358A01"
            sx={{
              fontWeight: 600,
            }}
          >
            {item.cost?.str ?? item.cost}
          </Typography>
          <Typography>{labelBeforeCountOfItems}</Typography>
          <Typography>
            <span style={{ color: "#2196f3", fontWeight: "bold" }}>
              {modifiedData.count}
            </span>
            {` ${labelAfterCountOfItems}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider width="80%" />
      <Box
        sx={{
          textAlign: "right",
          width: "100%",
          padding: "10px",
          border: "none",
        }}
      >
        {/* This needs to be replace with our custom button */}
        <Button
          variant="text"
          onClick={() => {
            history.push(exploreButtonUrl);
          }}
          style={{
            border: "none",
            margin: 0,
            borderRadius: "0 0 4px 4px",
          }}
        >
          Explore &nbsp; <ArrowRightIcon sx={{ fontSize: "12px" }} />
        </Button>
      </Box>
    </Card>
  );
};

const Recommendations = (props) => {
  const { cloudProvider = null, activeKey = null } = props;
  const { getDashBoardRecommendationsQuery } = useDashBoardRecommendationsV2(
    activeKey,
    cloudProvider,
  );

  const { isLoading, isPending, isRefetching, isError, data, error } =
    getDashBoardRecommendationsQuery;

  //remove cross cloud recommendations for now
  // TODO: remove this once cross cloud recommendations are added in api response
  const dataToDisplay = data?.filter((item) => item.type.id !== 4) ?? [];

  return (
    <Card
      elevation={2}
      style={{
        width: "100%",
        padding: "0px",
      }}
    >
      <CardHeader
        title={`Recommendations`}
        subheader={`(Based on last 7Days)`}
      />
      <CardContent
        style={{
          background: "white",
        }}
      >
        {isLoading || isPending || isRefetching ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
              height: "214px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Alert severity="warning" sx={{ my: 1 }}>
            {error?.message || "Something went wrong"}
          </Alert>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 3,
            }}
          >
            {dataToDisplay.map((item, i) => (
              <RecommendationCard
                item={item}
                selectedCloudProvider={cloudProvider}
                selectedKey={activeKey}
              />
            ))}
            <Card
              style={{
                ...CardStyle,
                minHeight: "100%",
              }}
            >
              <Box
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "7px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  sx={{ color: "#2196F3" }}
                >
                  Unlock Cross Cloud Insights
                </Typography>
                <Box style={{ textAlign: "center" }}>
                  <Box
                    component="img"
                    src={CrossCloudRecommendation}
                    style={{
                      width: "90%",
                    }}
                  />
                </Box>
              </Box>
              <Divider width="80%" />
              <Box
                sx={{
                  textAlign: "right",
                  width: "100%",
                  padding: "10px",
                  border: "none",
                }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    history.push(ROUTE_CROSS_CLOUD_RECOMMENDATIONS);
                  }}
                  style={{
                    border: "none",
                    margin: 0,
                    borderRadius: "0 0 4px 4px",
                  }}
                >
                  Explore &nbsp; <ArrowRightIcon sx={{ fontSize: "12px" }} />
                </Button>
              </Box>
            </Card>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Recommendations;
