import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Skeleton,
  Alert,
  LinearProgress,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";
import { useSpendEffeciencyV2 } from "../../../hooks/useSpendEffeciencyV2";
import { DEFAULT_MESSAGE_EMPTY } from "../../../config/config";
import { getLogoForType } from "../../../utils/commonUtil";
import errorImage from "../../../assets/svg/edge-cases/efficiency-section-error-state.svg";

const GraphContainerCardStyles = {
  padding: "16px",
  height: "100%",
};

const CardContentStyle = {
  padding: 0,
  margin: 0,
  height: "calc(100% - 98px)",
};

const ProgressBarWithContainer = (props) => {
  const { value } = props;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <LinearProgress
        variant="determinate"
        title={`${value}%`}
        value={value}
        sx={{
          height: "4px",
          borderRadius: "10px",
          width: "100%",
          background: "#dfdfe1",
          "& .MuiLinearProgress-bar": {
            background: `linear-gradient(90deg, #C5E5FF ${
              100 - value
            }%, #5DB0F2 100%)`,
          },
        }}
      />
    </Box>
  );
};

const AccountItem = ({ data, cloudProvider }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        px: 2,
        mb: 4,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {!cloudProvider && data.cloud !== undefined ? (
              <Box
                component="img"
                src={getLogoForType(data.cloud)}
                width="30px"
              />
            ) : null}
            <Tooltip title={data.key_label || data.instance}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 600,
                  overflow: "hidden",
                  maxWidth: "20ch",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data.key_label || data.instance}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <Chip
            label={
              data.saving_percentage === "0%"
                ? "Optimal"
                : `Save ${data.saving_percentage}`
            }
            color="success"
            variant="outlined"
            sx={{
              fontWeight: "bold",
            }}
          />
          {data.saving_percentage !== "0%" ? (
            <Typography variant="subtitle2" textAlign="right">
              Possible Savings : {data.possible_savings}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ProgressBarWithContainer
          value={100 - data.saving_percentage.split("%")[0]}
        />
      </Box>
    </Box>
  );
};

const AccountSpendEfficiencyV2 = ({
  cloudProvider = null,
  activeKey = null,
}) => {
  const {
    getTop5AccountComputeEfficiencyQuery,
    getAccountComputeEffeciencyQuery,
  } = useSpendEffeciencyV2(activeKey, cloudProvider);

  const isLoading =
    getAccountComputeEffeciencyQuery.isLoading ||
    getTop5AccountComputeEfficiencyQuery.isRefetching ||
    getTop5AccountComputeEfficiencyQuery.isLoading ||
    getAccountComputeEffeciencyQuery.isRefetching;
  const error = cloudProvider
    ? getAccountComputeEffeciencyQuery.error
    : getTop5AccountComputeEfficiencyQuery.error;
  const dataToDisplay = cloudProvider
    ? getAccountComputeEffeciencyQuery.data
    : getTop5AccountComputeEfficiencyQuery.data;

  return (
    <Card style={GraphContainerCardStyles}>
      <Box sx={{ padding: "20px", "& .MuiCardHeader-root": { padding: 0 } }}>
        <CardHeader
          sx={{
            display: "flex",
            overflow: "hidden",
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
          }}
          titleTypographyProps={{ noWrap: true }}
          title={
            cloudProvider
              ? `Account Compute Efficiency`
              : `Top 5 Account Compute Efficiency`
          }
        />
        <Typography variant="caption">(Based on last 7 days)</Typography>
      </Box>
      <CardContent style={CardContentStyle}>
        {isLoading ? (
          <Skeleton
            id="test"
            animation="wave"
            variant="rectangular"
            height="100%"
          />
        ) : error ? (
          <Box
            style={{
              height: "100%",
              display: "grid",
              placeItems: "center",
              overflow: "hidden",
            }}
          >
            <Box>
              <Box component={"img"} src={errorImage} alt="error" />
              <Alert severity="error" sx={{ my: 1 }}>
                {error}
              </Alert>
            </Box>
          </Box>
        ) : dataToDisplay?.length === 0 ? (
          <Alert severity="warning" sx={{ my: 1 }}>
            {DEFAULT_MESSAGE_EMPTY}
          </Alert>
        ) : (
          <Box
            sx={{
              maxHeight: cloudProvider ? "549px" : "288px",
              overflow: "auto",
            }}
          >
            {dataToDisplay?.map((data, index) => (
              <AccountItem
                key={`${data.key_id}_${index}`}
                data={data}
                cloudProvider={cloudProvider}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountSpendEfficiencyV2;
