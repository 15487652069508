import { useMutation } from "@tanstack/react-query";

import { store } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";

const signUp = async (data) => {
  try {
    const resp = await store(`/auth/register/`, data);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useAuthV2 = () => {
  const signupMutation = useMutation({
    mutationFn: signUp,
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  return { signupMutation };
};
