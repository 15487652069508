import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { ROUTE_LOGIN, ROUTE_VERIFY_EMAIL } from "../../routers/routes";
import { BrandLogo } from "./BrandLogo";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";
import { WelcomeMessage } from "./WelcomeMessage";
import EULA from "../EULA/EULA";
import InputField from "../InputField/InputField";
import { useAuthV2 } from "../../hooks/useAuthV2";
import { useInvitation } from "../../hooks/useInvitation";

const SignUpFormV2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const invitedMemberToken = urlParams.get("token");

  const { getInvitedMemberDataQuery } = useInvitation(invitedMemberToken);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      organization_name: "",
      email: "",
      password: "",
    },
    values: {
      organization_name:
        getInvitedMemberDataQuery.data?.organization?.name || "",
      email: getInvitedMemberDataQuery.data?.email || "",
    },
  });

  const { signupMutation } = useAuthV2();
  const history = useHistory();

  const submitFormValues = (data) => {
    const terms_and_conditions = termsAndConditionsAccepted
      ? "accepted"
      : "rejected";

    const payload = { ...data, terms_and_conditions, role_id: 1 };

    // This block is only for invited member
    if (
      getInvitedMemberDataQuery.data?.organization?.name &&
      getInvitedMemberDataQuery.data?.email &&
      getInvitedMemberDataQuery.data?.role?.id
    ) {
      payload["organization_name"] =
        getInvitedMemberDataQuery.data?.organization.name;
      payload["email"] = getInvitedMemberDataQuery.data?.email;
      payload["role_id"] = getInvitedMemberDataQuery.data?.role?.id || 2;
      payload["invite-link"] = true;
    }

    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: "signup",
        })
        .then((token) => {
          signupMutation.mutate(
            {
              ...payload,
              recaptcha_token: token,
            },
            {
              onSuccess: () => {
                history.push(ROUTE_VERIFY_EMAIL);
              },
            }
          );
        });
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        nodeBadge.remove();
      }
      const script = document.querySelector(
        'script[src^="https://www.gstatic.com/recaptcha/releases"]'
      );
      if (script) {
        script.remove();
      }
    };
  }, []);

  if (getInvitedMemberDataQuery?.isLoading) {
    return <CircularProgress size="30px" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        width: "100%",
      }}
    >
      <LeftPane />
      {/* {getInvitedMemberDataQuery.isLoading ? (
        <Box>
          <CircularProgress size="30px" />
        </Box>
      ) : ( */}
      <RightPane>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "scroll",
            scrollbarWidth: "none",
            mt: 2,
            gap: "10px",
          }}
        >
          <BrandLogo />
          <WelcomeMessage />
          <Typography
            variant="h4"
            sx={{
              mt: 2,
            }}
          >
            Sign Up
          </Typography>
          <form
            onSubmit={handleSubmit(submitFormValues)}
            style={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gap: "20px",
                width: "100%",
              }}
            >
              <InputField
                control={control}
                name="first_name"
                label="First Name"
                rules={{
                  required: "First name is required",
                }}
              />
              <InputField
                control={control}
                name="last_name"
                label="Last Name"
                rules={{
                  required: "Last name is required",
                }}
              />
              <InputField
                control={control}
                name="organization_name"
                label="Organisation Name"
                disabled={Boolean(
                  getInvitedMemberDataQuery.data?.organization?.name
                )}
                rules={{
                  required: "Organisation name is required",
                }}
              />
              <InputField
                control={control}
                name="email"
                label="Email"
                type="email"
                disabled={Boolean(getInvitedMemberDataQuery.data?.email)}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                }}
              />
              <InputField
                control={control}
                name="password"
                label="Password"
                type="password"
                rules={{
                  required: "Password is required",
                }}
              />

              <Controller
                control={control}
                name="terms_and_conditions"
                defaultValue={termsAndConditionsAccepted}
                rules={{
                  required: "Terms and Conditions is required",
                }}
                render={({ field, fieldState: { error: fieldError } }) => {
                  return (
                    <Box>
                      <FormControlLabel
                        {...field}
                        control={
                          <Checkbox
                            name="terms_and_conditions"
                            checked={termsAndConditionsAccepted}
                          />
                        }
                        label="Terms and Conditions"
                        onClick={() => setIsModalOpen(true)}
                      />
                      {fieldError && (
                        <FormHelperText error>
                          {fieldError.message}
                        </FormHelperText>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              {signupMutation.error && (
                <Alert severity="warning" sx={{ my: 1 }}>
                  {signupMutation.error?.message}
                </Alert>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "50%" }}
                disabled={isSubmitting || signupMutation.isPending}
              >
                {signupMutation.isPending || isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  "Sign Up"
                )}
              </Button>

              <Box component="p" mt={2}>
                Already have an account? <Link to={ROUTE_LOGIN}>Sign In</Link>.
              </Box>
            </Box>
            <Dialog
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              aria-labelledby="EULA-dialog"
              aria-describedby="EULA-dialog"
              sx={{
                // adding this this maxwidth prop is not working
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "70vw",
                  },
                },
              }}
            >
              <DialogTitle id="EULA-dialog-title">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">
                    User License Agreement (EULA)
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => setIsModalOpen(false)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>

              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "fit-content",
                  }}
                >
                  <DialogContentText id="EULA-dialog-description">
                    <EULA />
                  </DialogContentText>
                </Box>
              </DialogContent>
              <DialogActions>
                <FormControlLabel
                  control={<Checkbox checked={termsAndConditionsAccepted} />}
                  label="Terms and Conditions"
                  onChange={() =>
                    setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
                  }
                />
              </DialogActions>
            </Dialog>
          </form>
          <div
            className="g-recaptcha"
            // data-sitekey="_reCAPTCHA_site_key_"
            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            data-size="invisible"
          ></div>
        </Box>
      </RightPane>
      {/* )} */}
    </Box>
  );
};

export default SignUpFormV2;
