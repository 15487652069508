import React from "react";
import { Box } from "@mui/material";

import { ReactComponent as CheckFilledWithGradient } from "../assets/CheckFilledWithGradient.svg";

const GradientCircleCheckIcon = ({width="30px", height="30px"}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: width,
        height: height,
        borderRadius: "50%",
        background: "linear-gradient(to right, #2196F3, #10CCD2)",
        padding: "2px",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "50%",
        }}
      >
        <CheckFilledWithGradient />
      </Box>
    </Box>
  );
};

export default GradientCircleCheckIcon;
