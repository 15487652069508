import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar as BaseAppBar,
  Box,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  Menu,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ROUTE_LANDING_PAGE } from "../routers/routes";
import history from "../utils/history";
import CloudNudgeLogo from "../assets/cloud_pulse_logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { IS_DEBUG } from "../config/config";
import { SimpleButton } from "../pages/landingPage/LandingPage";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import { ROUTE_LOGIN } from "../routers/routes";
import { MENU_ITEMS } from "../pages/landingPage/LandingPage";
import trackEvent from "../utils/analytics";

export const APP_BAR_HEIGHT = 60;
export const APP_BAR_LOGO_HEIGHT = 48;

//receive isActive as a prop and change the border color
const MenuItem = styled(ListItem)`
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#39b2ec" : "black")};
  transition: all 0.3s ease-in-out;
  width: fit-content;
  border-bottom: 2px solid
    ${(props) => (props.isActive ? "#39b2ec" : "transparent")};
  height: 100%;
`;

export const AppBar = ({ toggleDrawer }) => {
  const breakpoint = useBreakpoint();
  const test = useRouteMatch();
  let currentRoute = "/";
  const currentPageRoute = test.path;
  const currentHash = new URL(window.location.href).hash;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (currentHash) {
    currentRoute = currentHash;
  } else {
    currentRoute = currentPageRoute;
  }

  return (
    <BaseAppBar
      position="fixed"
      sx={{
        top: 0,
        alignItems: { mobile: "flex-start", tablet: "center" },
        background: "white",
        height: `${APP_BAR_HEIGHT}px`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        paddingX: {
          mobile: "10px",
          tablet: "27px",
          laptop: "93px",
          desktop: "251px",
        },
      }}
    >
      <Toolbar style={{ height: "100%", width: "100%" }}>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{
            paddingLeft: "16px",
            paddingRight: "16px",
            display: {
              mobile: "block",
              tablet: "block",
              laptop: "none",
              desktop: "none",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            margin: { tablet: "0 auto" },
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={CloudNudgeLogo}
              alt="CloudNudge Logo"
              onClick={() => {
                history.push(ROUTE_LANDING_PAGE);
              }}
              sx={{
                cursor: "pointer",
                height: `${APP_BAR_LOGO_HEIGHT}px`,
              }}
            />
            <List
              sx={{
                display: {
                  mobile: "none",
                  laptop: "flex",
                },
                alignItems: "center",
                gap: "20px",
                padding: "0px",
                height: "100%",
              }}
            >
              {MENU_ITEMS.map((menu) => {
                if (menu.subMenu && menu.subMenu.length > 0) {
                  return (
                    <>
                      <MenuItem onClick={handleMenu}>
                        <Typography variant="button">{menu.name}</Typography>
                        <KeyboardArrowDownIcon
                          style={{
                            transform: Boolean(anchorEl)
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            transition: "all 0.3s ease-in-out",
                          }}
                        />
                      </MenuItem>
                      {menu.subMenu.map((subMenu) => {
                        return (
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                window.open(subMenu.route, "_blank");
                                handleClose();
                              }}
                              style={{ padding: "0 40px" }}
                            >
                              <Typography variant="button">
                                {subMenu.name}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        );
                      })}
                    </>
                  );
                }
                return (
                  <MenuItem
                    onClick={() => {
                      if (menu.route.startsWith("#")) {
                        history.push(`/${menu.route}`);
                      } else {
                        history.push(menu.route);
                      }
                    }}
                    isActive={currentRoute === menu.route}
                  >
                    <Typography variant="button">{menu.name}</Typography>
                  </MenuItem>
                );
              })}
            </List>
          </Box>
          <SimpleButton
            sx={{
              marginLeft: "20px",
              paddingInline: "60px",
              display: {
                mobile: "none",
                laptop: "flex",
              },
            }}
            onClick={() => {
              trackEvent("cta", "click", "Header Login Button");
              history.push(ROUTE_LOGIN);
            }}
          >
            Login
          </SimpleButton>
          {IS_DEBUG && (
            <Box>
              <Typography color="black">{breakpoint}</Typography>
            </Box>
          )}
        </Box>
      </Toolbar>
    </BaseAppBar>
  );
};
