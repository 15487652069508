import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { setCookie } from "../utils/storageUtil";
import { STORAGE_USER_INFO } from "../utils/constants";
import { fetch, store } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { CACHED_DATA_CACHE_TIME } from "../config/config";
import { QUERY_KEY } from "../utils/queryKeys";

const ListUserRoles = async () => {
  try {
    const resp = await fetch(`/auth/roles/`);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getTeamMembers = async () => {
  try {
    const resp = await fetch(`/auth/list-organization-users/`);
    if (resp?.data) {
      return resp.data.map((member) => {
        return {
          ...member,
          role: {
            id: member.role.id,
            name: member.role.name,
          },
        };
      });
    }
    return [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const inviteMember = async (data) => {
  try {
    const resp = await store(`/auth/invite-member/`, data);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const changeUserRole = async (data) => {
  try {
    const resp = await store(`/auth/change-user-role/`, data);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const removeUser = async (userId) => {
  try {
    const resp = await store(`/auth/remove-user/${userId}/`);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const leaveOrganization = async () => {
  try {
    const resp = await store(`/auth/leave-organization/`);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const updateOrgLogo = async (data) => {
  try {
    const formData = new FormData();
    formData.append("organization_logo", data.organization_logo);
    const resp = await store(`/auth/organization/update-logo/`, formData, true);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useTeam = () => {
  const queryClient = useQueryClient();

  const listUserRolesQuery = useQuery({
    queryKey: [QUERY_KEY.USER_ROLES],
    queryFn: ListUserRoles,
    refetchOnWindowFocus: false,
    cacheTime: CACHED_DATA_CACHE_TIME,
    staleTime: CACHED_DATA_CACHE_TIME,
  });

  const getTeamMembersQuery = useQuery({
    queryKey: [QUERY_KEY.TEAM_MEMBERS],
    queryFn: getTeamMembers,
    refetchOnWindowFocus: false,
    cacheTime: CACHED_DATA_CACHE_TIME,
    staleTime: CACHED_DATA_CACHE_TIME,
  });

  const inviteMemberMutation = useMutation({
    mutationFn: inviteMember,
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const changeUserRoleMutation = useMutation({
    mutationFn: changeUserRole,
    onSuccess: (_, variables) => {
      queryClient.setQueryData([QUERY_KEY.TEAM_MEMBERS], (existingMembers) => {
        const updatedMembers = existingMembers.map((member) => {
          if (member.id === variables.user_id) {
            return {
              ...member,
              role: {
                id: variables.role_id,
                name: variables.role.label,
              },
            };
          }
          return member;
        });
        return updatedMembers;
      });
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const removeUserMutation = useMutation({
    mutationFn: (userId) => removeUser(userId),
    onSuccess: (_, userIdToDelete) => {
      queryClient.setQueryData([QUERY_KEY.TEAM_MEMBERS], (existingMembers) => {
        const updatedMembers = existingMembers.filter(
          (member) => member.id !== userIdToDelete
        );
        return updatedMembers;
      });
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const leaveOrganizationMutation = useMutation({
    mutationFn: leaveOrganization,
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const updateOrgLogoMutation = useMutation({
    mutationFn: (data) => updateOrgLogo(data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QUERY_KEY.AUTHENTICATED_USER_INFO],
        (oldData) => {
          const updatedData = {
            ...oldData,
            organization: {
              ...oldData.organization,
              logo: data.organization_logo,
            },
          };
          setCookie(STORAGE_USER_INFO, JSON.stringify(updatedData), 1);
          return updatedData;
        }
      );
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  return {
    listUserRolesQuery,
    getTeamMembersQuery,
    inviteMemberMutation,
    leaveOrganizationMutation,
    changeUserRoleMutation,
    removeUserMutation,
    updateOrgLogoMutation,
  };
};
