import styled from "@emotion/styled/macro";
import { Box, CircularProgress } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGNUP } from "../../routers/routes";
import { H4 } from "../../ui/H4";
import { Link } from "../../ui/Link";
import { SolidButton } from "../../ui/SolidButton";
import renderText from "../common/form/renderText";
import { BrandLogo } from "./BrandLogo";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";
import { WelcomeMessage } from "./WelcomeMessage";

// Styled components using emotion
export const FullScreenContainer = styled(Box)`
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
`;

export const Input = styled(Field)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
`;

export const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const submitFormValues = (values, onSubmit) => {
  window.grecaptcha.ready((_) => {
    window.grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
        action: "login",
      })
      .then((token) => {
        onSubmit({
          ...values,
          recaptcha_token: token,
        });
      });
  });
};

const LoginForm = (props) => {
  const { handleSubmit, onSubmit, errorMessage } = props;
  const isLoading = useSelector((state) => state.auth.isLoading);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      const nodeBadge = document.querySelector(".grecaptcha-badge");
      if (nodeBadge) {
        nodeBadge.remove();
      }
      const script = document.querySelector(
        'script[src^="https://www.gstatic.com/recaptcha/releases"]'
      );
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <FullScreenContainer>
      <LeftPane />
      <RightPane>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "scroll",
            scrollbarWidth: "none",
            gap: "10px",
          }}
        >
          <BrandLogo />
          <WelcomeMessage />
          <H4
            sx={{
              mt: 4,
            }}
          >
            Sign In
          </H4>
          <form
            method="post"
            onSubmit={handleSubmit((values) =>
              submitFormValues(values, onSubmit)
            )}
          >
            <Box>
              <Input
                type="text"
                name="organization_id"
                component={renderText}
                label="Organization ID"
              />
              <Input
                type="text"
                name="email"
                component={renderText}
                label="Email"
              />
              <Input
                type="password"
                name="password"
                component={renderText}
                label="Password"
              />
              <Box
                component="p"
                sx={{
                  margin: "6px 0px 0px 0px",
                  padding: 0,
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <Link to={ROUTE_FORGOT_PASSWORD}>Forgot password?</Link>
              </Box>
            </Box>
            <BottomContainer>
              {errorMessage && (
                <Alert severity="warning" sx={{ my: 1 }}>
                  {errorMessage}
                </Alert>
              )}
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SolidButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "50%" }}
                >
                  Sign In
                </SolidButton>
              )}
              <Box component="p" mt={2}>
                Don't have an account? <Link to={ROUTE_SIGNUP}>Create one</Link>
              </Box>
            </BottomContainer>
          </form>
          <div
            className="g-recaptcha"
            // data-sitekey="_reCAPTCHA_site_key_"
            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            data-size="invisible"
          ></div>
        </Box>
      </RightPane>
    </FullScreenContainer>
  );
};

const validateLogin = (values) => {
  const errors = {};
  const requiredFields = {
    email: "email",
    password: "password",
    organization_id: "organization id",
  };
  Object.entries(requiredFields).forEach(([field, label]) => {
    if (!values[field]) {
      errors[field] = `The ${label} field is required.`;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address.";
  }
  return errors;
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default reduxForm({
  form: "LoginForm",
  validate: validateLogin,
})(LoginForm);
