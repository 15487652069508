import styled from '@emotion/styled/macro';
import { Box as BaseBox } from '@mui/material';

const Box = styled(BaseBox)`
  width: 0; 
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid red;
`;

export const TrendUp = (props) => {
  return(<Box />);
};
