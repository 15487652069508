import React from "react";
import { Box, Typography } from "@mui/material";

import { GradeintText } from "./LandingPage";

const WhyWeAreRealDealSection = () => {
  return (
    <Box
      sx={{
        padding: { mobile: "0 30px", tablet: "0 72px" },
        marginBottom: "100px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: {
            desktop: "1400px",
            laptop: "1200px",
            tablet: "750px",
          },
          margin: "0 auto",
        }}
      >
        <GradeintText variant="h3">Why We’re the Real Deal? </GradeintText>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "24px",
            marginTop: "80px",
            marginBottom: { mobile: "30px", laptop: "10px" },
          }}
        >
          <Box
            sx={{
              gridColumn: "span 2",
              display: { mobile: "none", laptop: "block" },
            }}
          >
            <Typography variant="h3" fontWeight="bold">
              Because we don’t just follow trends
            </Typography>
            <br />
            <Typography variant="h4" fontWeight="bold">
              we set them, <br /> break them & <br /> build something entirely
              new!
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: { mobile: "span 5", laptop: "span 3" },
              background: "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                display: "flex",
                flexDirection: { mobile: "column", tablet: "row" },
                alignItems: "center",
                gap: "24px",
                borderRadius: "10px",
              }}
            >
              <Box
                component="img"
                src="/assets/png/smart_ml_agent.png"
                width="183px"
                alt="Smart ML Agent"
                sx={{
                  aspectRatio: "0.62",
                }}
              />
              <Box>
                <Typography variant="h4">Smart ML Agent</Typography>
                <Typography variant="h5" style={{ marginTop: "12px" }}>
                  Meet your Smart ML Agent—your personal assistant for cloud
                  savings! <br /> <br />
                  Harness the power of machine learning to analyze usage
                  patterns, predict costs, and deliver intelligent
                  recommendations that enhance efficiency without compromising
                  performance.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              gridColumn: { mobile: "span 5", laptop: "span 3" },
              background: "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                display: "flex",
                gap: "24px",
                borderRadius: "10px",
                flexDirection: { mobile: "column", tablet: "row" },
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="/assets/png/cross_cloud_mastery.png"
                width="168px"
                alt="Cross Cloud Mastery"
                sx={{
                  aspectRatio: "0.49",
                }}
              />
              <Box>
                <Typography variant="h4">Cloud Cost Mastery</Typography>
                <Typography variant="h5" style={{ marginTop: "12px" }}>
                  Effortlessly navigate and manage resources across multiple
                  cloud platforms with our Cross-Cloud Mastery. <br /> <br />
                  Say goodbye to the hassle of juggling different environments
                  and enjoy seamless integration that optimizes performance and
                  reduces costs—all in one powerful platform!
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            textAlign="right"
            display="flex"
            alignItems="flex-end"
            sx={{
              gridColumn: "span 2",
              display: { mobile: "none", laptop: "block" },
            }}
          >
            <Box>
              <Typography variant="h3" fontWeight="bold">
                Make every minute work for you, manage cloud costs With{" "}
                <GradeintText variant="h3" component="span">
                  CloudNudge
                </GradeintText>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhyWeAreRealDealSection;
