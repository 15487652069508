import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

const CheckboxField = (props) => {
  const { control, label, name, rules = {}, shouldShowError = true, ...restProps } = props;
  return (
    <Controller
      control={control}
      name={name}
      {...(rules && Object.entries(rules).length > 0 ? { rules } : {})}
      render={({ field: { value, ...field }, fieldState: { error: fieldError } }) => {
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={Array.isArray(value) ? value.includes(restProps.value) : false}
                  {...restProps}
                />
              }
              label={label}
              error={!!fieldError}
            />
            {shouldShowError && fieldError && (
              <FormHelperText error>{fieldError.message}</FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};
export default CheckboxField;
