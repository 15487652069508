import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Tooltip,
} from "@mui/material";

import { SolidButton } from "../../ui/SolidButton";
import InputField from "../InputField/InputField";
import { useForm } from "react-hook-form";
import { useCloudFormation } from "../../hooks/useCloudFormation";

const CreateStackForm = ({
  handleSubmit,
  isSubmitting,
  handleStackSubmit,
  createAWSCloudFormationStackMutation,
  control,
  handleBack,
}) => {
  return (
    <Box
      style={{
        marginBlock: "10px",
      }}
    >
      <form onSubmit={handleSubmit(handleStackSubmit)}>
        <Typography variant="subtitle1">
          Please provide the ARN number to connect
        </Typography>
        <Box
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginBlock: "10px",
          }}
        >
          <InputField
            label="ARN No."
            name="target_account_role_arn"
            control={control}
            rules={{
              required: "ARN No is required",
            }}
          />
          <InputField
            label="Key Label"
            name="key_label"
            control={control}
            rules={{
              required: "Key Label is required",
            }}
          />
          {/* TODO: uncomment this when backend supports region */}
          {/* <Controller
            name="region"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={awsRegions}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(_, value) => field.onChange(value)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an Option"
                    variant="outlined"
                    fullWidth
                    required
                    style={{ width: "200px" }}
                  />
                )}
                componentsProps={{
                  popper: {
                    style: { width: "200px" },
                  },
                }}
              />
            )}
          /> */}
        </Box>
        {createAWSCloudFormationStackMutation.isError ? (
          <Alert
            severity="error"
            sx={{
              my: 1,
            }}
          >
            {createAWSCloudFormationStackMutation.error?.message}
          </Alert>
        ) : null}
        <Box>
          <Button
            type="button"
            variant="outlined"
            style={{
              border: "1px solid #2196f3",
              color: "#2196f3",
              marginBlock: "10px",
              marginInlineEnd: "10px",
            }}
            sx={{
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.38)!important",
                background: "rgba(0, 0, 0, 0.12)",
                border: "1px solid rgba(0, 0, 0, 0.38)!important",
              },
            }}
            onClick={handleBack}
            disabled={
              isSubmitting || createAWSCloudFormationStackMutation.isPending
            }
          >
            Back
          </Button>
          <SolidButton
            type="submit"
            variant="contained"
            disabled={
              isSubmitting || createAWSCloudFormationStackMutation.isPending
            }
          >
            {createAWSCloudFormationStackMutation.isPending ? (
              <CircularProgress size={24} />
            ) : (
              "Next"
            )}
          </SolidButton>
        </Box>
      </form>
    </Box>
  );
};

const AWSCloudFormation = () => {
  const [showStackForm, setShowStackForm] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const {
    getCloudFormationURLQuery,
    createAWSCloudFormationStackMutation,
    getAWSCloudFormationQuery,
    deleteAWSCloudFormationStackMutation,
  } = useCloudFormation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      target_account_role_arn: "",
      key_label: "",
    },
  });

  const handleCreateStack = () => {
    setRedirecting(true);
    getCloudFormationURLQuery
      .refetch()
      .then((data) => {
        if (data?.data?.data) {
          window.open(data?.data?.data, "_blank");
          setShowStackForm(true);
          setRedirecting(false);
        }
      })
      .catch(() => {
        setRedirecting(false);
      })
      .finally(() => {
        setRedirecting(false);
      });
  };

  const handleAlreadyHaveOne = () => {
    setShowStackForm(true);
  };

  const handleBack = () => {
    setShowStackForm(false);
  };

  const handleStackSubmit = (data) => {
    createAWSCloudFormationStackMutation.mutate({
      ...data,
    });
  };

  const handleDeleteStack = (stack_id) => {
    deleteAWSCloudFormationStackMutation.mutate(stack_id);
  };

  useEffect(() => {
    if (createAWSCloudFormationStackMutation.isSuccess) {
      reset();
      setShowStackForm(false);
    }
  }, [createAWSCloudFormationStackMutation.isSuccess, reset]);

  return (
    <>
      <Box>
        <Typography variant="h4">
          Optimize your cloud cost & maximize your savings today !{" "}
        </Typography>
        {getAWSCloudFormationQuery.data?.length === 0 &&
        !getAWSCloudFormationQuery.isLoading &&
        !getAWSCloudFormationQuery.isRefetching ? (
          <>
            <Typography variant="subtitle1">
              CloudNudge will start analyzing your cloud costs as soon as you
              connect your account.
            </Typography>
            <Typography
              variant="h4"
              sx={{
                display: showStackForm ? "none" : "block",
              }}
            >
              Would you like to set up an AWS CloudFormation stack to link your
              AWS account?
            </Typography>
            {getCloudFormationURLQuery.isError ? (
              <Alert severity="error" sx={{ my: 1 }}>
                {getCloudFormationURLQuery.error}
              </Alert>
            ) : null}
            <Box
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBlock: "20px",
              }}
            >
              <SolidButton
                sx={{
                  height: "100%",
                  Padding: "6px",
                  display: showStackForm ? "none" : "block",
                }}
                variant="contained"
                color="primary"
                onClick={handleCreateStack}
                disabled={getCloudFormationURLQuery.isRefetching || redirecting}
              >
                {getCloudFormationURLQuery.isRefetching || redirecting ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Create Stack"
                )}
              </SolidButton>
              <SolidButton
                sx={{
                  height: "100%",
                  Padding: "6px",
                  display: showStackForm ? "none" : "block",
                }}
                variant="contained"
                color="primary"
                onClick={handleAlreadyHaveOne}
                disabled={getCloudFormationURLQuery.isRefetching || redirecting}
              >
                Already have one?
              </SolidButton>
            </Box>
          </>
        ) : null}

        {getAWSCloudFormationQuery.data?.length === 0 && showStackForm ? (
          <CreateStackForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleStackSubmit={handleStackSubmit}
            createAWSCloudFormationStackMutation={
              createAWSCloudFormationStackMutation
            }
            control={control}
            handleBack={handleBack}
          />
        ) : null}

        {getAWSCloudFormationQuery.isFetching ||
        getAWSCloudFormationQuery.isLoading ? (
          <Skeleton height={200} width="100%" />
        ) : getAWSCloudFormationQuery.data?.length > 0 ? (
          <Box>
            {deleteAWSCloudFormationStackMutation.isError ? (
              <Alert
                severity="error"
                sx={{
                  my: 1,
                }}
              >
                {deleteAWSCloudFormationStackMutation.error?.message}
              </Alert>
            ) : null}

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr No</TableCell>
                  <TableCell>Key Label</TableCell>
                  <TableCell>Stack Name</TableCell>
                  <TableCell>ARN No.</TableCell>
                  <TableCell>Created Time</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getAWSCloudFormationQuery.data?.map((item, index) => {
                  const stackCreatedOnInLocalTimeZone = new Date(
                    item?.stack_created_on
                  ).toLocaleString();
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        style={{
                          maxWidth: "20ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip
                          title={item.key_label}
                          placement="bottom-start"
                        >
                          {item.key_label}
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: "20ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip
                          title={item.stack_name}
                          placement="bottom-start"
                        >
                          {item.stack_name}
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: "20ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip
                          title={item.target_account_role_arn}
                          placement="bottom-start"
                        >
                          {item.target_account_role_arn}
                        </Tooltip>
                      </TableCell>
                      <TableCell>{stackCreatedOnInLocalTimeZone}</TableCell>
                      <TableCell>{item.region.name}</TableCell>
                      <TableCell>
                        <SolidButton
                          sx={{ margin: "2px" }}
                          variant="contained"
                          onClick={() => handleDeleteStack(item.id)}
                          disabled={
                            deleteAWSCloudFormationStackMutation.isPending
                          }
                        >
                          {deleteAWSCloudFormationStackMutation.isPending &&
                          item.id ===
                            deleteAWSCloudFormationStackMutation.variables ? (
                            <CircularProgress
                              size={24}
                              style={{ color: "white" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </SolidButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!showStackForm ? (
              <>
                {getCloudFormationURLQuery.isError ? (
                  <Alert severity="error" sx={{ my: 1 }}>
                    {getCloudFormationURLQuery.error}
                  </Alert>
                ) : null}
                <Box
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      height: "100%",
                      Padding: "6px",
                      my: 2,
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={handleCreateStack}
                    disabled={
                      getCloudFormationURLQuery.isRefetching || redirecting
                    }
                  >
                    {getCloudFormationURLQuery.isRefetching || redirecting ? (
                      <CircularProgress size={24} color="primary" />
                    ) : (
                      "Create Stack"
                    )}
                  </Button>
                  <Button
                    sx={{
                      height: "100%",
                      Padding: "6px",
                      my: 2,
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={handleAlreadyHaveOne}
                    disabled={
                      getCloudFormationURLQuery.isRefetching || redirecting
                    }
                  >
                    Already have one?
                  </Button>
                </Box>
              </>
            ) : null}
          </Box>
        ) : null}

        {getAWSCloudFormationQuery.data?.length > 0 && showStackForm ? (
          <CreateStackForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleStackSubmit={handleStackSubmit}
            createAWSCloudFormationStackMutation={
              createAWSCloudFormationStackMutation
            }
            control={control}
            handleBack={handleBack}
          />
        ) : null}
      </Box>
    </>
  );
};

export default AWSCloudFormation;
