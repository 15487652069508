import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Alert,
  Skeleton,
} from "@mui/material";

import {
  CostByRegionV2,
  CostByServiceV2,
} from "./components/CostByRegionServiceV2";
import CostByAccountV2 from "./components/CostByAccountV2";
import AccountSpendEfficiencyV2 from "./components/AccountSpendEfficiencyV2";
import Recommendations from "./Recommendations";
import { useGetCloudKeys } from "../../hooks/useRecommendationsV2";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CostDetailsForCloudV2 = (props) => {
  const { cloudProvider, startDate, endDate } = props;
  const [value, setValue] = React.useState(0);
  const [tabularView, setTabularView] = useState(false);

  const getCloudKeysQuery = useGetCloudKeys(cloudProvider);

  const [selectedKey, setSelectedKey] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleKeyChange = (event) => {
    setSelectedKey(event.target.value);
  };

  const toggleTabularView = () => {
    setTabularView(!tabularView);
  };

  useEffect(() => {
    setSelectedKey(getCloudKeysQuery?.data?.[0]?.id);
  }, [getCloudKeysQuery?.data]);

  if (getCloudKeysQuery?.isLoading || getCloudKeysQuery?.isFetching) {
    return (
      <Skeleton
        style={{
          width: "100%",
          height: "calc(100vh - 314px)",
          transform: "none",
        }}
      />
    );
  }

  if (getCloudKeysQuery?.data?.length === 0) {
    return (
      <Box style={{ width: "100%", padding: "20px" }}>
        <Alert severity="warning">
          No tokens found, Please add a token to continue
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateAreas:
          '"keys keys keys" "tab tab efficiency" "recommendation recommendation efficiency"',
        gap: "20px",
        width: "100%",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          gridColumn: "span 3",
          gap: "20px",
          gridArea: "keys",
        }}
      >
        <FormControlLabel
          style={{ flexShrink: 0 }}
          control={
            <Switch onChange={toggleTabularView} checked={tabularView} />
          }
          label="Tabular view"
        />
        <FormControl fullWidth>
          <InputLabel shrink={!!selectedKey} id="access-keys-select-label">
            Tokens
          </InputLabel>
          <Select
            labelId="access-keys-select-label"
            id="access-keys-select"
            value={selectedKey || ""}
            label="Token Keys"
            onChange={handleKeyChange}
            size="small"
          >
            {getCloudKeysQuery?.data?.map((key) => (
              <MenuItem value={key.id}>{key.key_label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        style={{
          gridColumn: "span 2",
          display: "grid",
          gridTemplateColumns: "subgrid",
          gridArea: "tab",
        }}
      >
        <Card sx={{ padding: "20px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              marginBottom: "20px",
            }}
          >
            <Tab label="Cost By Region" {...a11yProps(0)} />
            <Tab label="Cost By Service" {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <CostByRegionV2
              cloudProvider={cloudProvider}
              startDate={startDate}
              endDate={endDate}
              showTabularView={tabularView}
              activeKey={selectedKey}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CostByServiceV2
              {...props}
              showTabularView={tabularView}
              activeKey={selectedKey}
            />
          </CustomTabPanel>
        </Card>
        <Card>
          <CostByAccountV2
            cloudProvider={cloudProvider}
            startDate={startDate}
            endDate={endDate}
            showTabularView={tabularView}
            activeKey={selectedKey}
          />
        </Card>
      </Box>
      <Box style={{ gridArea: "efficiency" }}>
        <AccountSpendEfficiencyV2
          cloudProvider={cloudProvider}
          startDate={startDate}
          endDate={endDate}
          activeKey={selectedKey}
        />
      </Box>
      <Box style={{ gridArea: "recommendation" }}>
        <Recommendations
          cloudProvider={cloudProvider}
          activeKey={selectedKey}
        />
      </Box>
    </Box>
  );
};

export default CostDetailsForCloudV2;
