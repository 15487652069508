import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { destroy, fetch, store, update } from "../utils/httpUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import { CACHED_DATA_CACHE_TIME, CACHED_DATA_STALE_TIME } from "../config/config";

const getBudgetInsights = async (
  cloudProvider,
  key_id,
) => {
  try {
    const url = `/budget/insights/?cloud_name=${cloudProvider}&key_id=${key_id}`;
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useBudgetInsights = (cloudProvider, key_id) => {
  const getBudgetInsightsQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_INSIGHTS, cloudProvider, key_id],
    queryFn: () => getBudgetInsights(cloudProvider, key_id),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudProvider && !!key_id,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetInsightsQuery };
};

const getBudgetList = async (cloudProvider) => {
  try {
    const resp = await fetch(`/budget/list/?cloud_name=${cloudProvider}`);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetBudgetList = (cloudProvider) => {
  const getBudgetListQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_LIST, cloudProvider],
    queryFn: () => getBudgetList(cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudProvider,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetListQuery };
};

const getBudgetScopeList = async () => {
  try {
    const resp = await fetch(`/budget/scope-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetBudgetScopeList = () => {
  const getBudgetScopeListQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_SCOPE_LIST],
    queryFn: getBudgetScopeList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetScopeListQuery };
};

const getBudgetCostDimension = async () => {
  try {
    const resp = await fetch(`/budget/dimension-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetBudgetCostDimension = () => {
  const getBudgetCostDimensionQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_COST_DIMENSION],
    queryFn: getBudgetCostDimension,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetCostDimensionQuery };
};

const getAggregateCostList = async () => {
  try {
    const resp = await fetch(`/budget/aggregate-cost-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetAggregateCostList = () => {
  const getAggregateCostListQuery = useQuery({
    queryKey: [QUERY_KEY.AGGREGATE_COST_LIST],
    queryFn: getAggregateCostList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getAggregateCostListQuery };
};

const getBudgetFrequencyList = async () => {
  try {
    const resp = await fetch(`/budget/frequency-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetBudgetFrequencyList = () => {
  const getBudgetFrequencyQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_FREQUENCY_LIST],
    queryFn: getBudgetFrequencyList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetFrequencyQuery };
};

const getBudgetTriggerOnList = async () => {
  try {
    const resp = await fetch(`/budget/triggeron-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetBudgetTriggerOnList = () => {
  const getBudgetTriggerOnQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_TRIGGER_ON_LIST],
    queryFn: getBudgetTriggerOnList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetTriggerOnQuery };
};

const getDimensionValues = async (cloudName, keyId, dimensionId) => {
  try {
    const resp = await fetch(`/budget/dimension-values/?cloud_name=${cloudName}&key_id=${keyId}&dimension_id=${dimensionId}`);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetDimensionValues = (cloudName, keyId, dimensionId) => {
  const getBudgetDimensionValuesQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_DIMENSION_VALUES, cloudName, keyId, dimensionId],
    queryFn: () => getDimensionValues(cloudName, keyId, dimensionId),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudName && !!keyId && !!dimensionId,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetDimensionValuesQuery };
};

const getNotificationChannels = async () => {
  try {
    const resp = await fetch(`/budget/list-notification-channels/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useGetNotificationChannels = () => {
  const getNotificationChannelsQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET_NOTIFICATION_CHANNELS],
    queryFn: getNotificationChannels,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getNotificationChannelsQuery };
};

const createBudget = async (formData) => {
  try {
    const url = `/budget/`;
    const resp = await store(url, formData);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useCreateBudget = () => {
  const queryClient = useQueryClient();
  const createBudgetMutation = useMutation({
    mutationFn: (formData) => createBudget(formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.BUDGET_LIST]);
      queryClient.invalidateQueries([QUERY_KEY.BUDGET_INSIGHTS]);
      return data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { createBudgetMutation };
};

const getBudgetPreviewGraphData = async (keyId, aggregateCostBy, scopeId, dimensionId, dimensionValues) => {
  let url = `/budget/get-preview-cost/?key_id=${keyId}&aggregate_cost_by=${aggregateCostBy}&scope_id=${scopeId}`;
  if (scopeId === 1) {
    url = `/budget/get-preview-cost/?key_id=${keyId}&aggregate_cost_by=${aggregateCostBy}&scope_id=${scopeId}`;
  }

  if (scopeId === 2 && dimensionId && dimensionValues.length > 0) {

    url = `/budget/get-preview-cost/?key_id=${keyId}&aggregate_cost_by=${aggregateCostBy}&scope_id=${scopeId}&dimension_id=${dimensionId}&dimension_values=${dimensionValues}`;
  }

  try {
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetBudgetPreviewGraphData = (keyId, aggregateCostBy, scopeId, dimensionId, dimensionValues) => {
  let shouldFetch = false;
  let queryKey = [QUERY_KEY.BUDGET_PREVIEW_GRAPH_DATA];
  if (keyId && aggregateCostBy && scopeId) {
    shouldFetch = true;
    queryKey = [QUERY_KEY.BUDGET_PREVIEW_GRAPH_DATA, keyId, aggregateCostBy, scopeId];
    if (scopeId === 1) {
      shouldFetch = true;
    }
    if (scopeId === 2 && dimensionId && dimensionValues.length > 0) {
      queryKey = [QUERY_KEY.BUDGET_PREVIEW_GRAPH_DATA, keyId, aggregateCostBy, scopeId, dimensionId, JSON.stringify(dimensionValues)];
      shouldFetch = true;
    }
  }

  const getBudgetPreviewGraphDataQuery = useQuery({
    queryKey: queryKey,
    queryFn: () => getBudgetPreviewGraphData(keyId, aggregateCostBy, scopeId, dimensionId, JSON.stringify(dimensionValues)),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: shouldFetch,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getBudgetPreviewGraphDataQuery };
};

const getBudget = async (cloudName, budgetId, keyId) => {
  try {
    const url = `/budget/${budgetId}/?cloud_name=${cloudName}&key_id=${keyId}`;
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetBudget = (cloudName, budgetId, keyId, shouldFetch = true) => {
  const getBudgetQuery = useQuery({
    queryKey: [QUERY_KEY.BUDGET, cloudName, budgetId?.toString(), keyId?.toString()],
    queryFn: () => getBudget(cloudName, budgetId, keyId),
    refetchOnWindowFocus: false,
    retry: 2,
    //shouldFetch is another param helps to avoid fetching data when it is not needed (eg: create budge tab)
    enabled: !!cloudName && !!budgetId && !!keyId && !!shouldFetch,
    // response is not cached because backend having different response for get request and patch request
    staleTime: 0,
    cacheTime: 0,
  });

  return { getBudgetQuery };
};

const updateBudget = async (formData) => {
  const { budgetId, ...restFormData } = formData;
  try {
    const resp = await update(`/budget/${budgetId}/`, restFormData);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useUpdateBudget = () => {
  const queryClient = useQueryClient();
  const updateBudgetMutation = useMutation({
    mutationFn: (formData) => updateBudget(formData),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([QUERY_KEY.BUDGET_LIST]);
      queryClient.invalidateQueries([QUERY_KEY.BUDGET]);
      queryClient.invalidateQueries([QUERY_KEY.BUDGET_INSIGHTS]);
      return data.data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { updateBudgetMutation };
};

const updateBudgetStatus = async ({ budgetId, cloudName, isActive }) => {
  try {
    const resp = await update(`/budget/status/${budgetId}/?cloud_name=${cloudName}&is_active=${isActive}`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useUpdateBudgetStatus = () => {
  const queryClient = useQueryClient();
  const updateBudgetStatusMutation = useMutation({
    mutationFn: (formData) => updateBudgetStatus(formData),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData([QUERY_KEY.BUDGET_LIST, variables.cloudName], (oldData) => {
        if (oldData) {
          return oldData.map((budget) => {
            if (budget.id === variables.budgetId) {
              return {
                ...budget,
                is_active: variables.isActive,
              };
            }
            return budget;
          });
        }
        return [];
      });
      return data.data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { updateBudgetStatusMutation };
};


const deleteBudgets = async (budgetIds = []) => {
  try {
    const resp = await destroy(`/budget/?ids=[${budgetIds}]`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useDeleteBudgets = (selectedCloudProvider) => {
  const queryClient = useQueryClient();
  const deleteBudgetsMutation = useMutation({
    mutationFn: (budgetIds) => deleteBudgets(budgetIds),
    onSuccess: (data, variables) => {
      const budgetIds = [...variables];
      queryClient.setQueryData([QUERY_KEY.BUDGET_LIST, selectedCloudProvider], (oldData) => {
        return oldData.filter((budget) => !budgetIds.includes(budget.id));
      });
      return data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { deleteBudgetsMutation };
};
