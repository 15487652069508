import { gridPaginationSelector, gridRowCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const pagination = useGridSelector(apiRef, gridPaginationSelector);
  const totalRows = useGridSelector(apiRef, gridRowCountSelector);
  
  const page = pagination?.paginationModel?.page ?? 0;
  const pageSize = pagination?.paginationModel?.pageSize ?? 5;
  const totalPages = totalRows > 0 ? Math.ceil(totalRows / pageSize) : 1;

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      apiRef.current.setPage(newPage);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Typography variant="body2" sx={{ mr: 2 }}>
        Total Entries: {totalRows}
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <IconButton onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <Typography variant="body2">{`Page ${page + 1} of ${totalPages}`}</Typography>
        <IconButton onClick={() => handlePageChange(page + 1)} disabled={page + 1 >= totalPages}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CustomPagination;
