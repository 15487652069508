import React from "react";

import {
  Box,
  CardContent,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = 300;
const PARAM_COST_BY_REGION = "byRegion";

const CustomLegend = ({ data }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      gap: "8px",
      flexWrap: "wrap",
      maxWidth: GRAPH_HEIGHT,
    }}
  >
    {data.map((entry, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          alignItems: "center",
        }}
        title={entry.label}
      >
        <div
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: entry.color,
            marginRight: "4px",
            flexShrink: 0,
          }}
        ></div>
        <p
          style={{
            maxWidth: "80px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          {entry.label}
        </p>
      </div>
    ))}
  </div>
);

function PieChartView({ isLoading, top5Entries }) {
  if (isLoading) {
    return <Skeleton variant="rectangular" height={GRAPH_HEIGHT} />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: GRAPH_HEIGHT,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PieChart
        series={[
          {
            data: top5Entries ?? [],
            innerRadius: 60,
            outerRadius: 100,
          },
        ]}
        slotProps={{
          legend: { hidden: true },
        }}
        height={GRAPH_HEIGHT}
        width={GRAPH_WIDTH}
        margin={{ top: -50, bottom: 30, left: 10, right: 10 }}
      >
        <text
          x={GRAPH_WIDTH / 2}
          y={GRAPH_HEIGHT / 2 - 40}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            fill: "black",
          }}
        >
          Top 5
        </text>
      </PieChart>
      <CustomLegend data={top5Entries ?? []} />
    </Box>
  );
}

function TabularView({ isLoading, allEntries, costByType }) {
  if (isLoading) {
    return <Skeleton variant="rectangular" height={GRAPH_HEIGHT} />;
  }

  return (
    <Box style={{ maxHeight: GRAPH_HEIGHT, overflowY: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {costByType === PARAM_COST_BY_REGION ? "Region" : "Service"}
            </TableCell>
            <TableCell>Cost Percentage</TableCell>
            <TableCell>Cost {`($)`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEntries?.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell>{row.percentage}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const TabContent = ({
  showTabularView,
  isError,
  error,
  isLoading,
  allEntries,
  top5Entries,
  costByType,
}) => {
  return (
    <Box>
      <CardContent
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        {isError ? (
          <Box>
            <Alert severity="warning" sx={{ my: 1 }}>
              {error?.message || "something went wrong"}
            </Alert>
          </Box>
        ) : (
          <>
            {showTabularView ? (
              <TabularView
                isLoading={isLoading}
                allEntries={allEntries}
                costByType={costByType}
              />
            ) : (
              <PieChartView isLoading={isLoading} top5Entries={top5Entries} />
            )}
          </>
        )}
      </CardContent>
    </Box>
  );
};

export default TabContent;
