import React, { useState } from "react";

import CloudProviderSelector from "./CloudProviderSelector";
import { Box } from "@mui/material";
import CostDetailsForAllV2 from "./CostDetailsForAllV2";
import CostDetailsForCloudV2 from "./CostDetailsForCloudV2";
import { CLOUD_PROVIDER_ALL } from "../../utils/constants";
import { getDaysFromDuration, getLastNDaysDate } from "../../utils/commonUtil";
import {
  DEFAULT_CLOUD_PROVIDER_SELECTOR,
  DEFAULT_DURATION_KEY,
} from "../../config/config";

const Dashboard = () => {
  const [duration, setDuration] = useState(DEFAULT_DURATION_KEY);
  const [cloudProvider, setCloudProvider] = useState(
    DEFAULT_CLOUD_PROVIDER_SELECTOR
  );
  const [startDate, setStartDate] = useState(
    getLastNDaysDate(getDaysFromDuration(duration))
  );
  const [endDate, setEndDate] = useState(getLastNDaysDate(0));

  const onProviderClicked = (provider) => {
    setCloudProvider(provider);
  };

  const onChangeDuration = (duration) => {
    setDuration(duration);
    setStartDate(getLastNDaysDate(getDaysFromDuration(duration)));
    setEndDate(getLastNDaysDate(0));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 2,
        }}
      >
        <CloudProviderSelector
          selectedDuration={duration}
          setSelectedDuration={setDuration}
          onProviderClicked={onProviderClicked}
          onChangeDuration={onChangeDuration}
        />
        {cloudProvider === CLOUD_PROVIDER_ALL ? (
          <CostDetailsForAllV2
            startDate={startDate}
            endDate={endDate}
            duration={duration}
          />
        ) : (
          <CostDetailsForCloudV2
            startDate={startDate}
            endDate={endDate}
            duration={duration}
            cloudProvider={cloudProvider}
          />
        )}
      </Box>
    </>
  );
};

export default Dashboard;
