import React, { useMemo, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { useForm } from "react-hook-form";

import InputField from "../../InputField/InputField";
import SelectField from "../../Select/SelectField";
import { SolidButton } from "../../../ui/SolidButton";
import { TinyText } from "../../../ui/TinyText";
import TeamsSection from "./TeamsSection";
import { useTeam } from "../../../hooks/useTeam";
import { getInfo } from "../../../utils/jwtUtil";
import { deleteCookie } from "../../../utils/storageUtil";

const TeamManagement = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm();

  const [openSnackbar, setOpenSnackbar] = useState({
    value: false,
    message: "",
    severity: "success",
  });

  const handleClose = () => {
    setOpenSnackbar({
      value: false,
      message: "",
      severity: "success",
    });
  };

  const authenticatedUser = getInfo();
  if (!authenticatedUser || !authenticatedUser.id) {
    deleteCookie("token");
    deleteCookie("user_info");
    // TODO: find better way to redirect
    window.location.href = "/login";
  }

  const { listUserRolesQuery, inviteMemberMutation } = useTeam();

  const userAvailableRoles = useMemo(() => {
    if (!listUserRolesQuery.data) return [];

    return listUserRolesQuery.data?.map((role) => {
      return { label: role.name, value: role.id };
    });
  }, [listUserRolesQuery]);

  const onSubmit = (data) => {
    const payload = {
      email: data.teammateEmail,
      role_id: data.userRoleId,
      organization_id: authenticatedUser.organization.organization_id,
    };

    inviteMemberMutation.mutate(payload, {
      onSuccess: (data) => {
        setOpenSnackbar({
          value: true,
          message: data.message || "Invited successfully",
          severity: "success",
        });
      },
      onError: (err) => {
        setOpenSnackbar({
          value: true,
          message: err.message || "Something went wrong",
          severity: "error",
        });
      },
    });
  };

  return (
    <Box>
      <TeamsSection userAvailableRoles={userAvailableRoles} />
      <Box>
        <Divider sx={{ my: "10px" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box>
              <Typography variant="h5">Invite via Mail</Typography>
              <TinyText>
                Teammates will create a account via the received mail
              </TinyText>
            </Box>
            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <Box>
                  <InputField
                    placeholder="Enter Teammate Email ID"
                    control={control}
                    label="Enter Teammate Email ID"
                    name="teammateEmail"
                    rules={{
                      required: "Email is required",
                    }}
                    size="small"
                  />
                </Box>
                <Box width="200px">
                  <SelectField
                    control={control}
                    label="User Role"
                    name="userRoleId"
                    placeholder="Select User Role"
                    options={userAvailableRoles}
                    size="small"
                    style={{ width: "100%" }}
                    rules={{
                      required: "User Role is required",
                    }}
                  />
                </Box>
              </Box>
              <SolidButton
                type="submit"
                style={{
                  width: "100px",
                  height: "40px",
                  marginBottom:
                    errors.teammateEmail || errors.userRole ? "20px" : "0",
                }}
                disabled={
                  inviteMemberMutation.isPending || isSubmitting || !isValid
                }
              >
                {inviteMemberMutation.isPending ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Invite"
                )}
              </SolidButton>
            </Box>
          </Box>
        </form>
        <Divider sx={{ my: "10px" }} />
      </Box>
      <Snackbar
        open={openSnackbar.value}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={openSnackbar.severity}
          variant="filled"
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TeamManagement;
