import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Box,
  CardContent,
  List,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  CLOUD_PROVIDER_DEFAULT,
  STANDARD_DURATIONS,
  TREND_DOWNWARD,
  TREND_UPWARD,
} from "../../utils/constants";

import { useTotalCostsV2 } from "../../hooks/useCostsV2";
import { ROUTE_ADD_NEW_CLOUD_PROVIDER } from "../../routers/routes";
import { logout } from "../../services/authService";
import { Card } from "../../ui/Card";
import { Chip } from "../../ui/Chip";
import { SmallText } from "../../ui/SmallText";
import { TrendDown } from "../../ui/TrendDown";
import { TrendUp } from "../../ui/TrendUp";
import {
  getDaysFromDuration,
  getLastNDaysDate,
  getLogoForType,
} from "../../utils/commonUtil";
import history from "../../utils/history";

const CARD_WIDTH_MIN = "230px";
const CARD_HEIGHT = "130px";

const CardStyle = {
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
  minWidth: `${CARD_WIDTH_MIN}`,
  height: `${CARD_HEIGHT}`,
};

function AddCloudCard(props) {
  return (
    <Card
      style={CardStyle}
      onClick={() => {
        // props.onProviderClicked(null);
        history.push(ROUTE_ADD_NEW_CLOUD_PROVIDER);
      }}
    >
      <CardContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
          gap: 4,
        }}
      >
        <AddBoxIcon fontSize="large" />
        <SmallText
          style={{
            fontWeight: "600",
          }}
        >
          Add More
        </SmallText>
      </CardContent>
    </Card>
  );
}

function CloudProvider(props) {
  const { cloudProvider, selectedProvider } = props;
  const { trend } = cloudProvider;

  return (
    <Card
      key={cloudProvider.type}
      style={{
        ...CardStyle,
        border:
          selectedProvider === cloudProvider.type
            ? "solid 2px rgb(99,159,223)"
            : "none",
      }}
      {...props}
    >
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          gap: "8px",
          padding: "16px",
          height: `${CARD_HEIGHT}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {cloudProvider.type === "total" ? (
            <SmallText
              style={{
                fontWeight: "800",
              }}
            >
              Total <br></br> Cost
            </SmallText>
          ) : (
            <Box
              component="img"
              width="55px"
              src={getLogoForType(cloudProvider.type)}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Box>
            <Typography style={{ fontWeight: "800", fontSize: "22px" }}>
              ${cloudProvider.value ? cloudProvider.value : "NA"}
            </Typography>
            {cloudProvider.type !== "total" ? (
              <Typography>Total cost</Typography>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                color={
                  trend &&
                  (trend === TREND_UPWARD
                    ? "cloudNudge.red"
                    : trend === TREND_DOWNWARD
                    ? "cloudNudge.green"
                    : "black")
                }
                sx={{
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cloudProvider.percent ? `${cloudProvider.percent} %` : "NA"}
              </Typography>
              <Box
                sx={{
                  m: 1,
                }}
              >
                {trend &&
                  (trend === TREND_UPWARD ? (
                    <TrendUp />
                  ) : trend === TREND_DOWNWARD ? (
                    <TrendDown />
                  ) : null)}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

const DurationFilter = ({ selectedDuration, onChangeDuration }) => {
  return (
    <List
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 0,
        gap: "16px",
        overflow: "hidden",
        scrollbarWidth: "20px",
        marginBottom: "16px",
      }}
    >
      <>
        {STANDARD_DURATIONS.map((item) => (
          <Chip
            key={item.duration}
            color="primary"
            label={item.label}
            variant={selectedDuration === item.duration ? "none" : "outlined"}
            sx={{
              border: "1px solid #2196F380",
              width: "140px",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "uppercase",
              px: 2,
              py: "6px",
            }}
            onClick={() => {
              const days = getDaysFromDuration(item.duration);
              const lastDate = getLastNDaysDate(days);
              onChangeDuration(item.duration, lastDate);
            }}
          />
        ))}
      </>
    </List>
  );
};

const ListProgressIndicator = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "start", width: "100%" }}>
      {[1, 2, 3, 4, 5].map((i) => {
        return (
          <List
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
              padding: 0,
              gap: "16px",
              overflow: "auto",
              maxWidth: "92vw",
            }}
          >
            <ListItem>
              <Skeleton
                animation={i % 2 ? "wave" : ""}
                variant="rectangular"
                width={CARD_WIDTH_MIN}
                height={CARD_HEIGHT}
              />
            </ListItem>
          </List>
        );
      })}
    </Box>
  );
};

function CloudProviderSelector(props) {
  const {
    selectedDuration,
    setSelectedDuration,
    onChangeDuration: onChangeDurationInParent,
  } = props;
  const [selectedProvider, setSelectedProvider] = useState(
    CLOUD_PROVIDER_DEFAULT
  );

  const { getTotalCostsQuery } = useTotalCostsV2(
    getLastNDaysDate(getDaysFromDuration(selectedDuration)),
    getLastNDaysDate(0)
  );

  const dispatch = useDispatch();

  const onChangeDuration = (duration) => {
    setSelectedDuration(duration);
    onChangeDurationInParent(duration);
  };

  useEffect(() => {
    if (
      getTotalCostsQuery.error &&
      getTotalCostsQuery.error.includes("Authentication")
    ) {
      dispatch(logout());
    }
  }, [getTotalCostsQuery.error, dispatch]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          overflow: "hidden",
          gap: 2,
        }}
      >
        <DurationFilter
          selectedDuration={selectedDuration}
          onChangeDuration={onChangeDuration}
        />
      </Box>
      <Card style={{ boxShadow: "none" }}>
        <CardContent style={{ padding: 0 }}>
          {getTotalCostsQuery.isLoading || getTotalCostsQuery.isRefetching ? (
            <ListProgressIndicator />
          ) : getTotalCostsQuery.isError ? (
            <Alert severity="warning" sx={{ my: 1 }}>
              {getTotalCostsQuery.error}
            </Alert>
          ) : (
            <List
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                py: 1,
                gap: "16px",
                overflow: "auto",
                scrollbarWidth: "none",
                maxWidth: "92vw",
              }}
            >
              <CloudProvider
                key="total"
                selectedProvider={selectedProvider}
                cloudProvider={{
                  name: "Total Cost",
                  value: `${getTotalCostsQuery?.data?.total_cost}`,
                  percent: `${getTotalCostsQuery?.data?.trend_percentage}`,
                  type: "total",
                  trend: getTotalCostsQuery?.data?.trend,
                }}
                onClick={() => {
                  setSelectedProvider("total");
                  props.onProviderClicked("total");
                }}
              />
              {getTotalCostsQuery?.data?.total_cloud_cost?.map(
                ({ cloud, total_cost, trend_percentage, trend }) => {
                  return (
                    <CloudProvider
                      key={cloud}
                      selectedProvider={selectedProvider}
                      cloudProvider={{
                        name: cloud,
                        type: cloud,
                        value: total_cost,
                        percent: trend_percentage?.toFixed(2),
                        trend,
                      }}
                      onClick={() => {
                        setSelectedProvider(cloud);
                        props.onProviderClicked(cloud);
                      }}
                    />
                  );
                }
              )}
              <AddCloudCard {...props} />
            </List>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default CloudProviderSelector;
