import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, Skeleton } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";

import { useBudgetInsights } from "../../hooks/useBudgetAlerts";
import SelectComponent from "./Select";
import BudgetInsightsGraph from "./BudgetInsightsGraph";

const chartSetting = {
  yAxis: [
    {
      label: "Costs ($)",
    },
  ],
  width: 1000,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};
const TIME_PERIOD_OPTIONS = [
  { label: "90 days", value: "time_period_90_days_weekly_costs" },
  { label: "60 days", value: "time_period_60_days_weekly_costs" },
  { label: "30 days", value: "time_period_30_days_daily_costs" },
];

const BudgetInsightsTab = (props) => {
  const { selectedCloudProvider, selectedAccessKey } = props;
  const { getBudgetInsightsQuery } = useBudgetInsights(selectedCloudProvider, selectedAccessKey);
  const { data: insightsData, isLoading, isError } = getBudgetInsightsQuery;
  const [trendData, setTrendData] = useState({ data: [], thresholds: [] });

  const [dropDownData, setDropDownData] = useState({
    budget: "",
    timePeriod: TIME_PERIOD_OPTIONS[0].value,
  });

  const handleDropDownChange = (e) => {
    setDropDownData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const budgetOptions = useMemo(() => insightsData?.budget_trend_summary?.map((budget) => ({
    label: budget.budget_name,
    value: budget.budget_name,
  })), [insightsData]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      budget: budgetOptions?.[0]?.value || "",
    }));
  }, [budgetOptions]);

  useEffect(() => {
    if (dropDownData.budget.length > 0 && !getBudgetInsightsQuery.isLoading) {
      const findBudget = insightsData?.individual_budget_trends?.find((budget) => Object.keys(budget)[0] === dropDownData.budget);
      const budget = findBudget ? findBudget[dropDownData.budget] : null;

      if (budget === null) return setTrendData({ ...trendData, data: [], thresholds: [] });

      const dataBasedOnTimePeriod = budget[dropDownData.timePeriod];
      const data = Object.entries(dataBasedOnTimePeriod).map(([key, value]) => ({
        budget_name: key,
        current_value: value.current_value,
        forecasted_value: value.forecasted_value,
      }));

      setTrendData({ ...trendData, data, thresholds: budget.threshold_list ?? [] });
    }
  }, [insightsData, dropDownData?.budget, dropDownData?.timePeriod, getBudgetInsightsQuery.isLoading]);

  if (!selectedAccessKey) {
    return (<>Access key is required...</>);
  }

  if (isLoading) {
    return (
      <Skeleton animation={"wave"} variant="rectangular" height="500px" />
    );
  }

  return (
    <Box paddingTop={"20px"}>
      <Box>
        <Card>
          <CardContent>
            <Box display={"flex"} justifyContent={"center"} height={"calc(100vh - 660px)"} minHeight={"230px"}>
              <BarChart
                loading={getBudgetInsightsQuery.isLoading}
                dataset={insightsData?.budget_trend_summary}
                xAxis={[{
                  scaleType: "band",
                  dataKey: "budget_name",
                  barGapRatio: 0,
                }]}
                series={[
                  { dataKey: "actual_spend", label: "Actual", color: "#0A3F69" },
                  { dataKey: "budget_limit", label: "Budget", color: "#2196F3" },
                  { dataKey: "forecasted_spend", label: "Forecast", color: "#87C9FF" },
                ]}
                {...chartSetting}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box marginTop={"20px"}>
        <SelectComponent name={"budget"} label={"Budget"} options={budgetOptions}
                         value={dropDownData.budget ? dropDownData.budget : ""}
                         onChange={handleDropDownChange} />
        <SelectComponent name={"timePeriod"} label={"Time Period"} options={TIME_PERIOD_OPTIONS}
                         value={dropDownData.timePeriod ? dropDownData.timePeriod : ""}
                         onChange={handleDropDownChange} />
        <Card>
          <CardContent paddingLeft={"30px"}>
            <Box display={"flex"} justifyContent={"center"} height={"calc(100vh - 660px)"} minHeight={"230px"}>
              <BudgetInsightsGraph data={trendData.data} thresholds={trendData.thresholds} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default BudgetInsightsTab;
