import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

//TODO: Replace select component with this one, code is same,
// this component can be used globally

const SelectWithoutRHF = (props) => {
  const {
    name,
    label,
    options = [],
    error,
    value,
    size = "small",
    fullWidth = false,
    ...restProps
  } = props;

  const validValue = options.some((opt) => opt.value === value) ? value : "";

  return (
    <FormControl error={!!error} size={size} fullWidth={fullWidth}>
      <InputLabel id={`${name}-label`} htmlFor={name} shrink={!!validValue}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        value={validValue}
        {...restProps}
      >
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled ?? false}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectWithoutRHF;
