import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// icons & images
import Alert from "@mui/material/Alert";
import { InputTextField } from "../../ui/InputTextField";
import { BrandLogo } from "./BrandLogo";
import { LeftPane } from "./LeftPane";
import { BottomContainer, FullScreenContainer } from "./LoginForm";
import { RightPane } from "./RightPane";

// Import custom components
import { Box, CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { ROUTE_LOGIN } from "../../routers/routes";
import { H4 } from "../../ui/H4";
import { SmallText } from "../../ui/SmallText";
import { SolidButton } from "../../ui/SolidButton";
import history from "../../utils/history";
import AuthActionsSuccessState from "./AuthActionsSuccessState";

const OTPFields = ({ onVerificationSuccess }) => {
  const { verifyOtp, isLoading, data, error } = useAuth();
  const [validationError, setValidationError] = useState(error);
  const [otp, setOtp] = useState("");

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValidationError(null);
    setOtp(newValue);
  };

  useEffect(() => {
    if (data?.data?.message) {
      onVerificationSuccess();
    }

    if (error) {
      setValidationError(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Box
      gap={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <H4
        sx={{
          textAlign: "center",
        }}
      >
        Thank you. Kindly check your email
      </H4>
      <SmallText
        sx={{
          mt: 4,
        }}
      >
        We have sent a verification code to your email. Enter the same code here
        and verify.
      </SmallText>
      <Box
        mt={3}
        mb={1}
        sx={{
          width: "100%",
        }}
      >
        <InputTextField
          fullWidth
          label="OTP/Code"
          onChange={handleChange}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              verifyOtp(ev.target.value);
              ev.preventDefault();
            }
          }}
        />
      </Box>
      <BottomContainer>
        {validationError && (
          <Alert severity="warning" sx={{ my: 1 }}>
            {validationError
              ? validationError
              : `Failed to send password reset link. Please try again`}
          </Alert>
        )}
        {isLoading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <SolidButton
            width="50%"
            variant="contained"
            color="primary"
            disabled={!otp}
            onClick={() => {
              verifyOtp(otp);
            }}
          >
            Verify Code
          </SolidButton>
        )}
      </BottomContainer>
      {/* <SmallText my={4}>
        Haven’t received the email yet ?{" "}
        <MuiLink
          component="button"
          variant="body2"
          underline="none"
          onClick={() => {
            console.info("I'm a button.");
            verifyOtp(otp);
          }}
        >
          Resend Email
        </MuiLink>
      </SmallText> */}
    </Box>
  );
};

const EmailVerificationResult = () => {
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const onVerificationSuccess = () => {
    setVerificationSuccess(true);
  };

  return (
    <FullScreenContainer>
      <LeftPane />
      <RightPane>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "top",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <BrandLogo />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
              }}
              p={2}
            >
              {verificationSuccess ? (
                <AuthActionsSuccessState
                  title="Successful"
                  message="Congratulations! Your email has been successfully verified. We have sent your organisation details to your email. Please proceed to Login."
                  btnLabel="Login"
                  btnAction={() => {
                    history.push(ROUTE_LOGIN);
                  }}
                />
              ) : (
                <OTPFields onVerificationSuccess={onVerificationSuccess} />
              )}
            </Box>
          </Box>
        </Box>
      </RightPane>
    </FullScreenContainer>
  );
};

EmailVerificationResult.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default EmailVerificationResult;
