import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { setCookie } from "../utils/storageUtil";
import { fetch, store, update } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import { CACHED_DATA_CACHE_TIME } from "../config/config";
import { STORAGE_USER_INFO } from "../utils/constants";

const submitFeedbackApi = async (data) => {
  try {
    const resp = await store(`/auth/submit-feedback/`, data);

    return resp?.data?.message;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getAuthenticatedUser = async () => {
  try {
    const resp = await fetch(`/auth/get-user-info/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const updateUserProfile = async (data) => {
  try {
    const resp = await update(`/auth/update-user-info/`, data);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const resetPassword = async (data) => {
  try {
    const resp = await update(`/auth/update-password/`, data);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export default function useProfile() {
  const queryClient = useQueryClient();
  const submitFeedbackMutation = useMutation({
    mutationFn: submitFeedbackApi,
    onSuccess: (data) => {
      return data;
    },
    onError: (e) => {
      return e;
    },
  });

  const getAuthenticatedUserQuery = useQuery({
    queryKey: [QUERY_KEY.AUTHENTICATED_USER_INFO],
    queryFn: getAuthenticatedUser,
    refetchOnWindowFocus: false,
    cacheTime: CACHED_DATA_CACHE_TIME,
    staleTime: CACHED_DATA_CACHE_TIME,
    retry: 2,
  });

  const updateUserProfileMutation = useMutation({
    mutationFn: (payload) => updateUserProfile(payload),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QUERY_KEY.AUTHENTICATED_USER_INFO],
        (oldData) => {
          const updatedData = { ...oldData, ...data.updated_user };
          setCookie(STORAGE_USER_INFO, JSON.stringify(updatedData), 1);
          return updatedData;
        }
      );
    },
    onError: (e) => {
      return e;
    },
  });

  const resetPasswordMutation = useMutation({
    mutationFn: (data) => resetPassword(data),
    onSuccess: (data) => {
      return data;
    },
    onError: (e) => {
      return e;
    },
  });

  return {
    submitFeedbackMutation,
    getAuthenticatedUserQuery,
    updateUserProfileMutation,
    resetPasswordMutation,
  };
}
