import React, { useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Skeleton,
  Button,
  CircularProgress,
  Card,
  Typography,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import ConfirmationDialogV2 from "../../ui/ConfirmationDialogV2";
import {
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
} from "../../utils/constants";
import { useCloudProviderTokenV2 } from "../../hooks/useCloudProviderTokenV2";
import { TOKEN_MODAL_TYPES } from "./AddCloudProviderV2";

const TABLE_COLUMNS_WRT_CLOUD = {
  aws: [
    "Sr No",
    "Key Label",
    "AWS Access Key ID",
    "AWS Secret Access Key",
    "Region",
    // "Accounts",
    "Actions",
  ],
  azure: [
    "Sr No",
    "Key Label",
    "Tenant ID",
    "Client ID",
    "Client Secret",
    "Subscription ID",
    "Resource Group Name",
    "Actions",
  ],
  gcp: ["Sr No", "Key Label", "Project ID", "GCP Credentials File", "Actions"],
  oci: ["Sr No", "Key Label", "Config File ID", "Pem File ID", "Actions"],
};

const handleDownload = (file_url) => {
  const link = document.createElement("a");
  link.href = file_url;
  link.setAttribute("download", file_url.split("/").pop());
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const LoadingState = () => {
  return (
    <Card style={{ padding: "0px" }}>
      <Skeleton width={"100%"} height={"100px"} />
    </Card>
  );
};

const EmptyState = ({ handleAddToken }) => {
  return (
    <Card
      style={{
        display: "grid",
        placeItems: "center",
        height: "100%",
        padding: "20px",
        minHeight: "200px",
      }}
    >
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <Typography variant="subtitle1" my={2}>
          No tokens found, Please add a token to continue
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddToken}>
          Add Token
        </Button>
      </Box>
    </Card>
  );
};

const getTableBody = (row, selectedCloudProvider) => {
  switch (selectedCloudProvider) {
    case CLOUD_PROVIDER_AZURE:
      return (
        <>
          <TableCell>{row.key_label}</TableCell>
          <TableCell>{row.tenant_id}</TableCell>
          <TableCell>{row.client_id}</TableCell>
          <TableCell>{row.client_secret}</TableCell>
          <TableCell>{row.subscription_id}</TableCell>
          <TableCell>{row.resource_group_name}</TableCell>
        </>
      );
    case CLOUD_PROVIDER_GCP:
      return (
        <>
          <TableCell>{row.key_label ? row.key_label : "NA"}</TableCell>
          <TableCell>{row.project_id}</TableCell>
          <TableCell>
            {row.credential_file.length > 8
              ? `${row.credential_file.slice(0, 8)}...`
              : row.credential_file}
            <SaveAltIcon
              variant="outlined"
              color="primary"
              onClick={() => handleDownload(row.credential_file)}
              style={{
                marginLeft: 8,
                marginTop: 8,
                marginBottom: -4,
                cursor: "pointer",
              }}
            />
          </TableCell>
        </>
      );
    case CLOUD_PROVIDER_OCI:
      return (
        <>
          <TableCell>{row.key_label ? row.key_label : "NA"}</TableCell>
          <TableCell>
            {row.config_file_name.length > 8
              ? `${row.config_file_name.slice(0, 8)}...`
              : row.config_file_name}
            <SaveAltIcon
              variant="outlined"
              color="primary"
              onClick={() => handleDownload(row.config_file)}
              style={{
                marginLeft: 8,
                marginTop: 8,
                marginBottom: -4,
                cursor: "pointer",
              }}
            />
          </TableCell>

          <TableCell>
            {row.pem_file_name.length > 8
              ? `${row.pem_file_name.slice(0, 8)}...`
              : row.pem_file_name}
            <SaveAltIcon
              variant="outllined"
              color="primary"
              onClick={() => handleDownload(row.pem_file)}
              style={{
                marginLeft: 8,
                marginTop: 8,
                marginBottom: -4,
                cursor: "pointer",
              }}
            />
          </TableCell>
        </>
      );
    default:
      return (
        <TableRow>
          <TableCell
            colSpan={TABLE_COLUMNS_WRT_CLOUD[selectedCloudProvider].length}
          >
            <Box
              style={{
                display: "grid",
                placeItems: "center",
                height: "100%",
                gap: "20px",
                padding: "20px",
              }}
            >
              <Typography variant="subtitle1">
                Invalide Cloud Provider
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
  }
};

const TokenTable = ({
                      selectedCloudProvider,
                      handleEdit,
                      handleAddEditTokenModalOpen,
                      showSnackbar,
                    }) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState({
    value: false,
    tokenId: null,
  });
  const { getTokenListV2Query, deleteTokenMutation } = useCloudProviderTokenV2(
    selectedCloudProvider,
  );

  if (getTokenListV2Query.isLoading) {
    return <LoadingState />;
  }

  if (getTokenListV2Query.data?.length === 0) {
    return (
      <EmptyState
        handleAddToken={() =>
          handleAddEditTokenModalOpen(TOKEN_MODAL_TYPES.ADD)
        }
      />
    );
  }

  const handleCancel = () => {
    setIsDeleteConfirmationOpen({ value: false, tokenId: null });
  };

  const handleDelete = (tokenId) => {
    setIsDeleteConfirmationOpen({ value: true, tokenId });
  };

  const handleDeleteConfirmation = () => {
    deleteTokenMutation.mutate(isDeleteConfirmationOpen.tokenId, {
      onSuccess: () => {
        setIsDeleteConfirmationOpen({ value: false, tokenId: null });
        showSnackbar("success", "Token deleted successfully");
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          my: 2,
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS_WRT_CLOUD[selectedCloudProvider].map(
                  (column) => (
                    <TableCell sx={{ fontWeight: "bold" }} key={column}>
                      {column}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {getTokenListV2Query.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {getTableBody(row, selectedCloudProvider)}
                  <TableCell>
                    <Button
                      sx={{ margin: "2px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(row)}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ margin: "2px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationDialogV2
          title="Are you sure you want to delete?"
          open={isDeleteConfirmationOpen.value}
          handleClose={() => setIsDeleteConfirmationOpen(false)}
          error={deleteTokenMutation.error?.message}
          actions={
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="text" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteConfirmation}
                disabled={deleteTokenMutation.isPending}
              >
                {deleteTokenMutation.isPending ? <CircularProgress /> : "Ok"}
              </Button>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default TokenTable;
