import React, { useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardHeader,
  Chip,
  Snackbar,
  Typography,
  Button,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import { useTeam } from "../../../hooks/useTeam";
import UserRow from "./UserRow";
import { getInfo } from "../../../utils/jwtUtil";
import { handleLogoutV2 } from "../../../services/authService";

const TeamsSection = ({ userAvailableRoles }) => {
  const [openSnackbar, setOpenSnackbar] = useState({
    value: false,
    message: "",
    severity: "success",
  });

  const handleClose = () => {
    setOpenSnackbar({
      value: false,
      message: "",
      severity: "success",
    });
  };
  const { getTeamMembersQuery, leaveOrganizationMutation } = useTeam();
  const userInfo = getInfo();

  const handleLeaveOrganization = () => {
    leaveOrganizationMutation.mutate(
      {},
      {
        onSuccess: (data) => {
          setOpenSnackbar({
            value: true,
            message: data.message || "Leave organization successfully",
            severity: "success",
          });
          setTimeout(() => {
            handleLogoutV2();
          }, 1000);
        },
        onError: (err) => {
          setOpenSnackbar({
            value: true,
            message: err.message || "Something went wrong",
            severity: "error",
          });
        },
      },
    );
  };

  return (
    <Box>
      <Card style={{ padding: "20px" }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <CardHeader title="Team" />
          <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Chip
              label={userInfo?.organization?.name || "No org found"}
              style={{
                backgroundColor: "rgba(33, 150, 243, 0.09)",
                fontWeight: "bold",
              }}
            />
            <Button variant="outlined" onClick={handleLeaveOrganization}
                    disabled={leaveOrganizationMutation.isPending}>
              {leaveOrganizationMutation.isPending ? (
                <CircularProgress size={24} />
              ) : (
                "Leave Organization"
              )}
            </Button>
          </Box>
        </Box>
        <Box style={{ marginLeft: "20px" }}>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography fontWeight="bold">Member details</Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold">Role</Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold">Action</Typography>
            </Box>
          </Box>
          {getTeamMembersQuery.isLoading || getTeamMembersQuery.isRefetching ? (
              <Box py={"20px"}>
                <Skeleton
                  animation={"wave"}
                  variant="rectangular"
                  height={"30px"}
                />
              </Box>
            )
            : (
              getTeamMembersQuery.data?.map((member) => {
                return (
                  <UserRow
                    key={member.id}
                    member={member}
                    userAvailableRoles={userAvailableRoles}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                );
              })
            )}
        </Box>
      </Card>
      <Snackbar
        open={openSnackbar.value}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={openSnackbar.severity}
          variant="filled"
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TeamsSection;
