import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Card, CardContent, CircularProgress, FormHelperText, Skeleton, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";

import InputField from "../InputField/InputField";
import SelectField from "../Select/SelectField";
import CheckboxField from "../CheckboxField/CheckboxField";
import {
  useCreateBudget,
  useGetAggregateCostList, useGetBudget,
  useGetBudgetCostDimension,
  useGetBudgetFrequencyList,
  useGetBudgetPreviewGraphData,
  useGetBudgetScopeList,
  useGetBudgetTriggerOnList,
  useGetDimensionValues,
  useGetNotificationChannels, useUpdateBudget,
} from "../../hooks/useBudgetAlerts";
import { BudgetAlertSnackBarProvider, MODES } from "./BudgetAlerts";
import Select from "./Select";
import CostTrendGraph from "./CostTrendGraph";
import useQueryParams from "../../hooks/customHooks/useQueryParams";

const ALL_AWS_SERVICES = 1;
const BUDGET_SCOPE_FILTER_SPECIFIC_AWS_COST_DIMENSIONS = 2;

const CreateBudgetTab = (props) => {
  const { cloudProviderKeysOptions, selectedCloudProvider, activeMode, handleModeChange } = props;
  const queryParams = useQueryParams();
  const { control, handleSubmit, watch, setValue, getValues, setError, formState: { errors }, reset } = useForm({
    defaultValues: {
      alertFields: [{ threshold: "", frequency_id: "", trigger_on: "" }],
      budgetAmount: "",
      budgetScope: "",
      budgetName: "",
      costAggregatedBy: "",
      costDimension: "",
      dimensionValues: [],
      key: "",
      subscriberEmails: "",
      notificationChannels: [],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "alertFields",
  });

  const { handleShowSnackbar } = useContext(BudgetAlertSnackBarProvider);

  const { getBudgetScopeListQuery } = useGetBudgetScopeList();
  const { getBudgetCostDimensionQuery } = useGetBudgetCostDimension();
  const { getAggregateCostListQuery } = useGetAggregateCostList();
  const { getBudgetFrequencyQuery } = useGetBudgetFrequencyList();
  const { getBudgetTriggerOnQuery } = useGetBudgetTriggerOnList();
  const selectedKey = watch("key");
  const selectedCostDimension = watch("costDimension");
  const { getBudgetDimensionValuesQuery } = useGetDimensionValues(selectedCloudProvider, selectedKey, selectedCostDimension);
  const { getNotificationChannelsQuery } = useGetNotificationChannels();
  const { createBudgetMutation } = useCreateBudget();
  const { updateBudgetMutation } = useUpdateBudget();
  const { getBudgetPreviewGraphDataQuery } = useGetBudgetPreviewGraphData(watch("key"), watch("costAggregatedBy"), watch("budgetScope"), watch("costDimension"), watch("dimensionValues"));
  const { getBudgetQuery } = useGetBudget(selectedCloudProvider, queryParams.get("budgetId"), queryParams.get("keyId"), activeMode === MODES.EDIT);

  const budgetScopeOptions = useMemo(() => {
    return getBudgetScopeListQuery.data?.map((scope) => ({
      label: scope.name,
      value: scope.id,
    })) ?? [];
  }, [getBudgetScopeListQuery]);

  // This is needed as dimensionValues are dependent upon costDimension
  // So when costDimension changes, dimensionValues should be reset
  useEffect(() => {
    setValue("dimensionValues", []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watch("costDimension")]);

  const budgetToEdit = useMemo(() => {
    if (!getBudgetQuery.data) return null;
    return getBudgetQuery.data;
  }, [getBudgetQuery.data]);

  useEffect(() => {
    if (activeMode === MODES.EDIT) {
      const budgetName = budgetToEdit?.budget_name;
      const budgetKey = budgetToEdit?.key_id;
      const scopeId = budgetToEdit?.scope?.id;
      const costDimension = budgetToEdit?.dimension?.id;
      const costAggregatedBy = budgetToEdit?.aggregate_cost_by?.id;
      const budgetAmount = budgetToEdit?.limit;
      const alertFields = budgetToEdit?.alert?.map((alert) => {
        return {
          threshold: alert.threshold,
          frequency_id: alert.frequency?.id,
          trigger_on: alert.trigger_on?.id,
          id: alert.id,
        };
      });
      const subscriberEmails = budgetToEdit?.email?.join(", ");
      const notificationChannels = budgetToEdit?.notification_channel;

      setValue("budgetName", budgetName);
      setValue("key", budgetKey);
      setValue("budgetScope", scopeId);
      setValue("costDimension", costDimension);
      setValue("costAggregatedBy", costAggregatedBy);
      setValue("budgetAmount", budgetAmount);
      setValue("alertFields", alertFields);
      setValue("subscriberEmails", subscriberEmails);
      setValue("notificationChannels", notificationChannels);
    }
  }, [budgetToEdit, activeMode, setValue]);

  const budgetScope = watch("budgetScope");
  useEffect(() => {
    if (activeMode === MODES.EDIT && budgetScope === BUDGET_SCOPE_FILTER_SPECIFIC_AWS_COST_DIMENSIONS) {
      const dimensionValues = budgetToEdit?.dimension_values;
      setValue("dimensionValues", dimensionValues);
    }
  }, [budgetToEdit, activeMode, setValue, budgetScope]);

  useEffect(() => {
    // Reset form on mode change
    if (activeMode === MODES.CREATE) {
      reset();
    }
  }, [activeMode, reset]);

  const budgetCostDimensionOptions = useMemo(() => {
    return getBudgetCostDimensionQuery.data?.map((dimension) => ({
      label: dimension.name,
      value: dimension.id,
    })) ?? [];
  }, [getBudgetCostDimensionQuery]);

  const aggregateCostListOptions = useMemo(() => {
    return getAggregateCostListQuery.data?.map((cost) => ({
      label: cost.name,
      value: cost.id,
    })) ?? [];
  }, [getAggregateCostListQuery]);

  const budgetFrequencyOptions = useMemo(() => {
    return getBudgetFrequencyQuery.data?.map((frequency) => ({
      label: frequency.name,
      value: frequency.id,
    })) ?? [];
  }, [getBudgetFrequencyQuery]);

  const budgetTriggerOnOptions = useMemo(() => {
    return getBudgetTriggerOnQuery.data?.map((triggerOn) => ({
      label: triggerOn.name,
      value: triggerOn.id,
    })) ?? [];
  }, [getBudgetTriggerOnQuery]);

  const budgetDimensionValuesOptions = useMemo(() => {
    return getBudgetDimensionValuesQuery.data?.map((dimensionValue) => ({
      label: dimensionValue,
      value: dimensionValue,
    })) ?? [];
  }, [getBudgetDimensionValuesQuery.data]);

  const notificationChannelsOptions = useMemo(() => {
    return getNotificationChannelsQuery.data?.map((channel) => ({
      label: channel.name,
      value: channel.id,
    })) ?? [];
  }, [getNotificationChannelsQuery.data]);

  const [selectedGraphDataOption, setSelectedGraphDataOption] = useState({
    value: getBudgetFrequencyQuery.data?.[0]?.id,
    label: getBudgetFrequencyQuery.data?.[0]?.name,
  });

  const handleAddAlertFields = () => {
    append({ threshold: 0, frequency_id: "", trigger_on: "" });
  };

  const handleRemoveAlertFields = (id) => {
    remove(id);
  };

  const validateEmails = useCallback((emailString) => {
    const emails = emailString.split(",").map((email) => email.trim());
    const checkDuplicateEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
    if (checkDuplicateEmails.length > 0) {
      return "Duplicate emails are not allowed";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    const invalidEmails = emails.filter((email) => !emailRegex.test(email));
    return invalidEmails.length === 0 || "Invalid email(s) detected";
  }, []);

  const selectedNotificationChannels = watch("notificationChannels") || [];
  const handleCheckboxChange = (checked, value) => {
    if (checked) {
      setValue("notificationChannels", [value], { shouldValidate: true });
    } else {
      setValue("notificationChannels", selectedNotificationChannels.filter((v) => v !== value), { shouldValidate: true });
    }
  };

  const handleSubmitForm = useCallback((data) => {
    const emailList = data.subscriberEmails.split(",").map((email) => email.trim());
    const dataToSubmitForCreate = {
      name: data.budgetName,
      key_id: data.key,
      limit: data.budgetAmount ? parseInt(data.budgetAmount) : "",
      aggregate_cost_by: data.costAggregatedBy,
      scope_id: data.budgetScope,
      dimension_id: data.costDimension,
      dimension_values: data.dimensionValues,
      email: emailList,
      notification_channel: data.notificationChannels,
      alert: data.alertFields,
    };

    const dataToSubmitForEdit = {
      name: data.budgetName,
      key_id: parseInt(data.key),
      limit: data.budgetAmount ? parseInt(data.budgetAmount) : "",
      aggregate_cost_by: data.costAggregatedBy,
      scope_id: data.budgetScope,
      dimension_id: data.costDimension,
      dimension_values: data.dimensionValues,
      email: emailList,
      notification_channel: data.notificationChannels,
      alert: data.alertFields,
    };

    if (activeMode === MODES.EDIT) {
      updateBudgetMutation.mutate({ ...dataToSubmitForEdit, budgetId: budgetToEdit?.id }, {
        onSuccess: (data) => {
          handleShowSnackbar(
            data.message || "Budget updated successfully",
            "success",
          );
          handleModeChange(MODES.CREATE);
          updateBudgetMutation.reset();
          reset();
        },
        onError: (error) => {
          handleShowSnackbar(error.message, "error");
          updateBudgetMutation.reset();
          // reset();
        },
      });
    }

    if (activeMode === MODES.CREATE) {
      createBudgetMutation.mutate(dataToSubmitForCreate, {
        onSuccess: (data) => {
          handleShowSnackbar(
            data.message || "Budget created successfully",
            "success",
          );
          handleModeChange(MODES.CREATE);
          createBudgetMutation.reset();
          reset();
        },
        onError: (error) => {
          handleShowSnackbar(
            error.message || "Something went wrong",
            "error",
          );
        },
      });
    }
  }, [createBudgetMutation, handleShowSnackbar, activeMode, reset, updateBudgetMutation]);

  const graphData = useMemo(() => {
    const rawData = getBudgetPreviewGraphDataQuery.data?.[selectedGraphDataOption.label?.toLowerCase()] ?? [];

    return Object.entries(rawData || {}).map(([key, value]) => {
      return { label: key, ...value };
    });
  }, [getBudgetPreviewGraphDataQuery.data, selectedGraphDataOption]);

  const [selectedThresholds, setSelectedThresholds] = useState([]);

  useEffect(() => {
    const subscription = watch((value) => {
        setSelectedThresholds(() => value.alertFields?.filter(
          (threshold) => {
            return threshold.frequency_id === selectedGraphDataOption.value;
          },
        ) ?? []);
      },
    );

    if (activeMode === MODES.EDIT) {
      setSelectedThresholds(() => watch().alertFields?.filter(
        (threshold) => {
          return threshold.frequency_id === selectedGraphDataOption.value;
        },
      ) ?? []);
    }
    return () => subscription.unsubscribe();
  }, [activeMode, selectedGraphDataOption, selectedGraphDataOption.value, watch]);

  const refetchThresholds = useCallback((selectedGraphDataOption) => {
    setSelectedThresholds(() => getValues("alertFields")?.filter(
      (threshold) => {
        return threshold.frequency_id === selectedGraphDataOption.value;
      },
    ) ?? []);
  }, [getValues]);

  const handleCancel = () => {
    reset();
    handleModeChange(MODES.CREATE);
  };

  useEffect(() => {
    if (!getBudgetFrequencyQuery.isLoading) {
      setSelectedGraphDataOption({
        value: getBudgetFrequencyQuery.data?.[0]?.id,
        label: getBudgetFrequencyQuery.data?.[0]?.name,
      });
    }
  }, [getBudgetFrequencyQuery.data, getBudgetFrequencyQuery.isLoading, selectedCloudProvider]);

  const selectedBudgetScope = watch("budgetScope");
  const selectedBudgetCostDimension = watch("costDimension");
  const costTrendHeader = useMemo(() => {
    if (selectedBudgetScope === ALL_AWS_SERVICES) {
      return budgetScopeOptions.find((scope) => scope.value === ALL_AWS_SERVICES)?.label;
    }

    if (selectedBudgetScope === BUDGET_SCOPE_FILTER_SPECIFIC_AWS_COST_DIMENSIONS) {
      return budgetCostDimensionOptions.find((dimension) => dimension.value === selectedBudgetCostDimension)?.label;
    }

    return "";
  }, [selectedBudgetScope, budgetScopeOptions, budgetCostDimensionOptions, selectedBudgetCostDimension]);

  const selectedDimensionValues = watch("dimensionValues");
  const costTrendSubHeader = useMemo(() => {
    return selectedDimensionValues.length > 0 ? `${costTrendHeader} : ${selectedDimensionValues.join(", ")}` : "";
  }, [costTrendHeader, selectedDimensionValues]);

  useEffect(() => {
    if (activeMode === MODES.CREATE && selectedKey.length === 0) {
      setError("key", { type: "required", message: "Please select a key first." });
    } else {
      setError("key", undefined);
    }
  }, [activeMode, selectedKey, setError]);

  if (getBudgetScopeListQuery.isLoading || getBudgetCostDimensionQuery.isLoading || getAggregateCostListQuery.isLoading || getBudgetFrequencyQuery.isLoading || getBudgetTriggerOnQuery.isLoading || getNotificationChannelsQuery.isLoading || getBudgetQuery.isLoading || getBudgetQuery.isFetching) {
    return (
      <Box
        display={"grid"}
        gridTemplateColumns={"2fr 1fr"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 215px)"}
        gap={"20px"}
      >
        <Box height={"100%"} width={"100%"}>
          <Skeleton variant="rectangular" height={"calc(100% - 130px)"} width={"100%"} />
          <Skeleton variant="rectangular" height={"110px"} width={"100%"} sx={{ marginTop: "20px" }} />
        </Box>
        <Skeleton variant="rectangular" height={"100%"} width={"100%"} />
      </Box>
    );
  }

  if (getBudgetQuery.isError) {
    return (
      <Box
        display={"grid"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 215px)"}
        gap={"20px"}
      >
        <Typography variant="h6" color={"error.main"}>
          {getBudgetQuery.error?.message ?? "An error occurred while fetching the budget data. Please try again later"}
        </Typography>
      </Box>
    );
  }


  return (
    <Box display={"grid"} gridTemplateColumns={"2fr 1fr"} gap={"24px"}>
      <Box>
        <Card>
          <CardContent sx={{ padding: "24px" }}>
            <Box display={"flex"} alignItems={"center"} gap={"24px"} marginBottom={"24px"}>
              <Typography variant="h5">{activeMode === MODES.CREATE ? "Create" : "Edit"} Budget</Typography>
              {activeMode === MODES.EDIT ?
                <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => handleModeChange(MODES.CREATE)}>
                  + Create
                </Button>
                :
                null
              }
            </Box>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box display={"flex"} gap={"24px"}>
                <Box
                  flex={1}
                  sx={{
                    borderRight: "1px solid",
                    paddingRight: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <InputField
                    control={control}
                    name="budgetName"
                    label="Budget Name"
                    placeholder="Budget Name"
                    size={"small"}
                    rules={{ required: { value: true, message: "Budget Name is required" } }}
                    fullWidth
                  />
                  <SelectField
                    control={control}
                    name="key"
                    label="Key"
                    placeholder="Key"
                    fullWidth
                    size={"small"}
                    rules={{ required: { value: true, message: "Key is required" } }}
                    options={cloudProviderKeysOptions}
                    disabled={activeMode === MODES.EDIT}
                  />
                  <SelectField
                    control={control}
                    name="budgetScope"
                    label="Budget Scope"
                    placeholder="Budget Scope"
                    fullWidth
                    size={"small"}
                    rules={{ required: { value: true, message: "Budget Scope is required" } }}
                    options={budgetScopeOptions}
                  />
                  {
                    watch("budgetScope") === BUDGET_SCOPE_FILTER_SPECIFIC_AWS_COST_DIMENSIONS ? (
                      <>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <SelectField
                            control={control}
                            name="costDimension"
                            label="Cost Dimension"
                            placeholder="Cost Dimension"
                            fullWidth
                            size={"small"}
                            rules={{ required: { value: true, message: "Cost Dimension is required" } }}
                            options={budgetCostDimensionOptions}
                            disabled={getBudgetCostDimensionQuery.isLoading}
                            sx={{ "& .Mui-disabled": { opacity: 0.5, cursor: "not-allowed" } }}
                          />
                          {getBudgetCostDimensionQuery.isLoading ? (
                            <CircularProgress size={24} style={{ color: "blue" }} />
                          ) : null}
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <SelectField
                            control={control}
                            name="dimensionValues"
                            label="Dimension Values"
                            placeholder="Dimension Values"
                            multiple
                            fullWidth
                            rules={{ required: { value: true, message: "Dimension Values are required" } }}
                            size={"small"}
                            options={budgetDimensionValuesOptions}
                            disabled={getBudgetDimensionValuesQuery.isLoading || getBudgetCostDimensionQuery.isLoading}
                            sx={{ "& .Mui-disabled": { opacity: 0.5, cursor: "not-allowed" } }}
                            renderValue={(selected) => (
                              <Box sx={{
                                display: "flex",
                                gap: 0.5,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}>
                                {selected.length > 3
                                  ? `${selected.slice(0, 3).join(", ")} +${selected.length - 3} more`
                                  : selected.join(", ")}
                              </Box>
                            )}
                          />
                          {getBudgetDimensionValuesQuery.isLoading ? (
                            <CircularProgress size={24} style={{ color: "blue" }} />
                          ) : null}
                        </Box>
                      </>
                    ) : null
                  }
                  <SelectField
                    control={control}
                    name="costAggregatedBy"
                    label="Cost Aggregated By"
                    placeholder="Cost Aggregated By"
                    fullWidth
                    size={"small"}
                    rules={{ required: { value: true, message: "Cost Aggregated By is required" } }}
                    options={aggregateCostListOptions}
                  />
                  <InputField
                    control={control}
                    name="budgetAmount"
                    label="Budget Amount"
                    placeholder="Budget Amount"
                    size={"small"}
                    type="number"
                    rules={{
                      required: { value: true, message: "Budget Amount is required" },
                    }}
                    fullWidth
                  />
                </Box>
                <Box flex={1}>
                  <Typography variant="h5" marginBottom={"24px"}>Set Alert</Typography>
                  {fields.map((field, index) => {
                    return (
                      <Box display="flex" gap={2} marginBottom={"24px"} key={field.id}>
                        <InputField
                          type={"number"}
                          control={control}
                          name={`alertFields.${index}.threshold`}
                          rules={{
                            required: {
                              value: true,
                              message: "Threshold is required",
                            },
                            min: { value: 1, message: "Number must be greater than 0" },
                            validate: (value) => {
                              const parsedValue = parseFloat(value);
                              if (isNaN(parsedValue)) {
                                return "Threshold must be a number";
                              }
                              return true;
                            },
                          }}
                          placeholder={"$"}
                          label={"Threshold"}
                          size={"small"}
                          fullWidth
                        />
                        <SelectField
                          control={control}
                          name={`alertFields.${index}.frequency_id`}
                          label={`Frequency`}
                          size={"small"}
                          fullWidth
                          rules={{ required: { value: true, message: "Frequency is required" } }}
                          options={budgetFrequencyOptions}
                        />
                        <Box key={index} position={"relative"} width={"100%"}>
                          <SelectField
                            control={control}
                            name={`alertFields.${index}.trigger_on`}
                            label={`Trigger On`}
                            size={"small"}
                            fullWidth
                            rules={{ required: { value: true, message: "Trigger On is required" } }}
                            options={budgetTriggerOnOptions}
                          />
                          {fields.length > 1 ? (
                            <Box color={"red"} position={"absolute"} top={"-10px"} right={"-10px"} sx={{
                              backgroundColor: "white",
                              border: "1px solid red",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              boxShadow: "0px 1.667px 5px 1.667px rgba(0, 0, 0, 0.15)",
                            }}>
                              <RemoveIcon
                                fontSize={"small"}
                                onClick={() => handleRemoveAlertFields(index)}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    );
                  })}
                  {fields.length < 3 ?
                    (
                      <Button
                        variant={"outlined"}
                        onClick={handleAddAlertFields}
                      >
                        + Add
                      </Button>
                    ) : null}
                  <Box marginY={"24px"}>
                    <Typography variant="h5" marginBottom={"16px"}>Subscriber</Typography>
                    <InputField
                      control={control}
                      name="subscriberEmails"
                      label="Subscriber Email"
                      placeholder="Subscriber Email"
                      size={"small"}
                      rules={{
                        validate: validateEmails,
                      }}
                      fullWidth
                    />
                    <FormHelperText>Enter comma separated text eg. (email1,email2)</FormHelperText>
                  </Box>
                  <Box>
                    <Typography variant="h5">Budget Notification</Typography>
                    {notificationChannelsOptions.map((channel) => {
                      return (
                        <Box key={channel.value}>
                          <CheckboxField
                            control={control}
                            name="notificationChannels"
                            label={channel.label}
                            onChange={(e) => handleCheckboxChange(e.target.checked, channel.value)}
                            value={channel.value}
                            shouldShowError={false}
                            rules={{ validate: (value) => value.length === 1 || "Please choose one notification channel" }}
                            disabled={channel.label.toLowerCase() === "teams"}
                          />
                        </Box>
                      );
                    })}
                    {errors.notificationChannels && (
                      <FormHelperText error>{errors.notificationChannels.message}</FormHelperText>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                <Button variant={"outlined"} onClick={handleCancel}>Cancel</Button>
                <Button variant={"contained"} type={"submit"}
                        disabled={createBudgetMutation.isPending || updateBudgetMutation.isPending}>
                  {createBudgetMutation.isPending || updateBudgetMutation.isPending ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
        <Box sx={{ border: "1px solid #2196F380", padding: "16px", marginTop: "20px" }}>
          <Typography variant="body2">
            <Typography variant="body2" fontWeight={"bold"} component={"span"}>Included: </Typography>
            Taxes, Upfront reservation fees, Recurring reservation chargers, Other Subscription costs, Support Charges,
            Discounts
          </Typography>
          <Typography variant="body2">
            <Typography variant="body2" fontWeight={"bold"} component={"span"}>Excluded: </Typography>
            Refunds, Credits
          </Typography>
          <Typography variant="body2">
            <Typography variant="body2" fontWeight={"bold"} component={"span"}>Cost aggregated by: </Typography>
            Unblended Costs
          </Typography>
        </Box>
      </Box>
      <Box>
        <Card>
          <CardContent sx={{ height: "100%" }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box>
                <Typography variant="h5">Cost Trend
                  : {costTrendHeader}
                </Typography>
                {costTrendSubHeader}
              </Box>
              <Box>
                {/*TODO: Replace Select with SelectWithoutRHF -> code is same*/}
                <Select
                  name={"graphDataOption"}
                  label={"Frequency"}
                  options={budgetFrequencyOptions}
                  value={selectedGraphDataOption.value}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const label = budgetFrequencyOptions.find((option) => option.value === selectedValue)?.label;
                    setSelectedGraphDataOption({ value: selectedValue, label });
                    refetchThresholds({ value: selectedValue, label });
                  }} />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }}>
              {getBudgetPreviewGraphDataQuery.isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                  <CircularProgress />
                </Box>
              ) : (
                <CostTrendGraph data={graphData} thresholds={selectedThresholds} />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CreateBudgetTab;
