import React from "react";
import { Box, Typography, Divider, Card, CardContent, Skeleton, Alert } from "@mui/material";

import { SolidButton } from "../../ui/SolidButton";
import { ReactComponent as CheckFilledWithGradient } from "../../assets/CheckFilledWithGradient.svg";
import { GradeintText } from "../../pages/landingPage/LandingPage";
import history from "../../utils/history";
import { ROUTE_SIGNUP } from "../../routers/routes";
import { INFO_CLOUDNUDGE_EMAIL } from "../../utils/constants";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import usePricing from "../../hooks/usePricing";

const EVALUATE_PLAN = "Evaluate Plan";
const INDIVIDUAL_PLAN = "Individual Plan";
const ORGANIZATION_PLAN = "Organization Plan";
const ENTERPRISE_PLAN = "Enterprise Plan";

const GIF_IMAGES_FOR_PLANS = {
  [EVALUATE_PLAN]: "/assets/gif/free.gif",
  [INDIVIDUAL_PLAN]: "/assets/gif/starter.gif",
  [ORGANIZATION_PLAN]: "/assets/gif/premium.gif",
  [ENTERPRISE_PLAN]: "/assets/gif/ultimate.gif",
};

const HIGHLIGHTED_STRINGS = {
  [EVALUATE_PLAN]: [
    "free 3 VM recommendations.",
    "Valid up to 48 hrs.",
  ],
  [INDIVIDUAL_PLAN]: "Up to 5k USD monthly spend.",
  [ORGANIZATION_PLAN]: "Up to 20k USD monthly spend.",
  [ENTERPRISE_PLAN]: ["Customize your pricing", "Access Cross-Cloud"],
};

const CHARACTERISTICS = {
  monthly_tracked_cloud_spend: "Monthly tracked cloud spend",
  number_of_users: "Number of users",
  accounts: "Accounts",
  supported_clouds: "Number of cloud supported",
  usage_analytics: "Usage Analytics",
  resource_optimization: "Resource Optimization",
  reports: "Reports",
  anamoly_detection: "Anomaly Detection",
  real_time_monitoring: "Real-time Monitoring",
  budget_alerts: "Budget Alerts",
  priority_support: "Priority Support",
  cross_cloud: "Cross-Cloud",
};

const GradientCircleCheckIcon = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        background: "linear-gradient(to right, #2196F3, #10CCD2)",
        padding: "2px",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "50%",
        }}
      >
        <CheckFilledWithGradient />
      </Box>
    </Box>
  );
};

const applyHighlighting = (description, highlights) => {
  if (!description) return description;

  // Convert single strings to an array
  const highlightArray = Array.isArray(highlights) ? highlights : [highlights];

  // Escape special characters in regex
  const escapedHighlights = highlightArray.map(str => str.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"));

  // Create regex pattern to match all highlighted phrases
  const regex = new RegExp(`(${escapedHighlights.join("|")})`, "gi");

  return description.split(regex).map((part, index) =>
    highlightArray.includes(part) ? (
      <Typography
        variant={"h5"}
        key={index}
        sx={{
          color: "#2196F3",
          fontWeight: "bold",
        }}
      >
        {part}
      </Typography>
    ) : (
      part
    ),
  );
};

const PlanDescription = ({ description, planType }) => {
  return <Typography variant={"h5"}>{applyHighlighting(description, HIGHLIGHTED_STRINGS[planType])}</Typography>;
};


const PricingCard = ({ plan, showYearlyPrice, breakpoint }) => {
  return (
    <Card
      style={{ flexShrink: 0, borderRadius: "14px", marginTop: "34px" }}
      key={plan.id || plan.plan_name}
    >
      <CardContent
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "22px",
          height: "calc(100% - 40px)",
          position: "relative",
        }}
      >
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          {plan.plan_name}
        </Typography>
        <Divider />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={GIF_IMAGES_FOR_PLANS[plan.plan_name]}
            alt="free"
            style={{ width: "40px", height: "40px" }}
          />
          <Typography variant="h3" textAlign="center">
            {typeof plan.price === "string"
              ? plan.price
              : showYearlyPrice
                ? plan.price?.yearly
                : plan.price?.monthly}
          </Typography>
        </Box>
        <Box style={{ textAlign: "center", marginBottom: "20px" }}>
          <PlanDescription description={plan.description} planType={plan.plan_name} />
        </Box>
        <Box sx={{
          position: "relative",
          bottom: "0",
        }}>
          <Divider />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <SolidButton
              style={{
                padding: "4px 24px",
                background: plan.plan_name === ENTERPRISE_PLAN ? "linear-gradient(to right, #2196F3, #10CCD2)" : "#2196f3",
              }}
              onClick={() => {
                plan.name === EVALUATE_PLAN &&
                breakpoint !== "mobile" &&
                breakpoint !== "tablet"
                  ? history.push(ROUTE_SIGNUP)
                  : window.open(`mailto:${INFO_CLOUDNUDGE_EMAIL}`);
              }}
            >
              {plan.name === EVALUATE_PLAN &&
              breakpoint !== "mobile" &&
              breakpoint !== "tablet"
                ? "Sign Up"
                : "Talk to us"}
            </SolidButton>
          </Box>
        </Box>
      </CardContent>
    </Card>);
};

const ProfileAndPlanSection = ({ showYearlyPrice }) => {
  const breakpoint = useBreakpoint();
  const { getAvailablePricingPlansQuery } = usePricing();

  if (getAvailablePricingPlansQuery.isLoading || getAvailablePricingPlansQuery.isFetching) {
    return (
      <Box style={{ padding: "0 10px" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              tablet: "repeat(2, minmax(272px, 1fr))",
              laptop: "repeat(4, minmax(272px, 1fr))",
            },
            gap: "20px",
            marginY: "40px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height="480px" />
          ))}
        </Box>
      </Box>
    );
  }

  if (getAvailablePricingPlansQuery.error) {
    return (
      <Box style={{ height: "calc(100vh - 350px)", minHeight: "500px", display: "grid", placeItems: "center" }}>
        <Alert
          variant={"filled"}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          {getAvailablePricingPlansQuery.error ?? "Something went wrong"}
        </Alert>
      </Box>
    );
  }

  return (
    <Box style={{ padding: "0 10px" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            tablet: "repeat(2, minmax(272px, 1fr))",
            laptop: "repeat(4, minmax(272px, 1fr))",
          },
          gap: "20px",
          marginTop: "40px",
        }}
      >
        {getAvailablePricingPlansQuery.data?.map((plan) => {
          if (plan.isRecommended) {
            return (
              <Box
                key={plan.id || plan.plan_name}
                style={{
                  background: "linear-gradient(to right, #2196F3, #10CCD2)",
                  borderRadius: "14px",
                }}
              >
                <Typography style={{ padding: "5px 10px", color: "white" }}>
                  Recommended Plan
                </Typography>
                <PricingCard
                  plan={plan}
                  showYearlyPrice={showYearlyPrice}
                  breakpoint={breakpoint}
                />
              </Box>
            );
          }

          return (
            <PricingCard
              key={plan.id || plan.plan_name}
              plan={plan}
              showYearlyPrice={showYearlyPrice}
              breakpoint={breakpoint}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "14px",
          // width: "fit-content",
          marginX: "auto",
          display: {
            mobile: "none",
            tablet: "block",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                tablet: "repeat(5, minmax(150px, 1fr))",
                laptop: "repeat(5, minmax(200px, 1fr))",
                desktop: "repeat(5, minmax(272px, 1fr))",
              },
              placeItems: "center",
              gap: "20px",
              backgroundColor: "rgba(33, 150, 243, 0.1)",
              padding: "10px",
              borderRadius: "14px 14px 0 0",
            }}
          >
            <Typography
              fontWeight="bold"
              alignSelf="left"
              width="100%"
              paddingLeft="20px"
            >
              Plan Features
            </Typography>
            {getAvailablePricingPlansQuery.data?.map((plan) => (
              <Typography fontWeight="bold" key={plan.id || plan.plan_name}>
                {plan.plan_name}
              </Typography>
            ))}
          </Box>
          {Object.entries(CHARACTERISTICS).map(([key, value]) => {
            return (
              <Box
                key={key}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    tablet: "repeat(5, minmax(150px, 1fr))",
                    laptop: "repeat(5, minmax(200px, 1fr))",
                    desktop: "repeat(5, minmax(272px, 1fr))",
                  },
                  placeItems: "center",
                  gap: "20px",
                  padding: "10px",
                }}
              >
                <Typography
                  fontWeight="bold"
                  alignSelf="left"
                  width="100%"
                  paddingLeft="20px"
                >
                  {value}
                </Typography>
                {getAvailablePricingPlansQuery.data?.map((plan) => {
                  return (
                    <Typography fontWeight="bold" key={plan.id || plan.plan_name}>
                      {plan.features[key] === true ? (
                        <GradientCircleCheckIcon />
                      ) : (
                        plan.features[key] === false ? "-" : plan.features[key]
                      )}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box margin="20px 0">
        <Typography variant="h5" textAlign="center" color="primary">
          Get the nudge you need to take control of your cloud costs
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileAndPlanSection;
