//Package Import
import React from "react";
import styled from "@emotion/styled/macro";
import { Box, Typography, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";

//Local Imports
import history from "../../utils/history";
import { ROUTE_LANDING_PAGE } from "../../routers/routes";
import { APP_BAR_LOGO_HEIGHT } from "../../ui/AppBar";

//Assets
import CloudNudgeLogo from "../../assets/cloud_pulse_logo.svg";
import trackEvent from "../../utils/analytics";

//Styled components
const FooterTypography = styled(Typography)`
  /* font-family: Roboto; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 0.15px;
`;

//Components
const IconWrapper = ({ icon, link, type }) => {
  return (
    <Link
      href={link}
      onClick={() =>
        trackEvent("cta", "click", `Footer Icon Clicked - ${type}`)
      }
      sx={{
        width: {
          mobile: "25.253px",
          tablet: "45.278px",
          laptop: "37.5px",
          desktop: "50px",
        },
        aspectRatio: 1,
        background: "#2196F3",
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        color: "white",
        padding: "2px",
        boxShadow:
          "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
      }}
    >
      {icon}
    </Link>
  );
};

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: { mobile: "14.19px" },
        justifyContent: "space-between",
        /* marginLeft: {
          desktop: "13px",
          laptop: "13px",
          tablet: "0",
        },
        marginRight: {
          desktop: "10px",
          laptop: "0",
        }, */
        paddingTop: {
          mobile: "16px",
          tablet: "28.69px",
          laptop: "37.5px",
        },
        paddingRight: {
          laptop: "60px",
          desktop: "60px",
        },
        paddingBottom: {
          mobile: "12px",
          tablet: "18.36px",
          laptop: "32.25px",
        },
        paddingLeft: {
          laptop: "61px",
          desktop: "60px",
        },
        background: "#F9FDFF",
        boxShadow: "2px -2px 10px 0px rgba(0, 0, 0, 0.25);",
        alignItems: {
          mobile: "center",
          tablet: "center",
          laptop: "flex-end",
          desktop: "flex-end",
        },
        borderRadius: "12px 12px 0 0",
        flexDirection: {
          mobile: "column",
          tablet: "column",
          laptop: "row",
          desktop: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { mobile: "14.19px" },
          justifyContent: "space-between",
          alignItems: {
            mobile: "center",
            tablet: "center",
            laptop: "flex-end",
            desktop: "flex-end",
          },
          borderRadius: "12px 12px 0 0",
          flexDirection: {
            mobile: "column",
            tablet: "column",
            laptop: "row",
            desktop: "row",
          },
          maxWidth: {
            desktop: "1400px",
            laptop: "1200px",
            tablet: "750px",
          },
          margin: { tablet: "0 auto" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            textAlign: {
              mobile: "center",
              tablet: "center",
              laptop: "initial",
              desktop: "initial",
            },
          }}
        >
          <Box
            component="img"
            src={CloudNudgeLogo}
            alt="CloudNudge Logo"
            onClick={() => {
              history.push(ROUTE_LANDING_PAGE);
            }}
            sx={{
              cursor: "pointer",
              height: `${APP_BAR_LOGO_HEIGHT}px`,
              marginBottom: {
                mobile: "4px",
                tablet: "7.17px",
                laptop: "12px",
                desktop: "16px",
              },
            }}
          />
          <FooterTypography
            sx={{
              fontSize: {
                mobile: "12px",
                tablet: "20px",
                laptop: "15px",
                desktop: "20px",
              },
            }}
          >
            A product by{" "}
            <Link
              onClick={() => {
                trackEvent("cta", "click", `Footer Website Clicked`);
              }}
              href="https://www.whileone.in/"
              target="_blank"
            >
              Whileone Techsoft Pvt. Ltd.
            </Link>
          </FooterTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              mobile: "center",
              tablet: "center",
              laptop: "flex-end",
              desktop: "flex-end",
            },
            gap: {
              mobile: "10.46px",
              tablet: "10.46px",
              laptop: "30px",
              desktop: "30px",
            },
          }}
        >
          <Box sx={{ display: "flex", gap: "24px" }}>
            <IconWrapper
              icon={<LinkedInIcon />}
              link="https://in.linkedin.com/company/whileone-techsoft-pvt-ltd"
              type="linkedin"
            />
            <IconWrapper
              icon={<YouTubeIcon />}
              link="https://www.youtube.com/@WhileoneTech"
              type="youtube"
            />
            <IconWrapper
              icon={<LanguageIcon />}
              link="https://www.whileone.in/"
              type="website"
            />
          </Box>
          <FooterTypography
            sx={{
              textAlign: { mobile: "center" },
              fontSize: {
                mobile: "10px",
                tablet: "18px",
                laptop: "15px",
                desktop: "20px",
              },
            }}
          >
            © 2024 CloudNudge. All rights reserved.
          </FooterTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
