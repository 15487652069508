import { Typography, Box } from "@material-ui/core";
import React from "react";

const EULA = () => {
  return (
    <Box>
      <Box
        style={{
          color: "black",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          style={{
            textAlign: "center",
          }}
        >
          Last Updated: 3 Dec 2024
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          THIS IS A LEGAL AGREEMENT BETWEEN CUSTOMER ('User') AND CloudNudge
          ('company) ENTITY REFERENCED IN THE ORDER THAT GOVERNS CUSTOMER’S
          ACCESS AND USE OF the CloudNudge, cloud cost optimization platform,
          its services, and any related software or applications ('Software').
          BY ACCESSING AND/OR USING THE PRODUCTS, CUSTOMER IS AGREEING TO BE
          BOUND BY THE TERMS OF THIS AGREEMENT.
        </Typography>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            1. Product Terms
          </Typography>
          <Box my={2}>
            <Typography variant="h6" fontWeight="bold">
              1.1 Software and Cloud Services
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Unless otherwise stated in the Agreement or in the Order, grants
              Customer a limited, non-transferable, non-sublicensable,
              non-exclusive, worldwide license to install, run, access, and use
              the number of units of Software and Cloud Services during the Term
              (if applicable) solely for internal business purposes. All usage
              must comply with the Business Unit Terms, the Order, and
              Documentation.
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="h6" fontWeight="bold">
              1.2 Hardware
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Hardware is provided for use with the Software licensed hereunder
              in accordance with the Business Unit Terms and Hardware
              Documentation.
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="h6" fontWeight="bold">
              1.3 Limitations on Use
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Except to the extent permitted by applicable law, Customer shall
              not: <br /> - Make more copies of the Software than required for
              use (except for a reasonable number of copies for archival
              purposes) or use any unlicensed versions of the Products.
              <br /> - Use any Products that are not listed in an Order.
              <br /> - Allow anyone other than Authorized Users to install, run,
              access, or use the Products.
              <br /> - Sublicense, distribute, pledge, or otherwise transfer the
              Software or any rights granted under this Agreement.
              <br /> - Modify, distribute, prepare derivative works of, reverse
              engineer, reverse assemble, disassemble, decompile, or attempt to
              decipher any code relating to Products.
              <br /> - Use or access any embedded or bundled component of
              Products on a stand-alone basis, where such components are
              provided to enable Product functionality.
              <br /> - Use any third-party software in conjunction with Products
              unless it is ensured that:
              <br /> - Such use does not cause the Product to become subject to
              any third-party license, including but not limited to requiring
              the Product’s public disclosure or distribution or the licensing
              of derivative works.
              <br /> - Market, offer to sell, and/or resell Products.
              <br /> - Use Products for competitive benchmarking or analysis,
              unless permitted by applicable law.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            2. Confidentiality
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              2.1 Non-Disclosure
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Neither party shall disclose Confidential Information to any third
              party (other than an Affiliate or an Authorized Reseller) without
              prior written consent. Confidential Information includes any
              proprietary information disclosed in writing or identified as
              confidential at the time of disclosure.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Confidential Information may only be disclosed to recipients who:
              <br />- Need to know such information for the purposes outlined in
              this Agreement, and
              <br /> - Are subject to a written agreement to protect
              Confidential Information under terms as protective as this
              Agreement.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Confidential Information remains the sole property of the
              disclosing party. No additional rights are granted to the
              receiving party unless explicitly stated herein.
            </Typography>
          </Box>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              2.2 Remedies
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Each party shall indemnify the other for direct damages resulting
              from a breach of this Section. Additionally, either party may seek
              an injunction to prevent unauthorized disclosure.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            3. Security and Privacy
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              3.1 Data Security
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              For Cloud Services, Maintenance, or Consulting Services requiring
              the Company to process Personal Data, the Company shall Implement
              and maintain administrative, physical, and technical security
              controls as set forth in the Security Exhibit. The Company
              complies with applicable data protection laws, including GDPR and
              CCPA. Customers may request data deletion or portability in
              compliance with such laws.
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Use of Logo for Marketing and Promotion and grant of license:
                </Typography>
                <Typography variant="body1">
                  The User grants the Owner a non-exclusive, worldwide,
                  royalty-free license to use the User’s logo, name, and any
                  related branding materials (the "User's Logo") on the Owner’s
                  website, in advertisements, and in promotional materials
                  (collectively, "Promotional Materials") for the purpose of
                  showcasing the User’s use of the Owner’s product or service
                  and the partnership between the User and the Owner. The Owner
                  agrees to use the User's Logo in a manner consistent with the
                  User's brand guidelines and user by accepting this EULA grants
                  permission for the use.
                </Typography>
                <Typography variant="body1" style={{ marginBlock: "20px" }}>
                  The User hereby grants the Owner license to use the User’s
                  logo, name, and related branding materials (the "User's Logo")
                  solely for the purposes of demonstrating, showcasing, or
                  promoting the Owner’s software or product (the "Product") at
                  industry events, conferences, trade shows, product
                  demonstrations, webinars, and similar activities
                  (collectively, "Promotional Activities").
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Case Studies and Testimonials
                </Typography>
                <Typography variant="body1">
                  If the User has agreed to participate in case studies,
                  interviews, or provide testimonials regarding the Owner's
                  product or service (the "Testimonial"), the User grants the
                  Owner the right to use the User's Logo, name, and testimonial
                  in such case studies or marketing materials, including but not
                  limited to the Owner’s website, advertisements, promotional
                  emails, and other forms of marketing or public relations
                  efforts.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              3.2 Account Information
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              To use the Software, Users must create an account and provide
              accurate, complete information. Users are responsible for
              maintaining the confidentiality of their account credentials.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            4. Intellectual Property Rights
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Company and its Affiliates own, or have license rights to, all
              intellectual property rights in Software, Cloud Services,
              Materials, Documentation, and all derivatives thereof
              (collectively “Protected Materials”) and Company trademarks
              (“Company Marks”), which are protected by applicable patent,
              copyright, trademark and trade secret laws. Customer must
              duplicate unaltered copies of all proprietary notices incorporated
              in or affixed to any Protected Materials. Except as expressly
              stated in the Agreement, Customer receives no other rights to use
              any of Company’s Protected Materials or Company Marks. Except for
              the limited license use rights expressly granted in the Agreement,
              Customer has no right, title or interest in or to the Protected
              Materials, Products, or Company Marks or any intellectual property
              rights related thereto. In no event may Customer alter or delete
              any proprietary notices on Protected Materials. All intellectual
              property rights in and to the Software, including but not limited
              to copyrights, trademarks, and patents, are owned by CloudNudge or
              its licensors. This EULA does not transfer any ownership rights.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            5. Updates and Modifications
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              CloudNudge reserves the right to update or modify the Software and
              this EULA at any time. Users will be notified of updates via email
              or in-app notification. Continued use of the Software constitutes
              agreement to the updated terms.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            6. Financial Terms
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              6.1 Payment Terms
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              The customer is responsible for all Fees and Taxes on Orders. In
              the event a purchase is direct with Company, Customer shall pay
              Company net thirty (30) days from the date of the invoice. All
              purchases are final, with no right to a refund or set-off, except
              as expressly provided in this Agreement. Company may charge
              customers an additional 1.5% per month (or such lower amount as
              required by applicable law) for all Fees that are not paid on
              time. Company reserves the right to suspend or terminate delivery
              of any Product, or any portion thereof, for non-payment of Fees.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            7. Warranties and Disclaimer
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              CloudNudge warrants that the Software will operate in material
              compliance with the Documentation during the Term.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Except as stated, the Software is provided 'AS IS' and 'AS
              AVAILABLE.'
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            8. Limitation of Liability
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              To the maximum extent permitted by applicable law, CloudNudge’s
              total liability is capped at the fees paid by the Customer in the
              last 12 months.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              CloudNudge does not warrant that the Software will be error-free
              or uninterrupted or that it will meet your specific requirements.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              To the maximum extent permitted by applicable law, CloudNudge
              shall not be liable for any indirect, incidental, special,
              consequential, or punitive damages, including lost profits, lost
              data, or business interruptions, arising out of or related to your
              use of the Software.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            9. Indemnification
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Company shall defend Customer against any third party Infringement
              Claim. Further, Company will indemnify Customer from and against
              damages, costs, and fees reasonably incurred (including reasonable
              attorneys' fees) that are attributable exclusively to such claim
              or action and which are assessed against Customer in a final
              judgement or settlement.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              CloudNudge will defend and indemnify Customers against third-party
              claims of intellectual property infringement.
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Conditions:
              <br /> - The Customer must notify CloudNudge promptly and in
              writing.
              <br /> - CloudNudge retains control of the defense or settlement.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            10. Termination
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              This EULA is effective until terminated. CloudNudge may terminate
              your access to the Software immediately if you breach any term of
              this EULA. Upon termination, you must cease all use of the
              Software and delete any copies in your possession
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            11. Governing Law
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              This EULA is governed by the laws of India. All disputes are
              subject to the jurisdiction of the courts in Pune, India.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            12. Miscellaneous
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              If any provision is unenforceable, it will be modified to the
              minimum extent necessary, and the remainder of the EULA will
              remain in full effect.
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="h6" fontWeight="bold">
            Contact Information
          </Typography>
          <Box
            my={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              If you have any questions, <br />
              please contact: support@cloudnudge.com
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EULA;
