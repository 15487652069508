import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box, Button,
  Card,
  CardContent,
  FormControlLabel,
  LinearProgress,
  Popover,
  Switch, TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  useGridApiContext,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip as RechartsTooltip, Cell } from "recharts";

import { useDeleteBudgets, useUpdateBudgetStatus } from "../../hooks/useBudgetAlerts";
import { getLogoForType } from "../../utils/commonUtil";
import { CLOUDNUDGE } from "../../utils/constants";
import { BudgetAlertSnackBarProvider, CREATE_BUDGET_TAB, MODES } from "./BudgetAlerts";
import CustomPagination from "../../ui/CustomPaginationForDataGrid";

const SpendAndBudgetAmt = ({ totalSpendPercentage, totalBudget, totalSpend }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const graphData = [
    {
      label: "Current Spend",
      value: totalSpend,
      color: "#0A3F69",
    },
    {
      label: "Budget",
      value: totalBudget,
      color: "#2196F3",
    },
  ];

  return (
    <Box sx={{ paddingY: 1, paddingX: 2 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={totalSpendPercentage >= 100 ? 100 : totalSpendPercentage}
          sx={{
            height: "4px",
            borderRadius: "10px",
            width: "100%",
            background: "#dfdfe1",
            "& .MuiLinearProgress-bar": {
              background: totalSpendPercentage >= 100 ? "red" : `linear-gradient(90deg, #C5E5FF ${
                100 - totalSpendPercentage
              }%, #5DB0F2 100%)`,
            },
          }}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={1} justifyContent={"space-between"} lineHeight={0}
           paddingY={"10px"}>
        {totalSpend}/{totalBudget}
        <Box>
          <i className="fi fi-sr-stats" onClick={handleClick} />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>Current Spend vs Budget</Typography>
          <Box height={"220px"} padding={"10px"}>
            <ResponsiveContainer width={400} height={200}>
              <BarChart data={graphData} margin={{ top: 20, right: 30, left: 40, bottom: 20 }}>
                <XAxis dataKey="label" />
                <YAxis label={{ value: "Cost ($)", angle: -90, position: "insideLeft" }} />
                <RechartsTooltip />
                <Bar
                  dataKey="value"
                  name="Spending"
                >
                  {graphData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

const ForecastVsBudgetAmt = ({ totalForecastSpendPercentage, totalBudget, totalForecastedSpend }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const graphData = [
    {
      label: "Forecast Spend",
      value: totalForecastedSpend,
      color: "#0A3F69",
    },
    {
      label: "Budget",
      value: totalBudget,
      color: "#2196F3",
    },
  ];

  return (
    <Box sx={{ paddingY: 1, paddingX: 2 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={totalForecastSpendPercentage >= 100 ? 100 : totalForecastSpendPercentage}
          sx={{
            height: "4px",
            borderRadius: "10px",
            width: "100%",
            background: "#dfdfe1",
            "& .MuiLinearProgress-bar": {
              background: totalForecastSpendPercentage >= 100 ? "red" : `linear-gradient(90deg, #C5E5FF ${
                100 - totalForecastSpendPercentage
              }%, #5DB0F2 100%)`,
            },
          }}
        />
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={1} justifyContent={"space-between"} lineHeight={0}
           paddingY={"10px"}>
        {totalForecastedSpend}/{totalBudget}
        <i className="fi fi-sr-stats" onClick={handleClick} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>Forecasted Spend vs Budget</Typography>
          <Box height={"220px"} padding={"10px"}>
            <ResponsiveContainer width={400} height={200}>
              <BarChart data={graphData} margin={{ top: 20, right: 30, left: 40, bottom: 20 }}>
                <XAxis dataKey="label" />
                <YAxis label={{ value: "Cost ($)", angle: -90, position: "insideLeft" }} />
                <RechartsTooltip />
                <Bar
                  dataKey="value"
                  name="Spending"
                >
                  {graphData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

const AlertDetails = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reactRouterDomHistory = useHistory();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleEditAlerts = () => {
    const budgetId = value.row.budgetId;
    const keyId = value.row.key_id;
    const host = value.row.host;
    if (budgetId && keyId && host === CLOUDNUDGE) {
      return reactRouterDomHistory.push(`/budget-alerts/${CREATE_BUDGET_TAB}?mode=${MODES.EDIT}&budgetId=${budgetId}&keyId=${keyId}`);
    }
  };

  return (
    <>
      <Box onClick={handleClick} color={"#2196F3"} sx={{ cursor: "pointer" }}>View Details</Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}

      >
        <Box width={400} sx={{ p: 2, maxHeight: "300px", overflowY: "auto" }}>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <Typography variant="h5">Alerts</Typography>
            <Box padding={"10px"} borderRadius={"50%"} onClick={handleEditAlerts}
                 sx={{
                   border: "1px solid #2196F3",
                   width: "30px",
                   height: "30px",
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "center",
                   padding: "10px",
                   cursor: "pointer",
                 }}>
              <EditIcon color={"primary"} fontSize={"small"} />
            </Box>
          </Box>
          <Box>
            {value.row.alert?.map((al) => {
              return (
                <Box key={al.id}
                     sx={{ display: "flex", alignItems: "center", gap: "10px", marginY: "24px" }}>
                  <TextField value={al.threshold} label="Threshold" variant="outlined" size={"small"} />
                  <TextField value={al.frequency} label="Frequency" variant="outlined" size={"small"} />
                  <TextField value={al.trigger_on} label="Trigger On" variant="outlined" size={"small"} />
                </Box>
              );
            })}
          </Box>
          <Box>
            <Typography variant="h5">Subscribers</Typography>
            <Box sx={{ display: "flex", alignItems: "center", rowGap: "5px", columnGap: "10px", flexWrap: "wrap" }}>
              {value.row.subscribers_address?.map((sub) => <Typography key={sub.Address}>{sub.Address}</Typography>)}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const BudgetName = ({ value }) => {

  const reactRouterDomHistory = useHistory();

  const handleEditBudget = (e) => {
    e.stopPropagation();
    const budgetId = value.row.budgetId;
    const keyId = value.row.key_id;
    const host = value.row.host;
    if (budgetId && keyId && host === CLOUDNUDGE) {
      return reactRouterDomHistory.push(`/budget-alerts/${CREATE_BUDGET_TAB}?mode=${MODES.EDIT}&budgetId=${budgetId}&keyId=${keyId}`);
    }
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box
        component="img"
        src={getLogoForType(value.row.host)}
        alt={`${value.row.host} logo`}
        width="30px"
        height="20px"
      />
      <Tooltip
        title={value.value}
      >
        <Box
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            textDecoration: value.row.host === CLOUDNUDGE ? "underline" : "none",
            cursor: value.row.host === CLOUDNUDGE ? "pointer" : "default",
          }}
          onClick={handleEditBudget}
        >
          {value.value}
        </Box>
      </Tooltip>
    </Box>
  );
};

const BudgetStatus = ({ value }) => {

  const [active, setActive] = useState(value.row.is_active);

  const { updateBudgetStatusMutation } = useUpdateBudgetStatus();
  const { handleShowSnackbar } = useContext(BudgetAlertSnackBarProvider);

  const handleUpdateBudgetStatus = (e) => {
    setActive(e.target.checked);
    const budgetId = value.row.budgetId;
    const cloudName = value.row.selectedCloudProvider;

    updateBudgetStatusMutation.mutate({ budgetId, isActive: e.target.checked, cloudName }, {
      onSuccess: () => {
        handleShowSnackbar("Budget status updated successfully", "success");
      },
      onError: (error) => {
        handleShowSnackbar(error.message ?? "Something went wrong, while updating budget status, please try after some time.", "error");
      },
    });
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <FormControlLabel
        label={"Active"}
        control={
          <Switch
            color={"success"}
            checked={active}
            label="Active"
            onChange={handleUpdateBudgetStatus}
          />}
      />
    </Box>
  );
};

const columns = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "budget_name",
    headerName: "Budget Name",
    minWidth: 150,
    flex: 1,
    renderCell: (value) => {
      return (<BudgetName value={value} />);
    },
  },
  { field: "host", headerName: "Host", minWidth: 120, flex: 1, hide: true },
  {
    field: "budget_type",
    headerName: "Budget Type",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "rules_exceeded",
    headerName: "Threshold",
    minWidth: 120,
    flex: 1,
    valueGetter: (value) => `Exceed (${value})`,
  },
  {
    field: "key_label",
    headerName: "Applies To",
    minWidth: 120,
    flex: 1,
    renderCell: (value) => {
      return <Tooltip title={value.value}><Box>{value.value.substring(0, 10)}...</Box></Tooltip>;
    },
  },
  {
    field: "spend_and_budget_amt",
    headerName: "Spend & Budget Amt",
    minWidth: 200,
    flex: 1,
    renderCell: (value) => {
      const totalBudget = value.row.budget_limit;
      const totalSpend = value.row.actual_spend;

      const totalSpendPercentage = totalSpend / totalBudget * 100;

      return (
        <SpendAndBudgetAmt
          totalSpendPercentage={totalSpendPercentage}
          totalBudget={totalBudget}
          totalSpend={totalSpend}
        />
      );
    },
  },
  {
    field: "forecast_vs_budget_amt",
    headerName: "Forecast vs Budget Amt",
    sortable: false,
    minWidth: 200,
    flex: 1,
    renderCell: (value) => {
      const totalBudget = value.row.budget_limit;
      const totalForecastedSpend = value.row.forecasted_spend;

      const totalForecastSpendPercentage = totalForecastedSpend / totalBudget * 100;
      return (
        <ForecastVsBudgetAmt
          totalBudget={totalBudget}
          totalForecastedSpend={totalForecastedSpend}
          totalForecastSpendPercentage={totalForecastSpendPercentage} />
      );
    },
  },
  {
    field: "alert",
    headerName: "Alert Details",
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (value) => {
      return (
        <AlertDetails value={value} />
      );
    },
  },
  {
    field: "start_time",
    headerName: "Start Date",
    sortable: false,
    minWidth: 120,
    flex: 1,
    valueGetter: (_, row) => `${row.start_time || ""}`,
  },
  {
    field: "is_active",
    headerName: "",
    sortable: false,
    minWidth: 120,
    flex: 1,
    renderCell: (value) => {

      if (value.row.host !== CLOUDNUDGE) {
        return null;
      }

      return (
        <BudgetStatus value={value} />
      );
    },
  },
];

const CustomToolbar = ({ selectedCloudProvider }) => {
  const apiRef = useGridApiContext();
  const { deleteBudgetsMutation } = useDeleteBudgets(selectedCloudProvider);
  const { handleShowSnackbar } = useContext(BudgetAlertSnackBarProvider);
  const reactRouterDomHistory = useHistory();

  const handleDelete = () => {
    const selectedRows = apiRef.current.getSelectedRows();

    if (selectedRows.size === 0) {
      return handleShowSnackbar("Please select at least one budget to delete", "error");
    }

    const idsToDelete = [];
    selectedRows.values().forEach((row) => idsToDelete.push(row.budgetId));

    deleteBudgetsMutation.mutate(idsToDelete, {
      onSuccess: (data) => {
        apiRef.current.setRowSelectionModel([]);
        handleShowSnackbar("Selected budgets deleted successfully", "success");
      },
      onError: (error) => {
        handleShowSnackbar("Something went wrong, while deleting budgets, please try after some time.", "error");
      },
    });
  };

  const handleExportCSV = () => {
    apiRef.current.exportDataAsCsv({
      fileName: "CloudNudge-budgets.csv",
      fields: ["id", "budget_name", "host", "budget_type", "rules_exceeded", "key_label", "start_time", "is_active"],
      getRowsToExport: (params) => {
        return params.apiRef.current.getAllRowIds();
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Typography variant="h5">Budget</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          startIcon={<DeleteIcon />}
          variant={"outlined"}
          onClick={handleDelete}
          disabled={deleteBudgetsMutation.isPending}
        >
          Delete
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<UploadFileIcon />}
          onClick={handleExportCSV}
        >
          Export CSV
        </Button>
        <Button
          variant={"contained"}
          onClick={() => reactRouterDomHistory.push(`/budget-alerts/${CREATE_BUDGET_TAB}?mode=${MODES.CREATE}`)}
        >
          + CREATE
        </Button>
      </Box>
    </Box>
  );
};


const BudgetTab = ({ selectedCloudProvider, budgetTabData, budgetTabFilters, isBudgetTabDataLoading }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!budgetTabData > 0) setTableData([]);

    setTableData(budgetTabData?.map((budget, idx) => {
      return { ...budget, budgetId: budget.id, id: idx + 1, selectedCloudProvider };
    }) ?? []);
  }, [budgetTabData, selectedCloudProvider]);

  const filteredData = useMemo(() => {
    return tableData.filter((budget) => {
      return (
        (budgetTabFilters.budgetName.length === 0 || budgetTabFilters.budgetName === "all" || budget.budget_name.toLowerCase().includes(budgetTabFilters.budgetName.toLowerCase())) &&
        (budgetTabFilters.budgetType.length === 0 || budgetTabFilters.budgetType === "all" || budget.budget_type.toLowerCase().includes(budgetTabFilters.budgetType.toLowerCase())) &&
        (budgetTabFilters.host.length === 0 || budgetTabFilters.host === "all" || budget.host.toLowerCase().includes(budgetTabFilters.host.toLowerCase())) &&
        (budgetTabFilters.key.length === 0 || budgetTabFilters.key === "all" || budget.key_label.toLowerCase().includes(budgetTabFilters.key.toLowerCase()))
      );
    });
  }, [tableData, budgetTabFilters]);

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  return (
    <Card>
      <CardContent sx={{ paddingBottom: "16px !important" }}>
        <Box display={"grid"}>
          <DataGrid
            pagination
            rows={filteredData}
            columns={columns}
            pageSizeOptions={[5]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            checkboxSelection
            isRowSelectable={(params) => params.row.host === CLOUDNUDGE}
            sx={{ border: 0 }}
            loading={isBudgetTabDataLoading}
            slotProps={{
              loadingOverlay: {
                variant: "skeleton",
                noRowsVariant: "skeleton",
              },
            }}
            slots={{
              toolbar: () => <CustomToolbar selectedCloudProvider={selectedCloudProvider} />,
              pagination: CustomPagination,
            }}
            columnVisibilityModel={{
              // Hide the host column, only visible in exported csv file
              host: false,
            }}
            disableRowSelectionOnClick
          />
        </Box>
      </CardContent>
    </Card>
  );
};
export default BudgetTab;
