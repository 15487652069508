import React, { useMemo } from "react";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";

import VmsSection from "./VmSection";
import CrossCloudInsightsView from "./CrossCloudRecommendationsInsightsView";
import CrossCloudOptimizeView from "./CrossCloudRecommendationsOptimizeView";
import { H4 } from "../../../ui/H4";
import EmptyStateImg from "../../../assets/svg/edge-cases/recommendations-error-state.svg";
import { RECOMMENDATION_TYPE_OPTIMAL_V2 } from "../../../utils/constants";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && <Box style={{ height: "100%" }}>{children}</Box>}
    </div>
  );
}

const RecommendationArea = ({
                              isLoading,
                              selectedVm,
                              handleVmClick,
                              vmListToShow,
                              tabValue,
                              crossCloudRecommendationsError,
                              selectedCloudProvider,
                              selectedCrossCloudProvider,
                              selectedCrossCloudProviderName,
                              accessKeys,
                            }) => {

  if (isLoading) {
    return (
      <>
        <Box
          style={{
            gridColumn: "span 2",
            minHeight: "calc(100vh - 290px)",
            maxHeight: "calc(100vh - 290px)",
          }}
        >
          <Skeleton animation={"wave"} variant="rectangular" height="100%" />
        </Box>
        <Box
          style={{
            gridColumn: "span 5",
            minHeight: "calc(100vh - 290px)",
            maxHeight: "calc(100vh - 290px)",
          }}
        >
          <Skeleton
            animation={"wave"}
            variant="rectangular"
            height="100%"
            style={{ gridColumn: "span 5" }}
          />
        </Box>
      </>
    );
  }

  if (!accessKeys || accessKeys?.length === 0) {
    return (
      <Box
        style={{
          gridColumn: "span 7",
          height: "calc(100vh - 300px)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box component="img" src={EmptyStateImg} />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            Recommendations cannot be generated. Consider adding the key.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!vmListToShow || vmListToShow?.length === 0) {
    return (
      <Box
        style={{
          gridColumn: "span 7",
          height: "calc(100vh - 300px)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box component="img" src={EmptyStateImg} />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            No Vm found under this Key.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        style={{
          gridColumn: "span 2",
          minHeight: "calc(100vh - 290px)",
          maxHeight: "calc(100vh - 290px)",
        }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent style={{ height: "100%" }}>
            <VmsSection
              selectedVm={selectedVm}
              handleVmClick={handleVmClick}
              vmsList={tabValue === 0 ? vmListToShow.insightView : vmListToShow.optimizeView}
              vmsError={crossCloudRecommendationsError}
            />
          </CardContent>
        </Card>
      </Box>
      {vmListToShow ? (
        <Box gridColumn="span 5">
          <CustomTabPanel value={tabValue} index={0}>
            <CrossCloudInsightsView
              vmListToShow={vmListToShow.insightView}
              selectedVm={selectedVm}
              selectedCloudProvider={selectedCloudProvider}
              selectedCrossCloudProvider={selectedCrossCloudProviderName}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <CrossCloudOptimizeView
              vmListToShow={vmListToShow.optimizeView}
              selectedVm={selectedVm}
              selectedCloudProvider={selectedCloudProvider}
              selectedCrossCloudProviderName={selectedCrossCloudProviderName}
            />
          </CustomTabPanel>
        </Box>
      ) : (
        <>
          <Card
            style={{
              width: "100%",
              gridColumn: "span 5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardContent>
              <H4>Select a VM to see the recommendations</H4>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default RecommendationArea;
