import { useState } from "react";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";
import {
  QUERY_KEY_ACCOUNT_DETAILS,
  QUERY_KEY_RECOMMENDATIONS,
  QUERY_KEY_RECOMMENDATION_FILTERS,
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_OCI,
  QUERY_KEY_MONITORING_CPU,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
} from "../utils/constants";
import { fetch, store } from "../utils/httpUtil";
import { useQuery } from "@tanstack/react-query";
import { parseNetworkError, parseNetworkErrorV2 } from "../utils/commonUtil";

const getRecommendations = async (cloudProvider, startDate, endDate) => {
  const params = {
    cloud_name: cloudProvider,
  };

  try {
    const resp = await fetch(
      `/dashboard/recommendations/${startDate}/${endDate}/`,
      params
    );
    return resp;
  } catch (error) {
    const parsedError = parseNetworkError({
      error: error?.response?.data?.error,
    });
    throw parsedError;
  }
};

export default function useRecommendations(cloudProvider, startDate, endDate) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: [QUERY_KEY_RECOMMENDATIONS, cloudProvider, startDate, endDate],
    queryFn: async () => {
      return await getRecommendations(cloudProvider, startDate, endDate);
    },
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
}

export function useAccountsForToken() {
  const [isLoading, setLoading] = useState(false);
  const getAccountsForTokenAsync = async (
    selectCloudProvider,
    selectedAccessKey
  ) => {
    try {
      setLoading(true);
      const resp = await fetch(
        `/recommendations/list-${selectCloudProvider}-accounts/${selectedAccessKey}/`
      );
      setLoading(false);
      return resp;
    } catch (err) {
      console.log("error: ", err);
      setLoading(false);
    }
  };

  return {
    isLoading,
    getAccountsForTokenAsync,
  };
}

export function useRecommendationForAVm() {
  const [isLoading, setLoading] = useState(false);

  // TODO: delete this as its not being used anymore
  const getRecommendationForAVmAsync = async (selectCloudProvider, data) => {
    try {
      setLoading(true);
      const resp = await store(
        `/recommendations/get-recommendations/${selectCloudProvider}/`,
        data
      );
      setLoading(false);
      return resp;
    } catch (err) {
      console.log("error: ", err);
      setLoading(false);
    }
  };

  return {
    getRecommendationForAVmAsync,
    isLoading,
  };
}

export function useCloudProviderAccountDetails(cloudProvider) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [QUERY_KEY_ACCOUNT_DETAILS, cloudProvider],
    queryFn: async () => {
      return await fetch(`/recommendations/project-list/${cloudProvider}/`);
    },
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}

const getRecommendationFilters = async (cloudProvider, type, accessKeyId) => {
  try {
    const resp = await fetch(
      // `${url}?cloud_name=${cloudProvider}&type=${type}&key_id=${accessKeyId}`
      "/recommendations/filtered-recommendations/",
      {
        cloud_name: cloudProvider,
        type,
        key_id: accessKeyId,
      }
    );
    return resp.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error.response);
    throw parsedError;
  }
};

/**
 * Get all the tokens/accesskey added for this user.
 * @param {*} cloudProvider
 * @returns
 */
export function useVmsAndRecommendations(cloudProvider, type, accessKeyId) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEY_RECOMMENDATION_FILTERS,
      cloudProvider,
      type,
      accessKeyId,
    ],
    enabled: false,
    queryFn: async () =>
      getRecommendationFilters(cloudProvider, type, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}

const getMonitoringParams = (
  cloudProvider,
  accessToken,
  vm,
  recommendation
) => {
  if (cloudProvider === CLOUD_PROVIDER_AWS) {
    return {
      aws_key_id: accessToken,
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      machine_type: vm.machine_type,
      recommended_instance_type: recommendation.machine_type,
    };
  } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
    return {
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      memory: vm.memory?.toString(),
      vcpu: vm.vcpu,
      machine_type: vm.machine_type,
      region: vm.region,
      recommended_instance_type: recommendation.machine_type,
      recommended_instance_memory: recommendation.memoryGiB?.toString(),
      recommended_instance_vcpu: recommendation.v_cpus,
      oci_key_id: accessToken,
      compartment_id: vm.compartment_id,
    };
  } else if (cloudProvider === CLOUD_PROVIDER_AZURE) {
    return {
      azure_key_id: accessToken,
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      machine_type: vm.machine_type,
      recommended_instance_type: recommendation.machine_type,
    };
  } else if (cloudProvider === CLOUD_PROVIDER_GCP) {
    return {
      gcp_key_id: accessToken,
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      machine_type: vm.machine_type,
      recommended_instance_type: recommendation.machine_type,
    };
  }
};

const getMonitoring = async (
  cloudProvider,
  accessToken,
  vm,
  recommendation
) => {
  console.log(
    `cloudProvider ${cloudProvider}, accessToken ${accessToken},  vm ${vm}, recommendation ${recommendation}`
  );
  console.log("vm:", vm);
  console.log("recommendation:", recommendation);
  try {
    const resp = await store(
      `/recommendations/monitoring/cpu/${cloudProvider}/`,
      getMonitoringParams(cloudProvider, accessToken, vm, recommendation)
    );
    return resp.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error.response);
    throw parsedError;
  }
};

/**
 *
 * @param {*} cloudProvider
 * @returns
 */
export function useCpuMonitoring(
  cloudProvider,
  accessToken,
  vm,
  recommendation,
  open
) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEY_MONITORING_CPU,
      cloudProvider,
      accessToken,
      vm,
      recommendation,
    ],
    enabled: open,
    queryFn: async () =>
      getMonitoring(cloudProvider, accessToken, vm, recommendation),
    /* queryFn: async () =>
      new Promise((resolve, reject) => {
        resolve(monitoring);
      }), */
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}
