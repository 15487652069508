import { httpBase } from "./httpBaseUtil";

export const fetch = (endpoint, params) => {
  return httpBase().get(endpoint, { params });
};

// TODO: rename this to create
export const store = (endpoint, data, isMultipart = false) => {
  const config = isMultipart
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  return httpBase().post(endpoint, data, config);
};

export const patch = (endpoint, data) => {
  return httpBase().patch(endpoint, data);
};

export const update = (endpoint, data, isMultipart = false) => {
  const config = isMultipart
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  return httpBase().put(endpoint, data, config);
};

export const destroy = (endpoint) => {
  return httpBase().delete(endpoint);
};
