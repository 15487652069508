import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Chip } from "../../../ui/Chip";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_OCI,
  RECOMMENDATION_TAGS,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
} from "../../../utils/constants";

import ParallelArchitecture from "../../../assets/png/parallel.png";
import CrossArchitecture from "../../../assets/png/cross_cloud.svg";
import SameArchitecture from "../../../assets/png/same_architecture.png";
import OptimalRecommenationEmptyState from "../../../assets/svg/edge-cases/recommendations-error-state.svg";
import SelectWithoutRHF from "../../SelectWithoutRHF";
import DetailedInsightGraphs from "../../common/DetailedInsightGraphs";
import { getLogoForType } from "../../../utils/commonUtil";

const allOption = {
  label: "All",
  value: "all",
};

const columns = [
  {
    field: "tag",
    headerName: "Recommendation",
    minWidth: 200,
    flex: 1,
    filterable: true,
    headerClassName: "header-row",
    renderCell: (value) => {
      const architectureType = value.value;

      let logoUrl = null;
      if (architectureType === "parallel_architecture") {
        logoUrl = ParallelArchitecture;
      } else if (architectureType === "cross_architecture") {
        logoUrl = CrossArchitecture;
      } else if (architectureType === "same_architecture") {
        logoUrl = SameArchitecture;
      }

      return (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={logoUrl}
            alt="Architecture logo"
            width="15px"
            height="15px"
            style={{
              rotate: architectureType !== "cross_architecture" ? "90deg" : "",
            }}
          />
          <Typography variant="body2">
            {RECOMMENDATION_TAGS[value.value]}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "machine_type",
    headerName: "Machine Type",
    headerClassName: "header-row",
    minWidth: 140,
    flex: 1,
    filterable: true,
  },
  {
    field: "vcpu",
    headerName: "vCPUs",
    headerClassName: "header-row",
    minWidth: 100,
    flex: 1,
    filterable: true,
  },
  {
    field: "memory",
    headerName: "Memory (GiB)",
    headerClassName: "header-row",
    minWidth: 120,
    flex: 1,
    filterable: true,
  },
  {
    field: "machine_price",
    headerName: "Price per Month",
    headerClassName: "header-row",
    minWidth: 130,
    flex: 1,
    filterable: true,
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {value.row.machine_price?.str}
        </Box>
      );
    },
  },
  {
    field: "saving",
    headerName: "Saving Opportunity ($)",
    headerClassName: "header-row",
    minWidth: 250,
    flex: 1,
    filterable: true,
    sortComparator: (v1, v2) => {
      return v1.num - v2.num;
    },
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Save {value.row.saving?.str}
          <Chip
            sx={{
              backgroundColor: "transparent",
              color: "#358A01",
              border: "1px solid grey",
              borderRadius: "8px",
              padding: "0px",
              margin: "0px",
              fontWeight: 600,
              minWidth: "70px",
            }}
            label={`SAVE ${value.row.percentage_saved}%`}
          />
        </Box>
      );
    },
  },
  {
    field: "architecture",
    headerName: "Architecture",
    headerClassName: "header-row",
    minWidth: 130,
    flex: 1,
    filterable: true,
  },
];

const EmptyStateView = ({ selectedVm, areAllVmsOptimal }) => {
  if (areAllVmsOptimal) {
    return (
      <DetailedInsightGraphs selectedVm={selectedVm} />
    );
  }

  return (
    <>
      {(!selectedVm?.recommended_action &&
        selectedVm?.percentage_saved === 0) ||
      selectedVm?.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
        <DetailedInsightGraphs selectedVm={selectedVm} />
      ) : (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 4,
            textAlign: "center",
            width: "100%",
            height: "100%",
            margin: "auto",
            boxShadow: "none",
          }}
        >
          <Box
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <Box
              component="img"
              src={OptimalRecommenationEmptyState}
              style={{ width: "30%" }}
            />
            <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
              Woops! There's nothing to show right now. Try selecting an
              alternative VM.
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "#F5F5F5",
      }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const CrossCloudOptimizeView = ({
                                  selectedVm,
                                  selectedCloudProvider,
                                  vmListToShow,
                                  selectedCrossCloudProviderName,
                                }) => {
  const areAllVmsOptimal = useMemo(() => {
    if (!vmListToShow || vmListToShow.length === 0) {
      return false;
    }
    return vmListToShow.every(
      (vm) => vm.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2,
    );
  }, [vmListToShow]);
  
  const [filters, setFilters] = useState({
    cpu: null,
    memory: null,
    usageType: null,
    internalStorage: null,
    networking: null,
  });

  const {
    rows,
    maxSavingsPossibleFromRecommendation,
    missingRecommendationsByTag,
    cpuOptions,
    memoryOptions,
    usageTypeOptions,
    internalStorageOptions,
    networkingOptions,
  } = useMemo(() => {
    const recommendations =
      selectedVm?.recommendations.map((recommendation, index) => ({
        id: index + 1,
        ...recommendation,
      })) ?? [];

    const cpuOptions = [...new Set(recommendations.map((recommendation) => recommendation.vcpu ?? recommendation.ocpu) ?? [])].map((cpu) => ({
      label: cpu.toString(),
      value: cpu.toString(),
    }));

    const memoryOptionsList = [...new Set(recommendations.map((recommendation) => recommendation.memory) ?? [])];
    const memoryOptions = memoryOptionsList.map((memory) => ({
      label: memory,
      value: memory,
    }));

    const usageTypeOptionsList = [...new Set(recommendations.map((recommendation) => recommendation.usage_type) ?? [])];
    const usageTypeOptions = usageTypeOptionsList.map((usageType) => ({
      label: usageType,
      value: usageType,
    }));

    const internalStorageOptionsList = [...new Set(recommendations.map((recommendation) => recommendation.instance_storage_support?.toString()) ?? [])];
    const internalStorageOptions = internalStorageOptionsList.map((internalStorage) => ({
      label: internalStorage,
      value: internalStorage,
    }));

    const networkingOptionsList = [...new Set(recommendations.map((recommendation) => recommendation.networking_performance) ?? [])];
    const networkingOptions = networkingOptionsList.map((networking) => ({
      label: networking,
      value: networking,
    }));


    const isFilterActive = (filterValue) => filterValue !== null && filterValue !== undefined && filterValue !== "all";

    const filteredList = recommendations.filter((recommendation) => {
      return (
        (!isFilterActive(filters.cpu) || recommendation.vcpu?.toString() === filters.cpu?.toString() || recommendation.ocpu?.toString() === filters.cpu?.toString()) &&
        (!isFilterActive(filters.memory) || recommendation.memory?.toString() === filters.memory?.toString()) &&
        (!isFilterActive(filters.usageType) || recommendation.usage_type?.toString() === filters.usageType?.toString()) &&
        (!isFilterActive(filters.internalStorage) || recommendation.instance_storage_support?.toString() === filters.internalStorage?.toString()) &&
        (!isFilterActive(filters.networking) || recommendation.networking_performance?.toString() === filters.networking?.toString())
      );
    });


    const groupByTag = Object.groupBy(filteredList, ({ tag }) => tag);

    const sortByDescWrtSavingPercent = Object.entries(groupByTag).flatMap(
      ([tag, filteredList]) => {
        const descBySavingPercent = filteredList.sort((a, b) => {
          return b.percentage_saved - a.percentage_saved;
        });
        return descBySavingPercent[0];
      },
    );

    const availableRecommendationsTags = sortByDescWrtSavingPercent.map(
      (re) => re.tag,
    );

    const missingRecommendationsByTag =
      selectedVm?.recommended_action !== RECOMMENDATION_TYPE_OPTIMAL_V2
        ? Object.keys(RECOMMENDATION_TAGS).filter((tag) => {
          return !availableRecommendationsTags.includes(tag);
        })
        : [];

    const listOfAllRecommendationCostSaving = sortByDescWrtSavingPercent.map(
      (recom) => recom.saving?.num,
    );
    const maxSavingsPossibleFromRecommendation = Math.max(
      ...listOfAllRecommendationCostSaving,
    );

    return {
      rows: sortByDescWrtSavingPercent,
      maxSavingsPossibleFromRecommendation,
      missingRecommendationsByTag,
      cpuOptions: [allOption, ...cpuOptions],
      memoryOptions: [allOption, ...memoryOptions],
      usageTypeOptions: [allOption, ...usageTypeOptions],
      internalStorageOptions: [allOption, ...internalStorageOptions],
      networkingOptions: [allOption, ...networkingOptions],
    };
  }, [filters.cpu, filters.internalStorage, filters.memory, filters.networking, filters.usageType, selectedVm?.recommendations, selectedVm?.recommended_action]);

  useEffect(() => {
    if (
      cpuOptions.length &&
      memoryOptions.length &&
      usageTypeOptions.length &&
      internalStorageOptions.length &&
      networkingOptions.length
    ) {
      setFilters((prevFilters) => ({
        cpu: prevFilters.cpu ?? cpuOptions[0]?.value,
        memory: prevFilters.memory ?? memoryOptions[0]?.value,
        usageType: prevFilters.usageType ?? usageTypeOptions[0]?.value,
        internalStorage: prevFilters.internalStorage ?? internalStorageOptions[0]?.value,
        networking: prevFilters.networking ?? networkingOptions[0]?.value,
      }));
    }
  }, [cpuOptions, internalStorageOptions, memoryOptions, networkingOptions, usageTypeOptions]);

  const handleDropDownChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const getColumns = (cloudProvider) => {
    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      return columns;
    } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
      const tempCols = [
        ...columns.slice(0, 2),
        ...[
          { field: "ocpu", headerName: "oCPUs", width: 80, filterable: true, headerClassName: "header-row" },
        ],
        ...columns.slice(2, columns.length),
      ];
      return tempCols;
    } else {
      return columns;
    }
  };

  return (
    <Box gridColumn="span 5" style={{ height: "100%" }}>
      {selectedVm && selectedVm?.instance_name ? (
        <Card
          style={{
            border: "2px solid rgba(33, 150, 243, 0.3)",
            marginBottom: "20px",
          }}
        >
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Box
                    component="img"
                    src={getLogoForType(selectedCloudProvider)}
                    alt={`${selectedCloudProvider} Logo`}
                    width="30px"
                    height="30px"
                  />
                  <Tooltip title={selectedVm?.instance_name}>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      style={{
                        maxWidth: "20ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedVm?.instance_name}
                    </Typography>
                  </Tooltip>
                  <Typography variant="subtitle2">
                    {selectedVm?.machine_type}
                  </Typography>
                </Box>
                <Box style={{ display: "flex", gap: "20px" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">Memory</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.memory}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">vCPU</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.vcpu}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1">Architecture</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.architecture}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Avg. CPU Utilization
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.avg_cpu_utilization}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Max CPU Utilization
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {selectedVm?.max_cpu_utilization}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" textAlign="center">
                        Physical Processor
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title={selectedVm?.physical_processor}>
                        <Typography
                          variant="subtitle2"
                          style={{
                            maxWidth: "20ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {selectedVm?.physical_processor}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  {selectedVm?.cost_per_month?.str}
                </Typography>
                <Typography variant="caption">Current Price</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : null}
      <Card style={{ height: selectedVm ? "calc(100% - 197px)" : "100%" }}>
        <CardContent style={{ height: "100%" }}>
          {rows.length === 0 ? (
            <EmptyStateView
              selectedVm={selectedVm}
              areAllVmsOptimal={areAllVmsOptimal}
            />
          ) : (
            <>
              <Typography variant={"h5"}>Recommendations</Typography>
              <Box
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                  marginTop: "16px",
                }}
              >
                <SelectWithoutRHF
                  fullWidth
                  name={"cpu"}
                  label={selectedCrossCloudProviderName === CLOUD_PROVIDER_OCI ? "oCPUs" : "vCPUs"}
                  options={cpuOptions}
                  value={filters.cpu}
                  onChange={handleDropDownChange}
                />
                <SelectWithoutRHF
                  fullWidth
                  name={"memory"}
                  label={"Memory in GB"}
                  options={memoryOptions}
                  value={filters.memory}
                  onChange={handleDropDownChange}
                />
                <SelectWithoutRHF
                  fullWidth
                  name={"usageType"}
                  label={"Usage Type"}
                  options={usageTypeOptions}
                  value={filters.usageType}
                  onChange={handleDropDownChange}
                />
                <SelectWithoutRHF
                  fullWidth
                  name={"internalStorage"}
                  label={"Instance Storage"}
                  options={internalStorageOptions}
                  value={filters.internalStorage}
                  onChange={handleDropDownChange}
                />
                <SelectWithoutRHF
                  fullWidth
                  name={"networking"}
                  label={"Networking"}
                  options={networkingOptions}
                  value={filters.networking}
                  onChange={handleDropDownChange}
                />
                <Box textAlign="center">
                  <Typography variant="h5" fontWeight="bold" color="green">
                    ${maxSavingsPossibleFromRecommendation}/m
                  </Typography>
                  <Typography
                    variant="caption"
                    color="green"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Estimated Savings
                  </Typography>
                </Box>
                {/* <Box>
                  <UploadFileIcon style={{ color: "rgba(0, 0, 0, 0.56)" }} />
                </Box>
                <Box>
                  <SettingsIcon style={{ color: "rgba(0, 0, 0, 0.56)" }} />
                </Box> */}
              </Box>
              <Box
                sx={{
                  "& .header-row": {
                    backgroundColor: "rgba(33, 150, 243, 0.1)",
                  },
                  margin: "20px 0",
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={getColumns(selectedCrossCloudProviderName)}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "tag", sort: "desc" }],
                    },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  autoHeight
                  hideFooter
                  density="comfortable"
                />
              </Box>
              {missingRecommendationsByTag.length > 0 ? (
                <Box
                  style={{
                    border: "1px solid rgba(33, 150, 243, 0.5)",
                    padding: "10px",
                    borderRadius: "4px",
                    margin: "20px 0",
                  }}
                >
                  Please note: No{" "}
                  <b>
                    {missingRecommendationsByTag
                      .map((tag) => RECOMMENDATION_TAGS[tag])
                      .join(", ")}
                  </b>{" "}
                  Recommendation available.
                </Box>
              ) : null}
              <DetailedInsightGraphs selectedVm={selectedVm} />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CrossCloudOptimizeView;
