import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Divider,
  Typography,
  Avatar,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import SelectField from "../../Select/SelectField";
import { getInfo } from "../../../utils/jwtUtil";
import { useTeam } from "../../../hooks/useTeam";
import { AVAILABLE_ROLES } from "../../../utils/constants";

function stringAvatar(name) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const UserRow = ({ member, userAvailableRoles, setOpenSnackbar }) => {
  const { control, watch } = useForm({
    defaultValues: {
      userRole: member.role.id,
    },
  });

  const { changeUserRoleMutation, removeUserMutation } = useTeam();

  useEffect(() => {
    const subscription = watch((value) =>
      changeUserRoleMutation.mutate(
        {
          user_id: member.id,
          role_id: value.userRole,
          role: userAvailableRoles.find(
            (role) => role.value === value.userRole,
          ),
        },
        {
          onSuccess: (data) => {
            setOpenSnackbar({
              value: true,
              message: data.message || "User role updated successfully",
              severity: "success",
            });
          },
          onError: (err) => {
            setOpenSnackbar({
              value: true,
              message: err.message || "Something went wrong",
              severity: "error",
            });
          },
        },
      ),
    );
    return () => subscription.unsubscribe();
  }, [
    watch,
    changeUserRoleMutation,
    member.id,
    setOpenSnackbar,
    userAvailableRoles,
  ]);

  const authenticatedUser = getInfo(member);

  const handleRemoveUser = (userId) => {
    removeUserMutation.mutate(userId, {
      onSuccess: (data) => {
        setOpenSnackbar({
          value: true,
          message: data.message || "User removed successfully",
          severity: "success",
        });
      },
      onError: (err) => {
        setOpenSnackbar({
          value: true,
          message: err.message || "Something went wrong",
          severity: "error",
        });
      },
    });
  };

  return (
    <Box>
      <Divider sx={{ my: "10px" }} />

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Avatar size="large" {...stringAvatar(`${member.first_name + " " + member.last_name}`)} />
          <Tooltip title={`${member.first_name} ${member.last_name}`}>
            <Typography maxWidth={"20ch"} overflow={"hidden"} textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}>
              {member.first_name} {member.last_name}
            </Typography>
          </Tooltip>
        </Box>
        <Box>
          {authenticatedUser.id !== member.id &&
          authenticatedUser.role.id === 1 ? (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <form style={{ width: "fit-content" }}>
                <SelectField
                  control={control}
                  label="User Role"
                  name="userRole"
                  placeholder="Select User Role"
                  options={userAvailableRoles}
                  size="small"
                  style={{ width: "100%" }}
                  disabled={changeUserRoleMutation.isPending}
                  rules={{
                    required: "User Role is required",
                  }}
                />
              </form>
              {changeUserRoleMutation.isPending ? (
                <CircularProgress size={24} />
              ) : null}
            </Box>
          ) : (
            <Typography>{member.role.name}</Typography>
          )}
        </Box>
        <Box>
          {/* Only admin can remove any other user or admin, self remove is not allowed */}
          {authenticatedUser.id !== member.id &&
          authenticatedUser.role.name === AVAILABLE_ROLES.ADMIN ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                disabled={removeUserMutation.isPending}
                onClick={() => handleRemoveUser(member.id)}
              >
                {removeUserMutation.isPending ? (
                  <CircularProgress size={24} color={"error"} />
                ) : "Remove"}
              </Button>

            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default UserRow;
