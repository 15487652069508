import React, { useEffect } from "react";
import { Avatar, Box, Typography } from "@mui/material";

import PublicLayout from "../common/layout/PublicLayout";
import { GradeintText } from "../../pages/landingPage/LandingPage";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PublicLayout>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1ch",
          margin: "70px 0 50px",
        }}
      >
        <Typography variant="h3" fontWeight="bold">
          Our
        </Typography>
        <GradeintText variant="h3">Story</GradeintText>
      </Box>
      <Box
        style={{
          position: "relative",
          margin: { laptop: "100px 0", tablet: "50px" },
        }}
      >
        <Box
          style={{
            display: "flex",
          }}
          sx={{
            gap: {
              laptop: "20px",
              tablet: "200px",
            },
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "row",
              desktop: "row",
            },
          }}
        >
          <Box style={{ flex: 1 }}>
            <Box
              component="img"
              src="/assets/whileone_team.JPG"
              sx={{
                width: "100%",
              }}
              style={{
                borderRadius: "14px",
                overflow: "hidden",
              }}
            />
          </Box>
          <Box style={{ flex: 1, textAlign: "center" }}>
            <Typography variant="h5" style={{ marginBottom: "24px" }}>
              Through constant discussions, brainstorming, and market
              validation, we launched CloudNudge a powerful tool for cloud cost
              optimization, built on 15 years of benchmarking expertise.
            </Typography>
            <Typography variant="h5">
              It helps businesses effectively manage and reduce cloud expenses
              through precise insights and competitive spending analysis. With
              CloudNudge, companies gain clear, actionable guidance to make
              smarter decisions for efficient cloud management, elevating their
              cost strategies with every recommendation.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: { tablet: "absolute" },
            bottom: {
              tablet: "135px",
              laptop: "-200px",
            },
            left: { laptop: "20%" },
            margin: { mobile: "10px 0", laptop: "0", tablet: "0 50px 50px" },
          }}
        >
          <Box
            style={{
              background: "linear-gradient(90deg, #2196F3 0%, #0ECFCF 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                background: "rgba(249, 253, 255, 1)",
                padding: { mobile: "10px", tablet: "24px" },
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: { mobile: "10px", tablet: "24px" },
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: { mobile: "none", tablet: "block" } }}>
                  <Avatar
                    src="/assets/png/testimonial-tekdi.png"
                    sx={{
                      width: { mobile: "60px", tablet: "80px" },
                      height: { mobile: "60px", tablet: "80px" },
                    }}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      marginBottom: { mobile: "10px", tablet: "24px" },
                      display: "flex",
                      flexDirection: { mobile: "column", tablet: "row" },
                      gap: { tablet: "1ch" },
                    }}
                  >
                    <Typography variant="h4">Amol Chitale</Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: {
                          mobile: "0",
                          tablet: "11px",
                          laptop: "17px",
                        },
                      }}
                    >
                      DevOps Head @tekditechnologies
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: { mobile: "flex", tablet: "block" },
                      alignItems: { mobile: "center", tablet: "flex-start" },
                      gap: { mobile: "10px", tablet: "0" },
                    }}
                  >
                    <Box sx={{ display: { mobile: "block", tablet: "none" } }}>
                      <Avatar
                        src="/assets/png/testimonial-tekdi.png"
                        sx={{
                          width: { mobile: "60px", tablet: "80px" },
                          height: { mobile: "60px", tablet: "80px" },
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{ marginBottom: { tablet: "24px" } }}
                    >
                      Bespoke solutions and optimisation suggestions provided by
                      CloudNudge team help us to optimise our cloud costs by
                      ~25%. It does so without any adverse impact to the end
                      customers. CloudNudge has helped in auditing and
                      monitoring cloud services which has resulted in
                      significant savings of various cloud resources.
                      Infrastructure optimisation recommendations are based on
                      exhaustive Policy and Service lists which are continuously
                      evolving giving value proposition to clients.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ margin: { laptop: "250px 0 100px", mobile: "50px 0" } }}>
        <Box
          style={{
            display: "flex",
            gap: "1ch",
            justifyContent: "center",
            margin: "40px 0",
          }}
        >
          <Typography variant="h3" fontWeight="bold">
            Our
          </Typography>
          <GradeintText variant="h3">Values</GradeintText>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: "40px",
          }}
          sx={{
            flexDirection: { laptop: "row", mobile: "column" },
          }}
        >
          <Box
            style={{
              background: "linear-gradient(90deg, #2196F3 0%, #0ECFCF 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              style={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                borderRadius: "10px",
                height: "calc(100% - 48px)",
              }}
            >
              <Typography variant="h4" style={{ marginBottom: "24px" }}>
                Visionary Innovation
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "24px" }}>
                We’re all about pushing boundaries and crafting game-changing
                solutions. Our goal? To empower businesses to streamline
                operations and uncover savings they didn’t know existed.
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              background: "linear-gradient(90deg, #2196F3 0%, #0ECFCF 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              style={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                borderRadius: "10px",
                height: "calc(100% - 48px)",
              }}
            >
              <Typography variant="h4" style={{ marginBottom: "24px" }}>
                Customer-Centric Focus
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "24px" }}>
                Our users are the driving force behind everything we do. We
                listen, learn, and adapt, ensuring our solutions are tailored to
                your needs and help you achieve your cloud goals.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{ display: "flex", gap: "40px", marginTop: "40px" }}
          sx={{
            flexDirection: { laptop: "row", mobile: "column" },
          }}
        >
          <Box
            style={{
              background: "linear-gradient(90deg, #2196F3 0%, #0ECFCF 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              style={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                borderRadius: "10px",
                height: "calc(100% - 48px)",
              }}
            >
              <Typography variant="h4" style={{ marginBottom: "24px" }}>
                Collaborative Spirit
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "24px" }}>
                We believe that teamwork is the secret sauce to success. By
                blending diverse perspectives and talents, we tackle challenges
                head-on and turn great ideas into reality.
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              background: "linear-gradient(90deg, #2196F3 0%, #0ECFCF 100%)",
              padding: "2px",
              borderRadius: "12px",
            }}
          >
            <Box
              style={{
                background: "rgba(249, 253, 255, 1)",
                padding: "24px",
                borderRadius: "10px",
                height: "calc(100% - 48px)",
              }}
            >
              <Typography variant="h4" style={{ marginBottom: "24px" }}>
                Radical Openness
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "24px" }}>
                In our world, transparency isn’t just a buzzword; it’s our way
                of life. We cultivate an environment where every voice matters
                and ideas flow freely. As our co-founder puts it, “When we share
                openly, we innovate boldly.”
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </PublicLayout>
  );
};

export default AboutUs;
