import React, { useState } from "react";
import { Box, Card, CardContent, Fab, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GradientButton, SimpleButton } from "./LandingPage";
import { ROUTE_SIGNUP } from "../../routers/routes";
import history from "../../utils/history";

import Step1 from "../../assets/png/step_1_signup_section.png";
import Step2 from "../../assets/png/step_2_signup_section.png";
import Step3 from "../../assets/png/step_3_signup_section.png";
import { INFO_CLOUDNUDGE_EMAIL } from "../../utils/constants";
import trackEvent from "../../utils/analytics";

const ButtonStyle = {
  fontSize: {
    mobile: "12px",
    tablet: "12px",
    laptop: "14px",
    desktop: "14px",
  },
  width: {
    mobile: "140px", // this had to be overriden than design, coz as per design was text overflowing
    tablet: "146px",
    laptop: "172px",
    desktop: "172px",
  },
  height: {
    mobile: "32px",
    tablet: "34px",
    laptop: "40px",
    desktop: "40px",
  },
};

const Separator = ({ isLastElement }) => {
  return (
    <ul
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        gap: "10px",
      }}
    >
      {Array.from({ length: 5 }, () => {
        return (
          <li
            style={{
              listStyleType: "none",
              width: "2px",
              height: "12px",
              background:
                "linear-gradient(to bottom, #9ED2FA 0%, #10CCD2 100%)",
            }}
          />
        );
      })}
      {isLastElement ? (
        <li
          style={{
            listStyleType: "none",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: "linear-gradient(to bottom, #9ED2FA 0%, #10CCD2 100%)",
          }}
        />
      ) : null}
    </ul>
  );
};

const SignUpStepsSection = () => {
  const steps = [Step1, Step2, Step3];
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: {
          mobile: "52px",
          tablet: "100px",
          laptop: "72px",
          desktop: "102px",
        },
        zIndex: "1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "column", laptop: "row" },
          justifyContent: "space-between",
          gap: "20px",
          width: "100%",
          maxWidth: {
            desktop: "1400px",
            laptop: "1200px",
            tablet: "750px",
          },
          margin: { tablet: "0 auto" },
          paddingInline: { mobile: "10px", tablet: "0px" },
        }}
      >
        <Box style={{ flex: 1 }}>
          <Box
            style={{
              marginBottom: "40px",
            }}
          >
            <Typography variant="h3" fontWeight="bold" textAlign="center">
              Sign Up For{" "}
              <span
                style={{
                  background:
                    "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Free
              </span>{" "}
              <br />
              with{" "}
              <span
                style={{
                  background:
                    "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                3 easy steps
              </span>
            </Typography>
          </Box>

          <Card>
            <CardContent sx={{ padding: { mobile: "0px", tablet: "20px" } }}>
              <Box style={{ position: "relative" }}>
                <Box
                  style={{
                    position: "absolute",
                    backgroundColor: "gray",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    color: "white",
                    top: "50%",
                    right: "0",
                    display: `${
                      currentStep < steps.length - 1 ? "grid" : "none"
                    }`,
                    cursor: "pointer",
                    placeItems: "center",
                  }}
                  onClick={handleNext}
                >
                  <ArrowForwardIosIcon />
                </Box>
                <Box
                  sx={{
                    maxWidth: "820px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent:
                      currentStep === 0
                        ? "flex-start"
                        : currentStep === steps.length - 1
                        ? "flex-end"
                        : "center",
                  }}
                >
                  {steps.map((step, index) => {
                    return (
                      <Box
                        key={index}
                        component="img"
                        src={step}
                        alt={`step ${index + 1}`}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    );
                  })}
                </Box>
                <Box
                  onClick={handlePrev}
                  style={{
                    position: "absolute",
                    backgroundColor: "gray",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: `${currentStep > 0 ? "grid" : "none"}`,
                    placeItems: "center",
                    color: "white",
                    top: "50%",
                    left: "0",
                    cursor: "pointer",
                    transform: "rotate(180deg)",
                  }}
                >
                  <ArrowForwardIosIcon />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box style={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Fab color="primary" style={{ flexShrink: 0 }}>
                  1
                </Fab>
                <Separator />
              </Box>
              <Box>
                <Typography variant="h4" fontWeight="bold" marginBottom="12px">
                  Connect Your Cloud
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Effortlessly link your cloud account in seconds! Experience
                  seamless integration and take control of your cloud spending.
                  Interestingly, we only get read access to your account. We do
                  not write or modify any of your configuration, data or files.
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Fab color="primary" style={{ flexShrink: 0 }}>
                  2
                </Fab>
                <Separator />
              </Box>
              <Box mt={2}>
                <Typography variant="h4" fontWeight="bold" marginBottom="12px">
                  Customize Your Cost Preferences
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Set your budget goals and unlock tailored savings strategies.
                  Optimize your cloud cost spending to maximize your ROI
                  effortlessly with recommendations like downsizing or
                  terminating a virtual machine. Tweak your cost saving
                  preferences with our 'Aggresssive', 'Defensive' & 'Balanced'
                  Modes.
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Fab color="primary" style={{ flexShrink: 0 }}>
                  3
                </Fab>
                <Separator isLastElement={true} />
              </Box>
              <Box mt={2}>
                <Typography variant="h4" fontWeight="bold" marginBottom="12px">
                  Access Real-Time Insights
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Dive into your personalized dashboard for instant insights!
                  Manage your cloud spend wisely and unleash hidden savings with
                  ease. Configure email alerts and get notified for anomalous
                  system behavior.
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "60px",
                  }}
                >
                  <SimpleButton
                    sx={{
                      ...ButtonStyle,
                      display: { mobile: "none", laptop: "block" },
                    }}
                    onClick={() => {
                      trackEvent(
                        "cta",
                        "click",
                        "Sign Up Section - Sign Up Button"
                      );
                      history.push(ROUTE_SIGNUP);
                    }}
                  >
                    Sign Up For Free
                  </SimpleButton>
                  <GradientButton
                    sx={ButtonStyle}
                    onClick={() => {
                      window.open(`mailto:${INFO_CLOUDNUDGE_EMAIL}`);
                    }}
                  >
                    talk to us
                  </GradientButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpStepsSection;
