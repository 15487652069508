import React from "react";
import { Alert, Skeleton } from "@mui/material";

import useProfile from "../../hooks/useProfile";
import ProfileAndPlanForAdmin from "./ProfileAndPlanForAdmin";
import ProfileAndPlanForMember from "./ProfileAndPlanForMember";
import ProfileAndPlanForUnpaidUser from "./ProfileAndPlanForUnpaidUser";
import { AVAILABLE_ROLES } from "../../utils/constants";

const ProfilePage = () => {
  const { getAuthenticatedUserQuery } = useProfile();

  if (
    getAuthenticatedUserQuery.isLoading ||
    getAuthenticatedUserQuery.isRefetching
  ) {
    return <Skeleton height={200} width="100%" />;
  }

  if (!getAuthenticatedUserQuery.data || getAuthenticatedUserQuery.error) {
    return (
      <>
        <Alert severity="error">
          {getAuthenticatedUserQuery.error?.message}
        </Alert>
      </>
    );
  }

  if (getAuthenticatedUserQuery.data.role?.name === AVAILABLE_ROLES.ADMIN || getAuthenticatedUserQuery.data.role?.name === AVAILABLE_ROLES.SUPER_ADMIN) {
    return (
      <ProfileAndPlanForAdmin
        getAuthenticatedUserQuery={getAuthenticatedUserQuery}
      />
    );
  }

  if (getAuthenticatedUserQuery.data.role?.name === AVAILABLE_ROLES.MEMBER) {
    return (
      <ProfileAndPlanForMember
        getAuthenticatedUserQuery={getAuthenticatedUserQuery}
      />
    );
  }
  
  return <ProfileAndPlanForUnpaidUser />;
};

export default ProfilePage;
