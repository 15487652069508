import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@mui/material";

// Import custom components
import { DataFetchProvider, useDataFetch } from "../providers/DataFetchedProvider";
import MiniDrawer from "../drawer/MiniDrawer";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "auto",
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    padding: 24,
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("tablet")]: {
      height: "calc(100% - 64px)",
      marginTop: 64,
    },
  },
});


const MainLayoutChildren = (props) => {
  const { classes, children } = props;
  const { isDataFetched } = useDataFetch();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <MiniDrawer />
        {isDataFetched || location.pathname === "/add-cloud-provider" || location.pathname === "/profile" ? (
          <main className={classes.content}>{children}</main>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "calc(100vh - 56px)",
              width: "100%",
            }}
          >
            <Box
              component="img"
              src={"/assets/gif/CloudNudge-loading-gif.gif"}
              alt="Loading"
              width={114}
              height={114}
            />
            <Typography variant="h4" textAlign="center">
              Just a nudge away!
              <br />
              We're fetching your data...
            </Typography>
          </Box>
        )}
      </div>
    </div>
  );
};

const MainLayout = (props) => {
  return (
    <DataFetchProvider>
      <MainLayoutChildren {...props} />
    </DataFetchProvider>
  );
};

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles)(MainLayout);
