import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@mui/material";
import React from "react";

const CustomLabel = ({ viewBox, value }) => {
  const { y } = viewBox; // Get Y position

  return (
    <foreignObject x={10} y={y - 10} width={50} height={30}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #10CCD2",
          borderRadius: "4px",
          padding: "0 5px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "#10CCD2",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </foreignObject>
  );
};

export default function CostTrendGraph({ data, thresholds }) {
  return (
    <Box
      width="100%"
      height="500px"
      sx={{
        padding: "10px",
        borderRadius: "4px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <XAxis dataKey="label" label={{ value: "Duration", position: "bottom" }} />
          <YAxis label={{ value: "Cost ($)", angle: -90, position: "left" }} />
          <Tooltip />

          {/* Bars */}
          <Bar dataKey="current_value" fill="#2196F3" stackId="a" />
          <Bar dataKey="forecasted_value" fill="#87C9FF" stackId="a" />

          {/* Reference Lines with Custom Labels */}
          {thresholds?.map((threshold, index) => {
            return (
              <ReferenceLine
                key={index}
                y={parseInt(threshold.threshold)}
                stroke="#10CCD2"
                strokeDasharray="5 5"
                label={<CustomLabel value={threshold.threshold} />}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
