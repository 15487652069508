import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

import { CREATE_BUDGET_TAB, MODES } from "./BudgetAlerts";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const BudgetAlertsTabs = (props) => {
  const { selectedTab, handleTabChange, TABS, activeMode } = props;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab.id} onChange={handleTabChange} aria-label="basic tabs example">
          {TABS.map((tab) => {
            const tabLabel = activeMode === MODES.EDIT && selectedTab.value === CREATE_BUDGET_TAB && tab.value === CREATE_BUDGET_TAB ? "Edit Budget" : tab.label;
            return (
              <Tab key={tab.id} label={tabLabel} {...a11yProps(tab.id)} />
            );
          })}
        </Tabs>
      </Box>
      {TABS.map((tab) => (
        <CustomTabPanel value={selectedTab.id} index={tab.id} key={tab.id}>
          <tab.Component {...props} />
        </CustomTabPanel>
      ))}
    </>
  );
};
export default BudgetAlertsTabs;
