import axios from "axios";
import { push } from "connected-react-router";
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupFailure,
  loginInProgress,
  signupInProgress,
} from "../actions/authAction";
import { API_URL } from "../config/config";
import {
  setLocalStorage,
  clearLocalStorage,
  setCookie,
  deleteCookie,
} from "../utils/storageUtil";
import {
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_VERIFY_EMAIL,
  ROUTE_ADD_NEW_CLOUD_PROVIDER,
} from "../routers/routes";
import { parseNetworkError } from "../utils/commonUtil";
import {
  STORAGE_AUTH_TOKEN,
  STORAGE_USER_INFO,
  STORAGE_HAS_SHOWN_GUIDED_TOUR,
} from "../utils/constants";

export const login = ({
  email,
  password,
  organization_id,
  recaptcha_token,
}) => {
  return (dispatch) => {
    dispatch(loginInProgress());
    axios
      .post(API_URL + "auth/login/", {
        email,
        password,
        organization_id,
        recaptcha_token,
      })
      .then((response) => {
        dispatch(loginSuccess(response.data.access));
        setCookie(STORAGE_AUTH_TOKEN, response.data.access, 1);
        setCookie(STORAGE_USER_INFO, JSON.stringify(response.data), 1);
        setLocalStorage(
          STORAGE_HAS_SHOWN_GUIDED_TOUR,
          response.data.guided_tour
        );
        /*  
        has_token is a boolean value for that user has already added one or more tokes.
        which means there are some data to show on dashabord.
        If true, then we are redirected to dashboard 
        */
        if (response.data.has_token) {
          dispatch(push(ROUTE_DASHBOARD));
        } else {
          dispatch(push(ROUTE_ADD_NEW_CLOUD_PROVIDER));
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(
          loginFailure({
            message: `${parseNetworkError({
              error: error?.response?.data?.error ?? error.message.toString(),
            })}`,
          })
        );
      });
  };
};

export const signup = ({
  first_name,
  last_name,
  email,
  password,
  organization_name,
  role_id = 1,
  terms_and_conditions,
  recaptcha_token,
}) => {
  return (dispatch) => {
    dispatch(signupInProgress());
    axios
      .post(API_URL + "auth/register/", {
        email,
        first_name,
        last_name,
        password,
        organization_name,
        role_id,
        terms_and_conditions,
        recaptcha_token,
      })
      .then((response) => {
        dispatch(push(ROUTE_VERIFY_EMAIL));
      })
      .catch((error) => {
        dispatch(
          signupFailure({
            message: `${parseNetworkError({
              error: error?.response?.data?.error ?? error.message.toString(),
            })}`,
          })
        );
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    deleteCookie(STORAGE_AUTH_TOKEN);
    deleteCookie(STORAGE_USER_INFO);
    clearLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR);
    dispatch(logoutSuccess());
    dispatch(push(ROUTE_LOGIN));
    return false;
  };
};

export const handleLogoutV2 = () => {
  deleteCookie(STORAGE_AUTH_TOKEN);
  deleteCookie(STORAGE_USER_INFO);
  clearLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR);
  window.location.href = "/login";
  return false;
};
