import React from "react";
import { Typography, Box, Divider } from "@mui/material";

import { Card } from "../../../ui/Card";
import {
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
  RECOMMENDATION_TYPE_IDLE_V2,
  AVILABLE_SERVICES,
  RECOMMENDATION_DATA_KEYS,
} from "../../../utils/constants";

const getBorderV2 = (action) => {
  if (action === RECOMMENDATION_TYPE_OPTIMAL_V2) {
    return "1px solid green";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2) {
    return "1px solid yellow";
  } else if (action === RECOMMENDATION_TYPE_IDLE_V2) {
    return "1px solid red";
  } else {
    return "1px solid yellow";
  }
};

const VmCardV2 = ({ vm, onClick, selectedVm, selectedCloudService }) => {
  return (
    <Card
      sx={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "10px",
        borderRadius: "8px",
        margin: "20px 2px",
        cursor: "pointer",
        gap: 2,
        display: "grid",
        gridTemplateColumns: "minmax(10ch, 1fr) 1fr",
        columnGap: "16px",
        rowGap: "10px",
        alignItems: "start",
        overflow: "hidden",
        wordWrap: "break-word",
        border:
          vm?.instance_id === selectedVm?.instance_id
            ? "2px solid #2196F3"
            : "none",
      }}
      key={vm.instance_id}
      onClick={() => onClick(vm)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="body" fontWeight="600">
            {vm[RECOMMENDATION_DATA_KEYS.instanceName[selectedCloudService]]}
          </Typography>
          <Divider />
          <Typography variant="body" fontWeight="600">
            {vm[RECOMMENDATION_DATA_KEYS.machineType[selectedCloudService]]}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body">Price</Typography>
          <Typography variant="body2">
            {selectedCloudService === AVILABLE_SERVICES.INSTANCES
              ? vm.cost_per_month?.str
              : vm.total_price?.str ||
                `$${
                  vm.current_instance_price?.num +
                  vm.current_iops_price?.num +
                  vm.current_storage_price?.num
                }/m`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
          height: "100%",
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              overflow: "hidden",
              whiteSpace: "pre-wrap", // Ensure long content wraps correctly
              wordBreak: "break-word", // Break words if they are too long
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: getBorderV2(
                  vm[
                    RECOMMENDATION_DATA_KEYS.recommendedAction[
                      selectedCloudService
                    ]
                  ]
                ),
                borderRadius: "8px",
                padding: "4px",
                width: "80%",
              }}
            >
              <Typography
                variant="text"
                fontWeight="600"
                textAlign="center"
                color={
                  vm[
                    RECOMMENDATION_DATA_KEYS.recommendedAction[
                      selectedCloudService
                    ]
                  ] === RECOMMENDATION_TYPE_OPTIMAL_V2
                    ? "#358A01"
                    : "black"
                }
              >
                {
                  vm[
                    RECOMMENDATION_DATA_KEYS.recommendedAction[
                      selectedCloudService
                    ]
                  ]
                }
              </Typography>
              {vm[
                RECOMMENDATION_DATA_KEYS.recommendedAction[selectedCloudService]
              ] !== RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
                <Typography
                  color="green"
                  variant="body"
                  fontWeight="600"
                  textAlign="center"
                >
                  Save -{" "}
                  {
                    vm[
                      RECOMMENDATION_DATA_KEYS.savingPercentage[
                        selectedCloudService
                      ]
                    ]
                  }
                  %
                </Typography>
              ) : null}
            </Box>
          </Box>
        </>
      </Box>
    </Card>
  );
};

export default VmCardV2;
