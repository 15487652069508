import { fetch } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "../utils/queryKeys";
import { CACHED_DATA_CACHE_TIME, CACHED_DATA_STALE_TIME } from "../config/config";

const getAvailablePricingPlans = async () => {
  try{
    const response = await fetch("/payments/list-subscription-plans/");
    return response?.data?.data;
  }
  catch (e) {
    const error = parseNetworkErrorV2(e.response);
    throw new Error(error);
  }
};


export default function usePricing ()  {
  const getAvailablePricingPlansQuery = useQuery({
    queryKey: QUERY_KEY.AVAILABLE_PRICING_PLANS,
    queryFn: getAvailablePricingPlans,
    refetchOnWindowFocus: false,
    cacheTime: CACHED_DATA_CACHE_TIME,
    staleTime: CACHED_DATA_STALE_TIME
  });

  return {
    getAvailablePricingPlansQuery
  }
};
