import { useQuery } from "@tanstack/react-query";

import { fetch } from "../utils/httpUtil";
import {
  CACHED_DATA_STALE_TIME,
  CACHED_DATA_CACHE_TIME,
} from "../config/config";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
} from "../utils/constants";
import { QUERY_KEY } from "../utils/queryKeys";

const getTotalCosts = async (startDate, endDate) => {
  try {
    const resp = await fetch(`/dashboard/total-cost/${startDate}/${endDate}/`);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export function useTotalCostsV2(startDate, endDate) {
  const getTotalCostsQuery = useQuery({
    queryKey: [QUERY_KEY.TOTAL_COST, startDate, endDate],
    queryFn: async () => {
      return await getTotalCosts(startDate, endDate);
    },
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    refetchOnWindowFocus: false,
  });

  return {
    getTotalCostsQuery,
  };
}

const getCloudCostTrendForAll = async (startDate, endDate, duration) => {
  try {
    const resp = await fetch(
      `/dashboard/cloud-cost-trend/${startDate}/${endDate}/${duration}/`
    );
    return resp?.data?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2({
      error: error?.response?.data?.error,
    });
    throw parsedError;
  }
};

export function useCloudCostTrendForAllV2(startDate, endDate, duration) {
  const getCloudCostTrendQuery = useQuery({
    queryKey: [
      QUERY_KEY.CLOUD_COST_TREND_FOR_ALL,
      startDate,
      endDate,
      duration,
    ],
    queryFn: async () => {
      return await getCloudCostTrendForAll(startDate, endDate, duration);
    },
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    refetchOnWindowFocus: false,
  });

  return {
    getCloudCostTrendQuery,
  };
}

const getCloudCostByRegionV2 = async (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  try {
    const resp = await fetch(
      `/dashboard/cloud-cost/${cloudProvider}/region/${startDate}/${endDate}/?key_id=${accessKeyId}`
    );
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export function useCostByRegionV2(
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) {
  const getCloudCostByRegionQuery = useQuery({
    queryKey: [
      QUERY_KEY.COST_BY_REGION_V2,
      startDate,
      endDate,
      cloudProvider,
      accessKeyId,
    ],
    queryFn: () =>
      getCloudCostByRegionV2(cloudProvider, startDate, endDate, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!accessKeyId,
  });

  return {
    getCloudCostByRegionQuery,
  };
}

const getCloudCostByServiceV2 = async (
  cloud,
  startDate,
  endDate,
  accessKeyId
) => {
  try {
    const resp = await fetch(
      `/dashboard/cloud-cost/${cloud}/service/${startDate}/${endDate}/?key_id=${accessKeyId}`
    );
    return resp?.data?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useCostByServiceV2 = (cloud, startDate, endDate, accessKeyId) => {
  const getCloudCostByServiceQuery = useQuery({
    queryKey: [
      QUERY_KEY.COST_BY_SERVICE_V2,
      cloud,
      startDate,
      endDate,
      accessKeyId,
    ],
    queryFn: () =>
      getCloudCostByServiceV2(cloud, startDate, endDate, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!accessKeyId,
  });

  return { getCloudCostByServiceQuery };
};

const getCostByAccountV2 = async (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  let url = "";

  if (cloudProvider === CLOUD_PROVIDER_AWS) {
    url = `/dashboard/cloud-cost/aws/account/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_AZURE) {
    url = `/dashboard/cloud-cost/azure/resource-group/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_GCP) {
    url = `/dashboard/cloud-cost/gcp/project/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_OCI) {
    url = `/dashboard/cloud-cost/oci/compartment/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  try {
    if (url === "") {
      throw Error("Invalid cloud provider");
    }
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useCostByAccountV2 = (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  const getCostByAccountQuery = useQuery({
    queryKey: [
      QUERY_KEY.COST_BY_ACCOUNT_V2,
      cloudProvider,
      startDate,
      endDate,
      accessKeyId,
    ],
    queryFn: () =>
      getCostByAccountV2(cloudProvider, startDate, endDate, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!accessKeyId,
  });

  return getCostByAccountQuery;
};
