import { createContext, useContext, useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../../utils/storageUtil";
import { useData } from "../../../hooks/useData";
import { useQueryClient } from "@tanstack/react-query";

const DataFetchContext = createContext();

export const DataFetchProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [isDataFetched, setIsDataFetched] = useState(() => getLocalStorage("isDataFetched") || false);
  const { checkIfDataFetchedQuery } = useData(isDataFetched);

  useEffect(() => {
    if (checkIfDataFetchedQuery.data) {
      // Invalidate all queries
      queryClient.invalidateQueries();
      setLocalStorage("isDataFetched", true);
      setIsDataFetched(true);
    }
  }, [checkIfDataFetchedQuery.data, queryClient]);

  const updateDataFetched = (value) => {
    setLocalStorage("isDataFetched", value);
    setIsDataFetched(value);
  };


  return (
    <DataFetchContext.Provider value={{ isDataFetched, updateDataFetched }}>
      {children}
    </DataFetchContext.Provider>
  );
};

export const useDataFetch = () => useContext(DataFetchContext);
