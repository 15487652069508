import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { Alert, Box, Skeleton } from "@mui/material";

import Recommendations from "./Recommendations";
import AccountSpendEfficiencyV2 from "./components/AccountSpendEfficiencyV2";

import { durationToBackendString } from "../../utils/commonUtil";
import { useCloudCostTrendForAllV2 } from "../../hooks/useCostsV2";
import LeftHalfCloud from "../../assets/half_cloud_left.svg";

const GRAPH_ROOT_CONTAINER_HEIGHT = 400;
const GRAPH_HEIGHT = 230;

const GraphContainerCardStyles = {
  minHeight: GRAPH_ROOT_CONTAINER_HEIGHT,
  padding: "16px",
};

const CardStyles = {
  ...GraphContainerCardStyles,
  width: "100%",
  height: "100%",
  padding: "16px",
  backgroundColor: "white",
};

const seriesColors = {
  all: "#000000",
  aws: "#FF9F0E",
  azure: "#0089D6",
  gcp: "#30A952",
  oci: "#EE1E25",
};

const seriesNames = {
  all: "All",
  aws: "AWS",
  azure: "Azure",
  gcp: "GCP",
  oci: "ORACLE",
};

const CustomizedLegend = ({ activeSeries, setActiveSeries }) => {
  const handleLegendClick = (series) => {
    if (series === "all") {
      setActiveSeries(null);
    } else {
      setActiveSeries((prev) => (prev === series ? null : series));
    }
  };

  return (
    <ul
      style={{
        listStyle: "none",
        padding: 0,
        display: "flex",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      {Object.entries(seriesNames).map(([key, value]) => {
        return (
          <li
            key={key}
            style={{
              cursor: "pointer",
              color: seriesColors[key],
              textDecoration:
                activeSeries === key || (activeSeries === null && key === "all")
                  ? "underline"
                  : "none",
              textDecorationThickness: "2px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleLegendClick(key)}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                marginRight: "5px",
                backgroundColor: seriesColors[key],
              }}
            ></span>
            <span>{value}</span>
          </li>
        );
      })}
    </ul>
  );
};

function groupBy(array, keyFn) {
  return array.reduce((acc, item) => {
    const key = keyFn(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

const CloudCostTrends = (props) => {
  const { duration, startDate, endDate } = props;
  const { getCloudCostTrendQuery } = useCloudCostTrendForAllV2(
    startDate,
    endDate,
    durationToBackendString(duration)
  );

  const graphData = useMemo(() => {
    const dateWiseData = groupBy(
      getCloudCostTrendQuery?.data ?? [],
      (obj) => obj.label
    );
    return Object.keys(dateWiseData).map((date) => ({
      month: date,
      aws: dateWiseData[date].find((item) => item.cloud === "aws")?.value,
      azure: dateWiseData[date].find((item) => item.cloud === "azure")?.value,
      gcp: dateWiseData[date].find((item) => item.cloud === "gcp")?.value,
      oci: dateWiseData[date].find((item) => item.cloud === "oci")?.value,
    }));
  }, [getCloudCostTrendQuery?.data]);

  const allSeries = Object.keys(seriesColors);
  const [activeSeries, setActiveSeries] = useState(null);

  return (
    <Card style={CardStyles}>
      {startDate && endDate && (
        <CardHeader
          sx={{
            display: "flex",
            overflow: "hidden",
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
          }}
          title={`Cloud Cost Efficiency`}
        />
      )}
      <CardContent
        style={{
          background: "white",
        }}
      >
        {getCloudCostTrendQuery?.isLoading ||
        getCloudCostTrendQuery?.isFetching ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={GRAPH_HEIGHT + 50}
          />
        ) : getCloudCostTrendQuery?.isError ? (
          <Alert severity="warning" sx={{ my: 1 }}>
            {getCloudCostTrendQuery?.error}
          </Alert>
        ) : (
          <Box sx={{ width: "100%", height: GRAPH_HEIGHT + 50 }}>
            <ResponsiveContainer>
              <BarChart
                data={graphData}
                height={GRAPH_HEIGHT}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend
                  content={
                    <CustomizedLegend
                      activeSeries={activeSeries}
                      setActiveSeries={setActiveSeries}
                    />
                  }
                />
                <ReferenceLine y={250} stroke="#8884d8" strokeDasharray="3 3" />
                {allSeries.map((series) => (
                  <Bar
                    key={series}
                    dataKey={
                      activeSeries === null || activeSeries === series
                        ? series
                        : () => 0
                    }
                    stackId="a"
                    fill={seriesColors[series]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const CostDetailsForAllV2 = (props) => {
  const { startDate, endDate } = props;
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
        width="100%"
      >
        <Box gridColumn="span 2">
          <CloudCostTrends {...props} />
        </Box>
        <Box>
          <AccountSpendEfficiencyV2 />
        </Box>
        <Box gridColumn="span 2">
          <Recommendations startDate={startDate} endDate={endDate} />
        </Box>
        <Card
          style={{
            backgroundImage: `url(${LeftHalfCloud})`,
            backgroundPosition: "right bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 100px",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5">
            "Optimizing costs isn't about spending less; it's about spending
            smarter to unlock greater value and sustainable success."
          </Typography>
        </Card>
      </Box>
    </>
  );
};

export default CostDetailsForAllV2;
