import React, { useMemo } from "react";
import {
  useCostByRegionV2,
  useCostByServiceV2,
} from "../../../hooks/useCostsV2";
import TabContent from "./TabContent";

const COST_BY_REGION = "byRegion";
const COST_BY_SERVICE = "byService";

const colorsForPieChart = [
  "#c0e3ff",
  "#4eb1ff",
  "#2196F3",
  "#0074d0",
  "#0b3f69",
];

const CostByRegionV2 = (props) => {
  const { cloudProvider, startDate, endDate, showTabularView, activeKey } =
    props;

  const { getCloudCostByRegionQuery } = useCostByRegionV2(
    cloudProvider,
    startDate,
    endDate,
    activeKey
  );

  const { allEntries, top5Entries } = useMemo(() => {
    const allKeys =
      getCloudCostByRegionQuery?.data?.data?.map((item, index) => ({
        id: index,
        value: item.cost,
        label: item.region,
        percentage: item.percentage,
      })) ?? [];

    const top5Entries =
      allKeys
        ?.sort((a, b) => b.value - a.value)
        .slice(0, 5)
        .map((item, index) => ({
          ...item,
          color: colorsForPieChart[index],
        })) ?? [];

    return {
      allEntries: allKeys,
      top5Entries,
    };
  }, [getCloudCostByRegionQuery?.data]);

  return (
    <TabContent
      showTabularView={showTabularView}
      isError={getCloudCostByRegionQuery?.isError}
      error={getCloudCostByRegionQuery?.error}
      isLoading={
        getCloudCostByRegionQuery?.isLoading ||
        getCloudCostByRegionQuery?.isFetching
      }
      allEntries={allEntries}
      top5Entries={top5Entries}
      costByType={COST_BY_REGION}
    />
  );
};

const CostByServiceV2 = (props) => {
  const { cloudProvider, startDate, endDate, showTabularView, activeKey } =
    props;

  const { getCloudCostByServiceQuery } = useCostByServiceV2(
    cloudProvider,
    startDate,
    endDate,
    activeKey
  );

  const { allEntries, top5Entries } = useMemo(() => {
    const allKeys =
      getCloudCostByServiceQuery?.data?.map((item, index) => ({
        id: index,
        value: item.cost,
        label: item.service,
        percentage: item.percentage,
      })) ?? [];

    const top5Entries = allKeys
      ?.sort((a, b) => b.value - a.value)
      .slice(0, 5)
      .map((item, index) => ({
        ...item,
        color: colorsForPieChart[index],
      }));

    return {
      allEntries: allKeys,
      top5Entries,
    };
  }, [getCloudCostByServiceQuery?.data]);

  return (
    <TabContent
      showTabularView={showTabularView}
      isError={getCloudCostByServiceQuery?.isError}
      error={getCloudCostByServiceQuery?.error}
      isLoading={
        getCloudCostByServiceQuery?.isLoading ||
        getCloudCostByServiceQuery?.isFetching
      }
      allEntries={allEntries}
      top5Entries={top5Entries}
      costByType={COST_BY_SERVICE}
    />
  );
};

export { CostByRegionV2, CostByServiceV2 };
