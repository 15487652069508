import React from "react";
import { Bar, BarChart, Legend, ReferenceLine, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";

const CustomLabel = ({ viewBox, value }) => {
  const { y } = viewBox; // Get Y position

  return (
    <foreignObject x={10} y={y - 10} width={50} height={30}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #10CCD2",
          borderRadius: "4px",
          padding: "0 5px",
          fontSize: "10px",
          fontWeight: "bold",
          color: "#10CCD2",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </foreignObject>
  );
};

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
      {payload.map((entry, index) => (
        <div key={`legend-${index}`} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: entry.color }}></div>
          <span style={{ fontSize: "1rem" }}>
            {entry.value === "current_value" ? "Current Value" : "Forecasted Value"}
          </span>
        </div>
      ))}
    </div>
  );
};

const BudgetInsightsGraph = ({ data, thresholds }) => {
  return (
    <ResponsiveContainer width={1000}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
        barCategoryGap="25%"
        barGap={0}
      >
        <XAxis dataKey="budget_name" tick={{ fontSize: 10 }} />
        <YAxis
          label={{
            value: "Costs ($)",
            angle: -90,
            position: "insideLeft",
            style: { textAnchor: "middle" },
          }}
        />
        <Tooltip cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />
        <Legend
          verticalAlign="top"
          align="center"
          wrapperStyle={{ paddingBottom: 10 }}
          content={<CustomLegend />}
        />

        {/* Bars */}
        <Bar dataKey="current_value" fill="#0A3F69" label={"Actual"} barSize={20} barGap={5} />
        <Bar dataKey="forecasted_value" fill="#2196F3" label={"Budget"} barSize={20} barGap={5} />

        {/* Reference Lines with Custom Labels */}
        {thresholds?.map((threshold, index) => {
          return (
            <ReferenceLine
              key={index}
              y={parseInt(threshold)}
              stroke="#10CCD2"
              strokeDasharray="5 5"
              label={<CustomLabel value={threshold} />}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default BudgetInsightsGraph;
