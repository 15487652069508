import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";

import { useAnomaliesDetected } from "../../hooks/useAnomalies";
import SelectWithoutRHF from "../SelectWithoutRHF";
import { MODES } from "../BudgetAlerts/BudgetAlerts";
import { CREATE_MONITORS_TAB } from "./Anomalies";
import { getLogoForType } from "../../utils/commonUtil";
import CustomPagination from "../../ui/CustomPaginationForDataGrid";

const allOption = {
  label: "All",
  value: "all",
};

const costImpactOptions = [
  allOption,
  { label: "0-20%", value: "0-20" },
  { label: "20%-40%", value: "20-40" },
  { label: "40%-60%", value: "40-60" },
  { label: "60%-80%", value: "60-80" },
  { label: "80%-100%", value: "80-100" },
];

const columns = [
  {
    field: "id",
    headerName: "Id",
    headerClassName: "header-row",
  },
  {
    field: "monitor_name",
    headerName: "Anomaly For",
    minWidth: 150,
    flex: 1,
    headerClassName: "header-row",
  },
  {
    field: "anomaly_start_date",
    headerName: "Time Stamp",
    sortable: false,
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
    valueGetter: (_, row) => `${row.anomaly_start_date || ""} - ${row.anomaly_end_date || ""}`,
  },
  {
    field: "monitor_type",
    headerName: "Monitor Type",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
  },
  {
    field: "severity",
    headerName: "Severity",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
  },
  {
    field: "total_impact",
    headerName: "Cost Impact",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
    valueGetter: (_, row) => {
      return `${row.total_impact} (${row.total_impact_percentage}%)`;
    },
  },
  {
    field: "anomaly_root_cause",
    headerName: "Top Root Cause",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
  },
];

const paginationModel = { page: 0, pageSize: 10 };

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "#F5F5F5",
      }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const AnomaliesDetectedTab = (props) => {
  const { selectedCloudProvider, selectedAccessKey, selectedTimePeriodId, selectedMonitorNameId } = props;
  const [trendData, setTrendData] = useState({ data: [], anomalyLookup: new Set() });
  const [filters, setFilters] = useState({
    severity: allOption.value,
    costImpact: allOption.value,
    rootCause: allOption.value,
  });
  const [tableData, setTableData] = useState([]);
  const reactRouterDomHistory = useHistory();
  const { getAnomaliesDetectedQuery } = useAnomaliesDetected(selectedCloudProvider, selectedAccessKey, selectedTimePeriodId, selectedMonitorNameId);

  useEffect(() => {
    const dataBasedOnTimePeriod = getAnomaliesDetectedQuery.data?.cost_usage ?? [];

    const data = Object.entries(dataBasedOnTimePeriod).map(([key, value]) => ({
      date: key,
      current_value: value.current_value,
    }));

    const anomalyHost = getAnomaliesDetectedQuery.data?.anomalies?.find((anomaly) => {
      return anomaly.monitor_id === selectedMonitorNameId;
    })?.identified_by ?? "";

    setTrendData({
      ...trendData,
      data,
      anomalyLookup: new Set(getAnomaliesDetectedQuery.data?.anomaly_cost_points?.map(dateStr => new Intl.DateTimeFormat("en-CA").format(new Date(dateStr)))) ?? new Set(),
      anomalyHost,
    });
  }, [getAnomaliesDetectedQuery.data, selectedMonitorNameId]);

  useEffect(() => {
    if (!getAnomaliesDetectedQuery.data?.[0]?.anomalies) setTableData([]);

    setTableData(getAnomaliesDetectedQuery.data?.anomalies?.map((anomaly, idx) => ({
      ...anomaly,
      id: idx + 1,
    })) ?? []);
  }, [getAnomaliesDetectedQuery.data?.anomalies]);

  const handleDropDownChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const anomalySeverityOptions = useMemo(() => {
    const severity = [...new Set(getAnomaliesDetectedQuery.data?.anomalies?.map((anomaly) => anomaly.severity) ?? [])];

    const options = severity?.map((severity) => ({
      label: severity,
      value: severity,
    })) ?? [];

    return [allOption, ...options];
  }, [getAnomaliesDetectedQuery.data?.anomalies]);

  const anomalyRootCauseOptions = useMemo(() => {
    const rootCause = [...new Set(getAnomaliesDetectedQuery.data?.anomalies?.map((anomaly) => anomaly.anomaly_root_cause) ?? [])];

    const options = rootCause?.map((cause) => ({
      label: cause,
      value: cause,
    })) ?? [];

    return [allOption, ...options];
  }, [getAnomaliesDetectedQuery.data?.anomalies]);

  const filteredData = useMemo(() => {
    return tableData.filter((anomaly) => {
      const isFilterActive = (filterValue) => filterValue.length > 0 && filterValue !== "all";

      let [minImpact, maxImpact] = filters.costImpact.split("-").map(parseFloat);

      return (
        (!isFilterActive(filters.severity) || anomaly.severity.toLowerCase().includes(filters.severity.toLowerCase())) &&
        (!isFilterActive(filters.costImpact) || (minImpact <= parseFloat(anomaly.total_impact) && parseFloat(anomaly.total_impact) <= maxImpact)) &&
        (!isFilterActive(filters.rootCause) || anomaly.anomaly_root_cause.toLowerCase().includes(filters.rootCause.toLowerCase()))
      );
    });
  }, [tableData, filters]);


  if (!selectedAccessKey) {
    return (<>Access key is required...</>);
  }

  if (getAnomaliesDetectedQuery.isLoading) {
    return (
      <Skeleton animation={"wave"} variant="rectangular" height="500px" />
    );
  }

  return (
    <Box paddingTop={"20px"}>
      <Box marginBottom={"20px"}>
        <Card>
          <CardContent>
            <Box display={"flex"} justifyContent={"center"} height={"calc(100vh - 660px)"} minHeight={"230px"}>
              {trendData.data.length > 0 ? (<ResponsiveContainer width="100%" height="100%">
                <LineChart data={trendData.data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                  <XAxis
                    dataKey="date"
                    label={{ value: "Duration", position: "bottom", dy: 25 }}
                    tick={{ fontSize: 10, angle: -45, textAnchor: "end" }}
                    tickFormatter={(value, index) => index % 2 === 0 ? value : ""}
                  />
                  <YAxis label={{ value: "Cost ($)", angle: -90, position: "left" }} />
                  <Tooltip />

                  <Line
                    type="monotone"
                    dataKey="current_value"
                    stroke="#1E90FF"
                    strokeWidth={2}
                    dot={({ cx, cy, payload }) => {
                      if (trendData.anomalyLookup?.has(payload.date)) {
                        return (
                          <svg x={cx - 20} y={cy - 20} width={40} height={40} viewBox="0 0 40 40">
                            <circle cx="20" cy="20" r="18" fill="white" stroke="red" strokeWidth="3" />
                            <image href={getLogoForType(trendData.anomalyHost)} x="8" y="8" width="24" height="24" />
                          </svg>
                        );
                      }
                      return null;
                    }}
                    name="Actual Trend"
                  />
                </LineChart>
              </ResponsiveContainer>) : <Box>No data available</Box>}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Card>
        <CardContent>
          <Box>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"}>
                  Anomalies Detected
                </Typography>
                <Button
                  variant={"contained"}
                  onClick={() => reactRouterDomHistory.push(`/anomalies/${CREATE_MONITORS_TAB}?mode=${MODES.CREATE}`)}
                >
                  + CREATE
                </Button>
              </Box>
              <Box display={"flex"} alignItems={"center"}
                   gap={"16px"} marginY={"20px"}>
                {getAnomaliesDetectedQuery.isLoading || getAnomaliesDetectedQuery.isFetching ? (
                  <>
                    <Skeleton variant="rectangular" height={"40px"} width={"120px"} />
                    <Skeleton variant="rectangular" height={"40px"} width={"120px"} />
                    <Skeleton variant="rectangular" height={"40px"} width={"120px"} />
                  </>
                ) : (
                  <>
                    <SelectWithoutRHF
                      sx={{
                        minWidth: 120,
                      }}
                      name={"severity"}
                      label={"Severity"}
                      options={anomalySeverityOptions}
                      value={filters.severity}
                      onChange={handleDropDownChange}
                    />
                    <SelectWithoutRHF
                      sx={{
                        minWidth: 120,
                      }}
                      width={"fit-content"}
                      name={"costImpact"}
                      label={"Cost Impact"}
                      options={costImpactOptions}
                      value={filters.costImpact}
                      onChange={handleDropDownChange}
                    />
                    <SelectWithoutRHF
                      sx={{
                        minWidth: 120,
                      }}
                      width={"fit-content"}
                      name={"rootCause"}
                      label={"Top Root Cause"}
                      options={anomalyRootCauseOptions}
                      value={filters.rootCause}
                      onChange={handleDropDownChange}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                overflowX: "auto",
                "& .header-row": {
                  backgroundColor: "rgba(33, 150, 243, 0.1)",
                },
              }}
            >
              <DataGrid
                rows={filteredData}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                sx={{ border: 0, borderRadius: "10px" }}
                loading={getAnomaliesDetectedQuery.isLoading || getAnomaliesDetectedQuery.isFetching}
                slotProps={{
                  loadingOverlay: {
                    variant: "skeleton",
                    noRowsVariant: "skeleton",
                  },
                }}
                slots={{
                  pagination: CustomPagination,
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableRowSelectionOnClick
                autoHeight
              />
            </Box>

          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default AnomaliesDetectedTab;

