import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "../reducers";
import history from "../utils/history";

export { history };

const middlewares = [thunkMiddleware, routerMiddleware(history)];

const store = createStore(
  createRootReducer(history),
  {},
  compose(applyMiddleware(...middlewares))
);

export default store;
