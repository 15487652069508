import React, { useCallback, useContext, useEffect, useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Card, CardContent, CircularProgress, FormHelperText, Skeleton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { useFieldArray, useForm } from "react-hook-form";


import InputField from "../InputField/InputField";
import SelectField from "../Select/SelectField";
import CheckboxField from "../CheckboxField/CheckboxField";
import { AnomaliesSnackBarProvider, MODES } from "./Anomalies";
import { useGetNotificationChannels } from "../../hooks/useBudgetAlerts";
import {
  useCreateAnomaly,
  useFrequencyList,
  useGetAnomaly,
  useGetMonitorTypes, useLinkedAccounts,
  useTriggerOnList, useUpdateAnomaly,
} from "../../hooks/useAnomalies";
import useQueryParams from "../../hooks/customHooks/useQueryParams";

// TODD: find a better way to do this
const MONITOR_TYPE_LINKED_ACCOUNT = 4;

const CreateMonitorsTab = (props) => {
  const { selectedCloudProvider, activeMode, handleModeChange, cloudProviderKeysOptions } = props;
  const { handleSubmit, control, setValue, getValues, watch, formState: { errors }, reset } = useForm({
    defaultValues: {
      alertFields: [
        {
          subscription_name: "",
          frequency_id: "",
          threshold: "",
          trigger_on_id: "",
          email: "",
          notification_channel: [],
        },
      ],
      monitorName: "",
      keyId: "",
      monitorTypeId: "",
      linkedAccountIds: [],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "alertFields",
  });

  const handleAddAlertFields = () => {
    append({ threshold: "", frequency_id: "", trigger_on_id: "", email: [], notification_channel: [] });
  };

  const handleRemoveAlertFields = (id) => {
    remove(id);
  };

  const validateEmails = useCallback((emailString) => {
    const emails = emailString.split(",").map((email) => email.trim());
    const checkDuplicateEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
    if (checkDuplicateEmails.length > 0) {
      return "Duplicate emails are not allowed";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    const invalidEmails = emails.filter((email) => !emailRegex.test(email));
    return invalidEmails.length === 0 || "Invalid email(s) detected";
  }, []);

  const handleCheckboxChange = (e, checked, value) => {
    const name = e.target.name;
    const selectedNotificationChannels = getValues(name);

    if (checked) {
      setValue(name, [value], { shouldValidate: true });
    } else {
      setValue(name, selectedNotificationChannels?.filter((v) => v !== value), { shouldValidate: true });
    }
  };

  const { handleShowSnackbar } = useContext(AnomaliesSnackBarProvider);
  const queryParams = useQueryParams();
  const { getNotificationChannelsQuery } = useGetNotificationChannels();
  const { getMonitorTypesQuery } = useGetMonitorTypes();
  const { getFrequencyListQuery } = useFrequencyList();
  const { getTriggerOnListQuery } = useTriggerOnList();
  const { createAnomalyMutation } = useCreateAnomaly();
  const { getAnomalyQuery } = useGetAnomaly(queryParams.get("anomalyId"), selectedCloudProvider);
  const { updateAnomalyMutation } = useUpdateAnomaly();
  const { getLinkedAccountsQuery } = useLinkedAccounts(selectedCloudProvider, watch("keyId"), watch("monitorTypeId"), watch("monitorTypeId") === MONITOR_TYPE_LINKED_ACCOUNT);

  const anomalyToEdit = useMemo(() => {
    if (!getAnomalyQuery.data) return null;
    return getAnomalyQuery.data;
  }, [getAnomalyQuery.data]);

  useEffect(() => {
    if (activeMode === MODES.EDIT) {
      const anomalyName = anomalyToEdit?.name;
      const anomalyKey = anomalyToEdit?.key_id;
      const anomalyMonitorType = anomalyToEdit?.monitor_type?.id;
      const anomalyHost = anomalyToEdit?.host;
      const anomalyAlertFields = anomalyToEdit?.alert?.map((alert) => {
        return {
          subscription_name: alert.subscription_name,
          threshold: alert.threshold,
          frequency_id: alert.frequency?.id,
          trigger_on_id: alert.trigger_on?.id,
          id: alert.id,
          email: alert?.email?.join(", "),
          notification_channel: alert?.notification_channel,
        };
      });
      const linkedAccountIds = anomalyToEdit?.monitor_values;

      setValue("monitorName", anomalyName);
      setValue("keyId", anomalyKey);
      setValue("monitorTypeId", anomalyMonitorType);
      setValue("host", anomalyHost);
      setValue("alertFields", anomalyAlertFields);
      setValue("linkedAccountIds", linkedAccountIds);
    }
  }, [activeMode, anomalyToEdit, setValue]);

  const handleCancel = () => {
    reset();
    handleModeChange(MODES.CREATE);
  };

  const handleSubmitForm = (data) => {
    const formattedAlertFields = data.alertFields.map((alertField) => {
      return {
        subscription_name: alertField.subscription_name,
        frequency_id: alertField.frequency_id,
        threshold: parseInt(alertField.threshold),
        trigger_on_id: alertField.trigger_on_id,
        email: alertField.email.split(",").map((email) => email.trim()),
        notification_channel: alertField.notification_channel,
        ...(alertField.id ? { id: alertField.id } : {}),
      };
    });

    const dataToSubmit = {
      name: data.monitorName,
      key_id: data.keyId,
      monitor_type_id: data.monitorTypeId,
      alert: formattedAlertFields,
      monitor_values: data.linkedAccountIds,
      ...(queryParams.get("anomalyId") ? { anomalyId: queryParams.get("anomalyId") } : {}),
    };

    if (activeMode === MODES.CREATE) {
      createAnomalyMutation.mutate(dataToSubmit, {
        onSuccess: (data) => {
          handleShowSnackbar(
            data.message || "Anomaly created successfully",
            "success",
          );
          handleModeChange(MODES.CREATE);
          createAnomalyMutation.reset();
          reset();
        },
        onError: (error) => {
          handleShowSnackbar(error.message, "error");
          createAnomalyMutation.reset();
        },
      });
    }

    if (activeMode === MODES.EDIT) {
      updateAnomalyMutation.mutate(dataToSubmit, {
        onSuccess: (data) => {
          handleShowSnackbar(
            data.message || "Anomaly updated successfully",
            "success",
          );
          handleModeChange(MODES.CREATE);
          updateAnomalyMutation.reset();
          reset();
        },
        onError: (error) => {
          handleShowSnackbar(error.message || "Something went wrong, while updating anomaly, please try after some time.", "error");
          updateAnomalyMutation.reset();
        },
      });
    }
  };

  const monitorTypesOptions = useMemo(() => {
    return getMonitorTypesQuery.data?.map((monitorType) => ({
      label: monitorType.name,
      value: monitorType.id,
    })) ?? [];
  }, [getMonitorTypesQuery]);

  const frequencyOptions = useMemo(() => {
    return getFrequencyListQuery.data?.map((frequency) => ({
      label: frequency.name,
      value: frequency.id,
    })) ?? [];
  }, [getFrequencyListQuery]);

  const triggerOnOptions = useMemo(() => {
    return getTriggerOnListQuery.data?.map((triggerOn) => ({
      label: triggerOn.name,
      value: triggerOn.id,
    })) ?? [];
  }, [getTriggerOnListQuery]);

  const linkedAccountOptions = useMemo(() => {
    return getLinkedAccountsQuery.data?.map((account) => ({
      label: account,
      value: account,
    })) ?? [];
  }, [getLinkedAccountsQuery]);


  const notificationChannelsOptions = useMemo(() => {
    return getNotificationChannelsQuery.data?.map((channel) => ({
      label: channel.name,
      value: channel.id,
    })) ?? [];
  }, [getNotificationChannelsQuery.data]);

  if (getNotificationChannelsQuery.isLoading || getMonitorTypesQuery.isLoading || getTriggerOnListQuery.isLoading || getFrequencyListQuery.isLoading || (activeMode === MODES.EDIT && getAnomalyQuery.isLoading)) {
    return (
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr 1fr"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 215px)"}
        gap={"20px"}
      >
        <Skeleton variant="rectangular" height={"100%"} width={"100%"} />
        <Skeleton variant="rectangular" height={"100%"} width={"100%"} />
      </Box>
    );
  }

  if (getAnomalyQuery.isError) {
    return (
      <Box
        display={"grid"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 215px)"}
        gap={"20px"}
      >
        <Typography variant="h6" color={"error.main"}>
          {getAnomalyQuery.error?.message ?? "An error occurred while fetching the anomaly data. Please try again later"}
        </Typography>
      </Box>
    );
  }


  return (
    <Card>
      <CardContent sx={{ padding: "24px" }}>
        <Box display={"flex"} alignItems={"center"} gap={"24px"} marginBottom={"24px"}>
          <Typography variant="h5">{activeMode === MODES.CREATE ? "Create" : "Edit"} Monitor</Typography>
          {activeMode === MODES.EDIT ?
            <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => handleModeChange(MODES.CREATE)}>
              + Create
            </Button>
            :
            null
          }
        </Box>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box display={"flex"} gap={"24px"}>
            <Box
              flex={1}
              sx={{
                borderRight: "1px solid",
                paddingRight: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <InputField
                control={control}
                name="monitorName"
                label="Monitor Name"
                placeholder="Enter a name"
                size={"small"}
                rules={{ required: { value: true, message: "Monitor Name is required" } }}
                fullWidth
              />
              <SelectField
                control={control}
                name="keyId"
                label="Key"
                placeholder="Key"
                fullWidth
                size={"small"}
                rules={{ required: { value: true, message: "Key is required" } }}
                options={cloudProviderKeysOptions}
                disabled={activeMode === MODES.EDIT}
              />
              <SelectField
                control={control}
                name="monitorTypeId"
                label="Monitor Type"
                placeholder="Enter a type"
                fullWidth
                size={"small"}
                rules={{ required: { value: true, message: "Monitory Type is required" } }}
                options={monitorTypesOptions}
              />
              {watch("monitorTypeId") === MONITOR_TYPE_LINKED_ACCOUNT ? (
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <SelectField
                    control={control}
                    name="linkedAccountIds"
                    label="Linked Account"
                    placeholder="Select an account"
                    multiple
                    fullWidth
                    size={"small"}
                    rules={{ required: { value: true, message: "Linked Account is required" } }}
                    options={linkedAccountOptions}
                    disabled={getLinkedAccountsQuery.isLoading || getLinkedAccountsQuery.isFetching}
                    sx={{ "& .Mui-disabled": { opacity: 0.5, cursor: "not-allowed" } }}
                  />
                  {getLinkedAccountsQuery.isLoading || getLinkedAccountsQuery.isFetching ? (
                    <CircularProgress size={24} style={{ color: "blue" }} />
                  ) : null}
                </Box>
              ) : null}
            </Box>
            <Box flex={1}>
              <Typography variant="h5" marginBottom={"20px"}>Set Alert</Typography>
              {fields.map((field, index) => {
                return (
                  <Box marginBottom={"24px"} key={field.id} border={"1px solid #C4C4C4"} position={"relative"}
                       padding={"15px"}>
                    {fields.length > 1 ? (
                      <Box color={"red"} position={"absolute"} top={"-10px"} right={"-10px"} sx={{
                        backgroundColor: "white",
                        border: "1px solid red",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        boxShadow: "0px 1.667px 5px 1.667px rgba(0, 0, 0, 0.15)",
                      }}>
                        <RemoveIcon
                          fontSize={"small"}
                          onClick={() => handleRemoveAlertFields(index)}
                        />
                      </Box>
                    ) : null}
                    <InputField
                      control={control}
                      name={`alertFields.${index}.subscription_name`}
                      rules={{
                        required: {
                          value: true,
                          message: "Subscription Name is required",
                        },
                      }}
                      placeholder={"Subscription Name"}
                      label={"Subscription Name"}
                      size={"small"}
                      sx={{
                        margin: "0 0 16px 0",
                      }}
                      fullWidth
                    />
                    <Box display="flex" gap={2}>
                      <InputField
                        type={"number"}
                        control={control}
                        name={`alertFields.${index}.threshold`}
                        rules={{
                          required: {
                            value: true,
                            message: "Threshold is required",
                          },
                          min: { value: 1, message: "Number must be greater than 0" },
                          validate: (value) => {
                            const parsedValue = parseFloat(value);
                            if (isNaN(parsedValue)) {
                              return "Threshold must be a number";
                            }
                            return true;
                          },
                        }}
                        placeholder={"$"}
                        label={"Threshold"}
                        size={"small"}
                        fullWidth
                      />
                      <SelectField
                        control={control}
                        name={`alertFields.${index}.frequency_id`}
                        label={`Frequency`}
                        size={"small"}
                        fullWidth
                        rules={{ required: { value: true, message: "Frequency is required" } }}
                        options={frequencyOptions}
                      />
                      <SelectField
                        control={control}
                        name={`alertFields.${index}.trigger_on_id`}
                        label={`Trigger On`}
                        size={"small"}
                        fullWidth
                        rules={{ required: { value: true, message: "Trigger On is required" } }}
                        options={triggerOnOptions}
                      />
                    </Box>
                    <Box margin={"16px 0 16px 0"}>
                      <InputField
                        control={control}
                        name={`alertFields.${index}.email`}
                        label="Subscriber Email"
                        placeholder="Subscriber Email"
                        size={"small"}
                        rules={{
                          validate: validateEmails,
                        }}
                        fullWidth
                      />
                      <FormHelperText>Enter comma separated text eg. (email1,email2)</FormHelperText>
                    </Box>
                    <Box>
                      <Typography variant="h5">Budget Notification</Typography>
                      {notificationChannelsOptions.map((channel) => {
                        return (
                          <Box key={channel.value}>
                            <CheckboxField
                              control={control}
                              name={`alertFields.${index}.notification_channel`}
                              label={channel.label}
                              onChange={(e) => handleCheckboxChange(e, e.target.checked, channel.value)}
                              value={channel.value}
                              shouldShowError={false}
                              rules={{ validate: (value) => value.length === 1 || "Please choose one notification channel" }}
                              disabled={channel.label.toLowerCase() === "teams"}
                            />
                          </Box>
                        );
                      })}
                      {errors.notificationChannels && (
                        <FormHelperText error>{errors.notificationChannels.message}</FormHelperText>
                      )}
                    </Box>
                  </Box>
                );
              })}
              {fields.length < 3 ?
                (
                  <Button
                    variant={"outlined"}
                    onClick={handleAddAlertFields}
                  >
                    + Add
                  </Button>
                ) : null}
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            <Button
              variant={"outlined"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              type={"submit"}
              disabled={createAnomalyMutation.isPending || updateAnomalyMutation.isPending}
            >
              {createAnomalyMutation.isPending || updateAnomalyMutation.isPending ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};
export default CreateMonitorsTab;
