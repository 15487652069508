import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent, CircularProgress,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip as MuiToolTip,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";

import { CLOUDNUDGE } from "../../utils/constants";
import { useDeleteAnomalies } from "../../hooks/useAnomalies";
import { AnomaliesSnackBarProvider, CREATE_MONITORS_TAB, FETCHED_MONITORS_TAB, MODES } from "./Anomalies";
import { getLogoForType } from "../../utils/commonUtil";
import CustomPagination from "../../ui/CustomPaginationForDataGrid";

const AlertDetails = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reactRouterDomHistory = useHistory();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleEditAlerts = () => {
    const anomalyId = value.row.anomalyId;
    const host = value.row.host;
    if (anomalyId && host === CLOUDNUDGE) {
      return reactRouterDomHistory.push(`/anomalies/${CREATE_MONITORS_TAB}?mode=${MODES.EDIT}&anomalyId=${anomalyId}`);
    }
  };


  return (
    <>
      <Box onClick={handleClick} color={"#2196F3"} sx={{ cursor: "pointer" }}>View Details</Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box width={650} sx={{ p: 2, maxHeight: "300px", overflowY: "auto" }}>
          <Box display={"flex"} alignItems={"center"} gap={"10px"} marginBottom={"10px"}>
            <Typography variant="h5">Alerts ({value.row.alert_subscribers?.length ?? 0})</Typography>
            {value.row.host === CLOUDNUDGE ? (
              <Box padding={"10px"} borderRadius={"50%"} onClick={handleEditAlerts}
                   sx={{
                     border: "1px solid #2196F3",
                     width: "30px",
                     height: "30px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     padding: "10px",
                     cursor: "pointer",
                   }}>
                <EditIcon color={"primary"} fontSize={"small"} />
              </Box>) : null}
          </Box>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Subscriber Name</TableCell>
                    <TableCell>Threshold Exceeded or Reached</TableCell>
                    <TableCell>Alert Frequency</TableCell>
                    <TableCell>Subscribers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.row.alert_subscribers?.map((al) => {
                    const subscribers = value.row.host === CLOUDNUDGE ? al.email : al.subscribers;
                    return (
                      <TableRow key={al.id}>
                        <TableCell>{al.subscription_name}</TableCell>
                        <TableCell>{al.threshold}</TableCell>
                        <TableCell>{al.frequency}</TableCell>
                        <TableCell>
                          <Tooltip title={subscribers?.map((s) => s.address).join(", ")}>
                            <Box>{subscribers?.length}</Box>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const MonitorName = ({ value }) => {

  const reactRouterDomHistory = useHistory();

  const handleEditAnomaly = (e) => {
    e.stopPropagation();
    const anomalyId = value.row.anomalyId;
    const host = value.row.host;
    if (anomalyId && host === CLOUDNUDGE) {
      return reactRouterDomHistory.push(`/anomalies/${CREATE_MONITORS_TAB}?mode=${MODES.EDIT}&anomalyId=${anomalyId}`);
    }
    return null;
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box
        component="img"
        src={getLogoForType(value.row.host)}
        alt={`${value.row.host} logo`}
        width="30px"
        height="20px"
      />
      <MuiToolTip
        title={value.value}
        placement={"right"}
      >
        <Box
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            pointerEvents: "auto",
            textDecoration: value.row.host === CLOUDNUDGE ? "underline" : "none",
            cursor: value.row.host === CLOUDNUDGE ? "pointer" : "default",
          }}
          onClick={handleEditAnomaly}
        >
          {value.value}
        </Box>
      </MuiToolTip>
    </Box>
  );
};

const columns = [
  {
    field: "id",
    headerName: "Id",
    headerClassName: "header-row",
  },
  {
    field: "monitor_name",
    headerName: "Monitor Name",
    minWidth: 150,
    flex: 1,
    headerClassName: "header-row",
    renderCell: (value) => <MonitorName value={value} />,
  },
  {
    field: "monitor_type",
    headerName: "Monitor Type",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
    renderCell: (value) => {
      return (
        <Box>
          <Tooltip title={value.row.monitor_values?.join(", ")} placement="right">
            {value.row.monitor_type}
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: "created_date",
    headerName: "Creation Date",
    sortable: false,
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
  },
  {
    field: "alert_subscribers",
    headerName: "Alert Subscription",
    minWidth: 150,
    flex: 1,
    sortable: false,
    headerClassName: "header-row",
    renderCell: (value) => {
      return (
        <AlertDetails value={value} />
      );
    },
    valueGetter: (value, row) => {
      const email = [];
      row.alert_subscribers?.forEach((al) => {
        if (row.host === CLOUDNUDGE) {
          al.email?.forEach((e) => email.push(e.address));
        } else {
          al.subscribers?.forEach((s) => email.push(s.address));
        }
      });

      return `${email.join(", ")}`;
    },
  },
  {
    field: "key_label",
    headerName: "Key",
    minWidth: 120,
    flex: 1,
    headerClassName: "header-row",
  },
];

const CustomToolbar = ({ selectedCloudProvider, selectedAccessKey }) => {
  const apiRef = useGridApiContext();
  const { handleShowSnackbar } = useContext(AnomaliesSnackBarProvider);
  const { deleteAnomaliesMutation } = useDeleteAnomalies();

  const handleDelete = () => {
    const selectedRows = apiRef.current.getSelectedRows();

    if (selectedRows.size === 0) {
      return handleShowSnackbar("Please select at least one anomaly to delete", "error");
    }

    const idsToDelete = [];
    selectedRows.values().forEach((row) => idsToDelete.push(row.anomalyId));

    deleteAnomaliesMutation.mutate({ idsToDelete, selectedAccessKey, selectedCloudProvider }, {
      onSuccess: (data) => {
        apiRef.current.setRowSelectionModel([]);
        handleShowSnackbar("Selected anomalies deleted successfully", "success");
      },
      onError: (error) => {
        handleShowSnackbar("Something went wrong, while deleting anomalies, please try after some time.", "error");
      },
    });
  };

  const handleExportCSV = () => {
    apiRef.current.exportDataAsCsv({
      fileName: "CloudNudge-Anomalies",
      fields: ["id", "monitor_name", "monitor_type", "created_date", "alert_subscribers", "key_label"],
      getRowsToExport: (params) => {
        return params.apiRef.current.getAllRowIds();
      },
    });
  };

  const reactRouterDomHistory = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Typography variant="h5">Fetched Monitors</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          startIcon={<DeleteIcon />}
          variant={"outlined"}
          onClick={handleDelete}
          disabled={deleteAnomaliesMutation.isPending}
        >
          {deleteAnomaliesMutation.isPending ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : "Delete"}
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<UploadFileIcon />}
          onClick={handleExportCSV}
        >
          Export CSV
        </Button>
        <Button
          variant={"contained"}
          onClick={() => reactRouterDomHistory.push(`/anomalies/${CREATE_MONITORS_TAB}?mode=${MODES.CREATE}`)}
        >
          + CREATE
        </Button>
      </Box>
    </Box>
  );
};

const paginationModel = { page: 0, pageSize: 10 };

const FetchedMonitorsTab = ({
                              selectedCloudProvider,
                              selectedAccessKey,
                              selectedMonitorType,
                              selectedHost,
                              getFetchedAnomaliesQuery,
                            }) => {

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!getFetchedAnomaliesQuery.data) {
      setTableData([]);
      return;
    }

    setTableData(getFetchedAnomaliesQuery.data?.map((anomaly, idx) => ({
      ...anomaly,
      id: idx + 1,
      anomalyId: anomaly.id,
    })) ?? []);
  }, [getFetchedAnomaliesQuery.data]);


  const filteredData = useMemo(() => {
    return tableData.filter((anomaly) => {
      const isFilterActive = (filterValue) => filterValue.length > 0 && filterValue !== "all";

      return (
        (!isFilterActive(selectedAccessKey.toString()) || anomaly.key_id === selectedAccessKey) &&
        (!isFilterActive(selectedMonitorType) || anomaly.monitor_type.toLowerCase().includes(selectedMonitorType.toLowerCase())) &&
        (!isFilterActive(selectedHost) || anomaly.host.toLowerCase().includes(selectedHost.toLowerCase()))
      );
    });
  }, [selectedAccessKey, selectedHost, selectedMonitorType, tableData]);


  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            overflowX: "auto",
            "& .header-row": {
              backgroundColor: "rgba(33, 150, 243, 0.1)",
            },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              backgroundColor: "rgba(33, 150, 243, 0.1)",
            },
          }}
        >
          <DataGrid
            rows={filteredData}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            isRowSelectable={(params) => params.row.host === CLOUDNUDGE}
            sx={{ border: 0, borderRadius: "10px", width: "100%" }}
            loading={getFetchedAnomaliesQuery.isLoading || getFetchedAnomaliesQuery.isFetching}
            slotProps={{
              loadingOverlay: {
                variant: "skeleton",
                noRowsVariant: "skeleton",
              },
            }}
            slots={{
              toolbar: () => <CustomToolbar
                selectedCloudProvider={selectedCloudProvider}
                selectedAccessKey={selectedAccessKey}
              />,
              pagination: CustomPagination,
            }}
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </CardContent>
    </Card>
  );
};
export default FetchedMonitorsTab;
