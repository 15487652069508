import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";

import BudgetAlertsTabs from "./BudgetAlertsTabs";
import { useGetCloudKeys, useGetCloudTypes } from "../../hooks/useRecommendationsV2";
import SelectComponent from "./Select";
import BudgetInsightsTab from "./BudgetInsightsTab";
import BudgetTab from "./BudgetTab";
import CreateBudgetTab from "./CreateBudgetTab";
import useQueryParams from "../../hooks/customHooks/useQueryParams";
import { useGetBudgetList } from "../../hooks/useBudgetAlerts";
import { CLOUD_PROVIDER_AWS } from "../../utils/constants";

export const BUDGET_INSIGHT_TAB = "budget-insights";
export const BUDGET_TAB = "budget";
export const CREATE_BUDGET_TAB = "create-budget";

const TABS = [
  {
    id: 0,
    label: "Budget Insights",
    value: BUDGET_INSIGHT_TAB,
    Component: BudgetInsightsTab,
  },
  {
    id: 1,
    label: "Budget",
    value: BUDGET_TAB,
    Component: BudgetTab,
  },
  {
    id: 2,
    label: "Create Budget",
    value: CREATE_BUDGET_TAB,
    Component: CreateBudgetTab,
  },
];

export const MODES = {
  CREATE: "create",
  EDIT: "edit",
};

export const BudgetAlertSnackBarProvider = createContext(null);

export const allOption = {
  label: "All",
  value: "all",
};

const BudgetAlerts = () => {
  const reactRouterDomHistory = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { tab: tabFromUrl } = useParams();
  const [dropDownData, setDropDownData] = useState({
    cloudProvider: "",
    accessKeyId: "",
  });
  const [budgetTabFilters, setBudgetTabFilters] = useState({
    budgetName: allOption.value,
    budgetType: allOption.value,
    host: allOption.value,
    key: allOption.value,
  });
  const [mode, setMode] = useState(MODES.CREATE);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { getBudgetListQuery } = useGetBudgetList(dropDownData.cloudProvider);

  const budgetNameOptions = useMemo(() => {
    const options = getBudgetListQuery.data?.map((budget) => ({
      label: budget.budget_name,
      value: budget.budget_name,
    })) ?? [];

    return [allOption, ...options];
  }, [getBudgetListQuery]);

  const budgetTypeOptions = useMemo(() => {

    const budgetType = [...new Set(getBudgetListQuery.data?.map((budget) => budget.budget_type) ?? [])];

    const options = budgetType.map((budget) => ({
      label: budget,
      value: budget,
    })) ?? [];

    return [allOption, ...options];
  }, [getBudgetListQuery]);

  const hostOptions = useMemo(() => {

    const hosts = [...new Set(getBudgetListQuery.data?.map((budget) => budget.host) ?? [])];

    const options = hosts.map((host) => ({
      label: host,
      value: host,
    })) ?? [];

    return [allOption, ...options];

  }, [getBudgetListQuery]);

  const keyOptions = useMemo(() => {
    const keys = [...new Set(getBudgetListQuery.data?.map((budget) => budget.key_label) ?? [])];

    const options = keys.map((key) => ({
      label: key,
      value: key,
    })) ?? [];

    return [allOption, ...options];
  }, [getBudgetListQuery]);

  const modeFromUrl = useMemo(() => {
    return queryParams.get("mode");
  }, [queryParams]);

  useEffect(() => {
    if (modeFromUrl) {
      setMode(modeFromUrl);
    }

    if (tabFromUrl) {
      setSelectedTab(TABS.find((t) => t.value === tabFromUrl));
    }
  }, [modeFromUrl, tabFromUrl]);

  const handleModeChange = (value) => {
    setMode(value);
    reactRouterDomHistory.push(`${location.pathname}?mode=${value}`);
  };

  const [openSnackbar, setOpenSnackbar] = useState({
    value: false,
    message: "",
    severity: "success",
  });

  const handleShowSnackbar = (message = "", severity = "success") => {
    setOpenSnackbar({
      value: true,
      message: message,
      severity: severity,
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar({ value: false, message: "", severity: "" });
  };


  const handleTabChange = useCallback((event, newValue) => {
    const selectedTabDetails = TABS.find((tab) => tab.id === newValue);
    setSelectedTab(selectedTabDetails);
    // reset mode to create
    setMode(MODES.CREATE);
    reactRouterDomHistory.push(`/budget-alerts/${selectedTabDetails.value}`);
  }, [reactRouterDomHistory]);

  const getCloudTypesQuery = useGetCloudTypes();
  const getCloudKeysQuery = useGetCloudKeys(dropDownData.cloudProvider);

  const handleDropDownChange = (e) => {
    setDropDownData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleBudgetTabDropDownChange = (e) => {
    setBudgetTabFilters((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const cloudProviderOptions = useMemo(() => {
    return getCloudTypesQuery.data?.map((cloudType) => ({
      label: cloudType.name,
      value: cloudType.name.toLowerCase(),
      // Disable rest of the cloud providers except AWS as of now because we are not supporting them
      disabled: cloudType.name.toLowerCase() !== CLOUD_PROVIDER_AWS,
    }));
  }, [getCloudTypesQuery.data]);

  const cloudProviderKeysOptions = useMemo(() => {
    return getCloudKeysQuery.data?.map((key) => ({
      label: key.key_label,
      value: key.id,
    }));
  }, [getCloudKeysQuery.data]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      cloudProvider: cloudProviderOptions?.[0]?.value || "",
    }));
  }, [cloudProviderOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      accessKeyId: cloudProviderKeysOptions?.[0]?.value || "",
    }));
  }, [cloudProviderKeysOptions]);


  if (getCloudTypesQuery.error || getCloudKeysQuery.error) {
    return (<>Error: {getCloudTypesQuery.error.message || getCloudKeysQuery.error.message}</>);
  }

  if (getCloudTypesQuery.isLoading || getCloudKeysQuery.isLoading) {
    return (
      <Box>
        <Skeleton variant="rectangular" height={"calc(100vh - 100px)"} width={"100%"} />
      </Box>
    );
  }

  return (
    <BudgetAlertSnackBarProvider.Provider value={{ handleShowSnackbar }}>
      <Box>
        <Box display={"flex"} alignItems={"center"} gap={"16px"} marginBottom={"21px"}>
          <SelectComponent
            name={"cloudProvider"}
            label={"Cloud"}
            options={cloudProviderOptions}
            value={dropDownData.cloudProvider ? dropDownData.cloudProvider : ""}
            onChange={handleDropDownChange}
          />
          {/*show only if it is insights tab*/}
          {selectedTab.value === BUDGET_INSIGHT_TAB ? (
            <SelectComponent
              name={"accessKeyId"}
              label={"Key"}
              options={cloudProviderKeysOptions}
              value={dropDownData.accessKeyId ? dropDownData.accessKeyId : ""}
              onChange={handleDropDownChange} />
          ) : null}
          {selectedTab.value === BUDGET_TAB ? (
            <Box display={"flex"} alignItems={"center"}
                 gap={"16px"}>
              <SelectComponent
                fullWidth
                name={"budgetName"}
                label={"Budget Name"}
                options={budgetNameOptions}
                value={budgetTabFilters.budgetName}
                onChange={handleBudgetTabDropDownChange}
                sx={{
                  minWidth: "100px",
                }}
              />
              <SelectComponent
                fullWidth
                name={"budgetType"}
                label={"Budget Type"}
                options={budgetTypeOptions}
                value={budgetTabFilters.budgetType}
                onChange={handleBudgetTabDropDownChange}
                sx={{
                  minWidth: "100px",
                }}
              />
              <SelectComponent
                fullWidth
                name={"host"}
                label={"Host"}
                options={hostOptions}
                value={budgetTabFilters.host}
                onChange={handleBudgetTabDropDownChange}
                sx={{
                  minWidth: "100px",
                }}
              />
              <SelectComponent
                fullWidth
                name={"key"}
                label={"Key"}
                options={keyOptions}
                value={budgetTabFilters.key}
                onChange={handleBudgetTabDropDownChange}
                sx={{
                  minWidth: "100px",
                }}
              />
            </Box>
          ) : null}
        </Box>
        <BudgetAlertsTabs
          TABS={TABS}
          activeMode={mode}
          handleModeChange={handleModeChange}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          selectedCloudProvider={dropDownData.cloudProvider}
          selectedAccessKey={dropDownData.accessKeyId}
          cloudProviderKeysOptions={cloudProviderKeysOptions}
          budgetTabData={getBudgetListQuery.data}
          budgetTabFilters={budgetTabFilters}
          isBudgetTabDataLoading={getBudgetListQuery.isLoading || getBudgetListQuery.isFetching}
        />
      </Box>
      <Snackbar
        open={openSnackbar.value}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={openSnackbar.severity}
          variant="filled"
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </BudgetAlertSnackBarProvider.Provider>
  );
};
export default BudgetAlerts;
