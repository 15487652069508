import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useGetCloudKeys, useGetCloudTypes } from "../../hooks/useRecommendationsV2";
import useQueryParams from "../../hooks/customHooks/useQueryParams";
import AnomaliesTabs from "./AnomaliesTabs";
import AnomaliesDetectedTab from "./AnomaliesDetectedTab";
import FetchedMonitorsTab from "./FetchedMonitorsTab";
import CreateMonitorsTab from "./CreateMonitorsTab";
import SelectWithoutRHF from "../SelectWithoutRHF";
import { useGetFetchedAnomalies, useMonitorNames, useTimePeriods } from "../../hooks/useAnomalies";
import { CLOUD_PROVIDER_AWS } from "../../utils/constants";

export const ANOMALIES_DETECTED_TAB = "anomalies-detected";
export const FETCHED_MONITORS_TAB = "fetched-monitors";
export const CREATE_MONITORS_TAB = "create-monitors";

const TABS = [
  {
    id: 0,
    label: "Anomalies Detected",
    value: ANOMALIES_DETECTED_TAB,
    Component: AnomaliesDetectedTab,
  },
  {
    id: 1,
    label: "Monitors",
    value: FETCHED_MONITORS_TAB,
    Component: FetchedMonitorsTab,
  },
  {
    id: 2,
    label: "Create Monitors",
    value: CREATE_MONITORS_TAB,
    Component: CreateMonitorsTab,
  },
];

export const MODES = {
  CREATE: "create",
  EDIT: "edit",
};

export const AnomaliesSnackBarProvider = createContext(null);

const allOption = {
  label: "All",
  value: "all",
};

const Anomalies = () => {
  const reactRouterDomHistory = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { tab: tabFromUrl } = useParams();
  const [dropDownData, setDropDownData] = useState({
    cloudProvider: "",
    accessKeyId: "",
    timePeriod: "",
    monitorNameId: "",
    //FetchedMonitorTab Filters
    monitorType: "",
    host: "",
  });
  const [mode, setMode] = useState(MODES.CREATE);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);

  const modeFromUrl = useMemo(() => {
    return queryParams.get("mode");
  }, [queryParams]);

  useEffect(() => {
    if (modeFromUrl) {
      setMode(modeFromUrl);
    }

    if (tabFromUrl) {
      setSelectedTab(TABS.find((t) => t.value === tabFromUrl));
    }
  }, [modeFromUrl, tabFromUrl]);

  const handleModeChange = (value) => {
    setMode(value);
    reactRouterDomHistory.push(`${location.pathname}?mode=${value}`);
  };

  const [openSnackbar, setOpenSnackbar] = useState({
    value: false,
    message: "",
    severity: "success",
  });

  const handleShowSnackbar = (message = "", severity = "success") => {
    setOpenSnackbar({
      value: true,
      message: message,
      severity: severity,
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar({ value: false, message: "", severity: "" });
  };

  const handleTabChange = useCallback((event, newValue) => {
    const selectedTabDetails = TABS.find((tab) => tab.id === newValue);
    setSelectedTab(selectedTabDetails);
    // reset mode to create
    setMode(MODES.CREATE);
    reactRouterDomHistory.push(`/anomalies/${selectedTabDetails.value}`);
  }, [reactRouterDomHistory]);

  const getCloudTypesQuery = useGetCloudTypes();
  const getCloudKeysQuery = useGetCloudKeys(dropDownData.cloudProvider);
  const { getTimePeriodsQuery } = useTimePeriods(selectedTab.value === ANOMALIES_DETECTED_TAB);
  const { getMonitorNamesQuery } = useMonitorNames(dropDownData.cloudProvider, dropDownData.accessKeyId, selectedTab.value === ANOMALIES_DETECTED_TAB && dropDownData.accessKeyId !== "all");
  const { getFetchedAnomaliesQuery } = useGetFetchedAnomalies(dropDownData.cloudProvider, true);

  const handleDropDownChange = (e) => {
    setDropDownData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const cloudProviderOptions = useMemo(() => {
    return getCloudTypesQuery.data?.map((cloudType) => ({
      label: cloudType.name,
      value: cloudType.name.toLowerCase(),
      // Disable rest of the cloud providers except AWS as of now because we are not supporting them
      disabled: cloudType.name.toLowerCase() !== CLOUD_PROVIDER_AWS,
    }));
  }, [getCloudTypesQuery.data]);

  const cloudProviderKeysOptions = useMemo(() => {
    const keysOptions = getCloudKeysQuery.data?.map((key) => ({
      label: key.key_label,
      value: key.id,
    })) ?? [];

    if (selectedTab.value === FETCHED_MONITORS_TAB) {
      return [allOption, ...keysOptions];
    }
    return keysOptions;
  }, [getCloudKeysQuery.data, selectedTab.value]);

  const timePeriodOptions = useMemo(() => {
    return getTimePeriodsQuery.data?.map((timePeriod) => ({
      label: `${timePeriod.name} days`,
      value: timePeriod.id,
    })) ?? [];
  }, [getTimePeriodsQuery.data]);

  const monitorNamesOptions = useMemo(() => {
    return getMonitorNamesQuery.data?.map((monitor) => ({
      label: monitor.name,
      value: monitor.id,
    })) ?? [];
  }, [getMonitorNamesQuery.data]);

  const anomalyMonitorTypeOptions = useMemo(() => {
    const monitorType = [...new Set(getFetchedAnomaliesQuery.data?.map((anomaly) => anomaly.monitor_type))];

    const options = monitorType.map((monitorType) => ({
      label: monitorType,
      value: monitorType,
    })) ?? [];

    return [allOption, ...options];
  }, [getFetchedAnomaliesQuery.data]);

  const anomalyHostOptions = useMemo(() => {
    const host = [...new Set(getFetchedAnomaliesQuery.data?.map((anomaly) => anomaly.host))];

    const options = host.map((host) => ({
      label: host,
      value: host,
    })) ?? [];

    return [allOption, ...options];
  }, [getFetchedAnomaliesQuery.data]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      cloudProvider: cloudProviderOptions?.[0]?.value || "",
    }));
  }, [cloudProviderOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      accessKeyId: cloudProviderKeysOptions?.[0]?.value || "",
    }));
  }, [cloudProviderKeysOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      timePeriod: timePeriodOptions?.[0]?.value || "",
    }));
  }, [timePeriodOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      monitorName: monitorNamesOptions?.[0]?.value || "",
    }));
  }, [monitorNamesOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      monitorType: anomalyMonitorTypeOptions?.[0]?.value || "",
    }));
  }, [anomalyMonitorTypeOptions]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      host: anomalyHostOptions?.[0]?.value || "",
    }));
  }, [anomalyHostOptions]);

  if (getCloudTypesQuery.error || getCloudKeysQuery.error || getMonitorNamesQuery.error || getTimePeriodsQuery.error) {
    return (<>Error: {getCloudTypesQuery.error?.message || getCloudKeysQuery.error?.message || getMonitorNamesQuery.error?.message || getTimePeriodsQuery.error?.message || "Something went wrong"}</>);
  }

  if (getCloudTypesQuery.isLoading || getCloudKeysQuery.isLoading || getMonitorNamesQuery.isLoading || getTimePeriodsQuery.isLoading) {
    return (
      <Box>
        <Skeleton variant="rectangular" height={"calc(100vh - 100px)"} width={"100%"} />
      </Box>
    );
  }

  return (
    <AnomaliesSnackBarProvider.Provider value={{ handleShowSnackbar }}>
      <Box>
        <Box display={"flex"} alignItems={"center"} gap={"16px"} marginBottom={"21px"}>
          <SelectWithoutRHF
            sx={{
              minWidth: 120,
            }}
            name={"cloudProvider"}
            label={"Cloud"}
            options={cloudProviderOptions}
            value={dropDownData.cloudProvider ? dropDownData.cloudProvider : ""}
            onChange={handleDropDownChange}
          />
          {selectedTab.value === ANOMALIES_DETECTED_TAB || selectedTab.value === FETCHED_MONITORS_TAB ? (
            <SelectWithoutRHF
              sx={{
                minWidth: 120,
              }}
              name={"accessKeyId"}
              label={"Key"}
              options={cloudProviderKeysOptions}
              value={dropDownData.accessKeyId ? dropDownData.accessKeyId : ""}
              onChange={handleDropDownChange}
              disabled={!dropDownData.cloudProvider}
            />
          ) : null}
          {selectedTab.value === ANOMALIES_DETECTED_TAB ? (
            <SelectWithoutRHF
              sx={{
                minWidth: 120,
              }}
              name={"timePeriod"}
              label={"Time Period"}
              options={timePeriodOptions}
              value={dropDownData.timePeriod ? dropDownData.timePeriod : ""}
              onChange={handleDropDownChange}
              disabled={!dropDownData.cloudProvider}
            />
          ) : null}
          {selectedTab.value === ANOMALIES_DETECTED_TAB ? (
            <SelectWithoutRHF
              sx={{
                minWidth: 120,
              }}
              name={"monitorName"}
              label={"Monitor Name"}
              options={monitorNamesOptions}
              value={dropDownData.monitorName ? dropDownData.monitorName : ""}
              onChange={handleDropDownChange}
              disabled={!dropDownData.cloudProvider || !dropDownData.accessKeyId}
            />
          ) : null}
          {
            selectedTab.value === FETCHED_MONITORS_TAB ? (
              <SelectWithoutRHF
                sx={{
                  minWidth: 120,
                }}
                name={"monitorType"}
                label={"Monitor Type"}
                options={anomalyMonitorTypeOptions}
                value={dropDownData.monitorType}
                onChange={handleDropDownChange}
              />
            ) : null
          }
          {
            selectedTab.value === FETCHED_MONITORS_TAB ? (
              <SelectWithoutRHF
                sx={{
                  minWidth: 120,
                }}
                name={"host"}
                label={"Host"}
                options={anomalyHostOptions}
                value={dropDownData.host}
                onChange={handleDropDownChange}
              />
            ) : null
          }
        </Box>
        <AnomaliesTabs
          TABS={TABS}
          activeMode={mode}
          handleModeChange={handleModeChange}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          selectedCloudProvider={dropDownData.cloudProvider}
          selectedAccessKey={dropDownData.accessKeyId}
          selectedTimePeriodId={dropDownData.timePeriod}
          selectedMonitorNameId={dropDownData.monitorName}
          cloudProviderKeysOptions={cloudProviderKeysOptions}
          //FetchedMonitorTab Filters
          selectedMonitorType={dropDownData.monitorType}
          selectedHost={dropDownData.host}
          getFetchedAnomaliesQuery={getFetchedAnomaliesQuery}
        />
      </Box>
      <Snackbar
        open={openSnackbar.value}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={openSnackbar.severity}
          variant="filled"
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </AnomaliesSnackBarProvider.Provider>
  );
};
export default Anomalies;
