import React, { useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { SolidButton } from "../../ui/SolidButton";
import { SmallText } from "../../ui/SmallText";
import { getLastSecondSegment, getLastSegment } from "../../utils/commonUtil";
import history from "../../utils/history";
import useAuth from "../../hooks/useAuth";
import AuthActionsSuccessState from "./AuthActionsSuccessState";

import Alert from "@mui/material/Alert";
import { H3 } from "../../ui/H3";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";
import { BottomContainer, FullScreenContainer } from "./LoginForm";
import { BrandLogo } from "./BrandLogo";
import { InputTextField } from "../../ui/InputTextField";
import { ROUTE_LOGIN } from "../../routers/routes";

const UiFields = ({ onPasswordUpdated }) => {
  const { updatePassword, isLoading, data, error } = useAuth();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [validationError, setValidationError] = useState(error);
  const token = getLastSegment(window.location);
  const part = getLastSecondSegment(window.location);

  const onPasswordChanged = (e) => {
    setValidationError(null);
    const newValue = e.target.value;
    setPassword(newValue);
  };

  const onConfirmPasswordChanged = (e) => {
    setValidationError(null);
    const newValue = e.target.value;
    setConfirmPassword(newValue);
  };

  useEffect(() => {
    if (data?.data?.message) {
      onPasswordUpdated(true);
    }

    if (error) {
      setValidationError(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <H3>Set a new password</H3>
      <SmallText
        sx={{
          mt: 4,
        }}
      >
        Create a new password with atleast 8 characters. Try that it differs
        from the previous one for security
      </SmallText>
      <Box mt={3} mb={1}>
        <InputTextField
          fullWidth
          label="Enter new password"
          type="password"
          onChange={onPasswordChanged}
        />
        <br />
        <br />
        <InputTextField
          fullWidth
          label="Re-enter same password"
          type="password"
          onChange={onConfirmPasswordChanged}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              updatePassword(ev.target.value, token, "Mg");
              ev.preventDefault();
            }
          }}
        />
      </Box>
      <BottomContainer>
        {validationError && (
          <Alert severity="warning" sx={{ my: 1 }}>
            {validationError
              ? validationError
              : `Failed to send password reset link. Please try again`}
          </Alert>
        )}
        {isLoading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <SolidButton
            width="50%"
            variant="contained"
            color="primary"
            disabled={
              !(
                password &&
                confirmPassword &&
                confirmPassword?.length > 7 &&
                password?.length > 7 &&
                confirmPassword === password
              )
            }
            onClick={() => {
              updatePassword(password, token, part);
            }}
          >
            Update Password
          </SolidButton>
        )}
      </BottomContainer>
    </Box>
  );
};

const SetPassword = () => {
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);

  const onPasswordUpdated = () => {
    setUpdatePasswordSuccess(true);
  };

  return (
    <FullScreenContainer>
      <LeftPane />
      <RightPane>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "top",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* <Box pt={8} component="img" src={CloudPulseLogo} /> */}
            <BrandLogo />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
              }}
              p={8}
            >
              {updatePasswordSuccess ? (
                <AuthActionsSuccessState
                  title="Successful"
                  message="Congratulations! Your password has been successfully changed. Please proceed to login"
                  btnLabel="Login"
                  btnAction={() => {
                    history.push(ROUTE_LOGIN);
                  }}
                />
              ) : (
                <UiFields onPasswordUpdated={onPasswordUpdated} />
              )}
            </Box>
          </Box>
        </Box>
      </RightPane>
    </FullScreenContainer>
  );
};

export default SetPassword;
