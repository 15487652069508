import { useQuery } from "@tanstack/react-query";

import { fetch } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { CACHED_DATA_CACHE_TIME } from "../config/config";
import { QUERY_KEY } from "../utils/queryKeys";

const getInvitedMemberData = async (token) => {
  try {
    const resp = await fetch(`/auth/signup/?token=${token}`);
    return resp?.data ?? [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useInvitation = (token) => {
  const getInvitedMemberDataQuery = useQuery({
    queryKey: [QUERY_KEY.INVITED_MEMBER_DATA, token],
    queryFn: () => getInvitedMemberData(token),
    refetchOnWindowFocus: false,
    enabled: !!token,
    cacheTime: CACHED_DATA_CACHE_TIME,
    staleTime: CACHED_DATA_CACHE_TIME,
  });

  return { getInvitedMemberDataQuery };
};
