import React, { useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { getLogoForType } from "../../../utils/commonUtil";
import { RECOMMENDATION_TYPE_OPTIMAL_V2 } from "../../../utils/constants";
import OptimalRecommenationEmptyState from "../../../assets/svg/edge-cases/recommendations-error-state.svg";
import DetailedInsightGraphs from "../../common/DetailedInsightGraphs";

const EmptyStateView = ({ selectedVm, areAllVmsOptimal }) => {
  if (areAllVmsOptimal && selectedVm) {
    return (
      <DetailedInsightGraphs selectedVm={selectedVm} />
    );
  }
  return (
    <>
      {(!selectedVm?.recommended_action &&
        selectedVm?.percentage_saved === 0) ||
      selectedVm?.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
        <DetailedInsightGraphs selectedVm={selectedVm} />
      ) : (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 4,
            textAlign: "center",
            width: "100%",
            height: "100%",
            maxHeight: selectedVm ? "calc(100% - 192px)" : "100%",
            margin: "auto",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Box
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <Box
              component="img"
              src={OptimalRecommenationEmptyState}
              style={{
                width: "30%",
              }}
            />
            <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
              Woops! There's nothing to show right now. Try selecting an
              alternative VM.
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};

const CrossCloudRecommendationsSection = ({
                                            vmListToShow,
                                            selectedVm,
                                            selectedCloudProvider,
                                            selectedCrossCloudProvider,
                                          }) => {

  const areAllVmsOptimal = useMemo(() => {
    if (!vmListToShow || vmListToShow.length === 0) {
      return false;
    }
    return vmListToShow.every(
      (vm) => vm.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2,
    );
  }, [vmListToShow]);

  const recommendation = selectedVm?.recommendations?.[0];
  const vmPercentageSaved = selectedVm?.percentage_saved;

  return (
    <Box gridColumn="span 5" style={{ height: "100%" }}>
      <>
        {selectedVm ? (
          <Card style={{ marginBottom: "20px" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      src={getLogoForType(selectedCloudProvider)}
                      alt={`${selectedCloudProvider} Logo`}
                      width="30px"
                      height="30px"
                    />
                    <Tooltip title={selectedVm?.instance_name}>
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        style={{
                          maxWidth: "20ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedVm?.instance_name}
                      </Typography>
                    </Tooltip>
                    <Typography variant="subtitle2">
                      {selectedVm?.machine_type}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "20px" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1">Memory</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          {selectedVm?.memory}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1">vCPU</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          {selectedVm?.vcpu}
                        </Typography>
                      </Box>
                    </Box>
                    {selectedVm?.ocpu && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">oCPU</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {selectedVm?.ocpu}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Architecture
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          {selectedVm?.architecture}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" textAlign="center">
                          Avg. CPU Utilization
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          {selectedVm?.avg_cpu_utilization}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" textAlign="center">
                          Max CPU Utilization
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2">
                          {selectedVm?.max_cpu_utilization}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" textAlign="center">
                          Physical Processor
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title={selectedVm?.physical_processor}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              maxWidth: "20ch",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {selectedVm?.physical_processor}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h5" fontWeight="bold">
                    {selectedVm?.cost_per_month?.str}
                  </Typography>
                  <Typography variant="caption">Current Price</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ) : null}

        {vmListToShow?.length === 0 ||
        selectedVm?.recommendations.length === 0 ||
        areAllVmsOptimal ? (
          <EmptyStateView
            selectedVm={selectedVm}
            areAllVmsOptimal={areAllVmsOptimal}
          />
        ) : (
          <>
            <Card style={{ marginBottom: "20px" }}>
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        src={getLogoForType(selectedCrossCloudProvider)}
                        width="30px"
                        height="30px"
                      />
                      <Tooltip title={recommendation?.machine_type}>
                        <Typography
                          variant="h4"
                          fontWeight="bold"
                          style={{
                            maxWidth: "20ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {recommendation?.machine_type}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box style={{ display: "flex", gap: "20px" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Memory</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {recommendation?.memory}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">vCPU</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {recommendation?.vcpu}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            Architecture
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {recommendation?.architecture}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1" textAlign="center">
                            Avg. CPU Utilization
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {recommendation?.avg_cpu_utilization}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1" textAlign="center">
                            Max CPU Utilization
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">
                            {recommendation?.max_cpu_utilization}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1" textAlign="center">
                            Physical Processor
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title={recommendation?.physical_processor}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                maxWidth: "20ch",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              {recommendation?.physical_processor}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box color={vmPercentageSaved >= 0 ? "green" : "red"}>
                    <Typography variant="h5" fontWeight="bold">
                      {recommendation?.machine_price?.str}
                    </Typography>
                    <Typography variant="caption">Current Price</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <DetailedInsightGraphs selectedVm={selectedVm} />
          </>
        )}
      </>
    </Box>
  );
};

export default CrossCloudRecommendationsSection;
