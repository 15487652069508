export const QUERY_KEY = {
  TOTAL_COST: "total-cost",
  CLOUD_COST_TREND_FOR_ALL: "cloud-cost-trend-for-all",
  TOP_5_ACC_COMPUTE_EFFECIENCY_V2: "top-5-acc-compute-effeciency-v2",
  ACCOUNT_COMPUTE_EFFECIENCY_V2: "account-compute-effeciency-v2",
  TOKEN_LIST_V2: "token-list-v2",
  COST_BY_SERVICE_V2: "cost-by-service-v2",
  COST_BY_REGION_V2: "cost-by-region-v2",
  COST_BY_ACCOUNT_V2: "cost-by-account-v2",
  DASHBOARD_RECOMMENDATIONS: "dashboard-recommendation",
  CLOUD_TYPES: "recommendations-cloud-types",
  CLOUD_KEYS: "recommendations-cloud-keys",
  RECOMMENDATIONS_CLOUD_SERVICES: "recommendations-cloud-services",
  RECOMMENDATIONS_TYPES: "recommendations-types",
  RECOMMENDATIONS_RESULTS: "recommendations-results",
  CC_CLOUD_REGIONS: "cc-cloud-regions",
  CC_RECOMMENDATIONS_RESULTS: "cc-recommendations-results",
  AWS_CLOUD_FORMATION: "aws-cloud-formation",
  AWS_CLOUD_FORMATION_URL: "aws-cloud-formation-url",
  CHECK_IF_DATA_FETCHED: "check-if-data-fetched",
  USER_ROLES: "user-roles",
  TEAM_MEMBERS: "team-members",
  INVITED_MEMBER_DATA: "invited-member-data",
  AUTHENTICATED_USER_INFO: "authenticated-user-info",
  AVAILABLE_PRICING_PLANS: "available-pricing-plans",
  BUDGET_INSIGHTS: "budget-insights",
  BUDGET_LIST: "budget-list",
  BUDGET_SCOPE_LIST: "budget-scope-list",
  BUDGET_COST_DIMENSION: "budget-cost-dimension",
  AGGREGATE_COST_LIST: "aggregate-cost-list",
  BUDGET_FREQUENCY_LIST: "budget-frequency-list",
  BUDGET_TRIGGER_ON_LIST: "budget-trigger-on-list",
  BUDGET_DIMENSION_VALUES: "budget-dimension-values",
  BUDGET_NOTIFICATION_CHANNELS: "budget-notification-channels",
  BUDGET_PREVIEW_GRAPH_DATA: "budget-preview-graph-data",
  BUDGET: "budget",
  CLOUD_MONITORING_DATA: "cloud-monitoring-data",
  ANOMALIES_DETECTED: "anomalies-detected",
  FETCHED_ANOMALIES: "fetched-anomalies",
  MONITOR_TYPES: "monitor-types",
  ANOMALIES_FREQUENCY_LIST: "anomalies-frequency-list",
  ANOMALIES_TRIGGER_ON_LIST: "anomalies-trigger-on-list",
  ANOMALY: "anomaly",
  FETCHED_MONITOR_NAMES: "fetched-monitor-names",
  ANOMALY_TIME_PERIODS: "anomaly-time-periods",
  ANOMALY_LINKED_ACCOUNTS: "anomaly-linked-accounts",
};
