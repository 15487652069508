import { getCookie, setCookie } from "./storageUtil";
import { STORAGE_AUTH_TOKEN, STORAGE_USER_INFO } from "./constants";

export let getToken = () => {
  return getCookie(STORAGE_AUTH_TOKEN);
};

export let getInfo = (updatedInfo = null) => {
  if (updatedInfo) {
    const existingUserData = JSON.parse(getCookie(STORAGE_USER_INFO));
    // when user role is changed we have to update the user info in cookie as well
    // to avoid race condition we are using this user info to check if the user is authenticated and for other details as well
    if (
      updatedInfo.id === existingUserData.id &&
      updatedInfo.role.id !== existingUserData.role.id
    ) {
      const updatedUserData = {
        ...existingUserData,
        role: {
          id: updatedInfo.role.id,
          name: updatedInfo.role.name,
        },
      };
      setCookie(STORAGE_USER_INFO, JSON.stringify(updatedUserData), 1);
    }
  }
  return JSON.parse(getCookie(STORAGE_USER_INFO));
};

export let isAuthenticated = () => {
  const token = getCookie(STORAGE_AUTH_TOKEN);
  const result = token !== null;
  return result;
};

export let hasCloudCredentials = () => {
  const userInfo = getInfo();
  const result = userInfo != null && userInfo.has_token;
  return result;
};
