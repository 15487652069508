import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";

import useProfile from "../../hooks/useProfile";
import { PROFILE_FORM_MODES } from "./ProfileAndPlanForAdmin";
import InputField from "../InputField/InputField";

const ResetPassword = ({ setProfileFormMode, snackbarContext }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    mode: "onChange",
  });

  const { resetPasswordMutation } = useProfile();

  const handleResetPassword = (data) => {
    resetPasswordMutation.mutate(data, {
      onSuccess: (data) => {
        snackbarContext.handleShowSnackbar(
          data.message || "Password reset successfully",
          "success"
        );
        reset();
        setProfileFormMode(PROFILE_FORM_MODES.INITIAL);
      },
      onError: (error) => {
        snackbarContext.handleShowSnackbar(
          error.message || "Something went wrong",
          "error"
        );
      },
    });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Box
          sx={{
            display: "grid",
            gap: "20px",
          }}
        >
          <InputField
            control={control}
            name="old_password"
            type="password"
            label="Re-enter current password"
            rules={{
              required: "Current Password is required",
            }}
          />
          <InputField
            control={control}
            name="new_password"
            type="password"
            label="Set new password"
            rules={{
              required: "New Password is required",
            }}
          />
          <InputField
            control={control}
            name="confirm_password"
            type="password"
            label="Confirm new Password"
            rules={{
              required: "Confirm new password is required",
              validate: (value) => {
                if (value !== watch("new_password")) {
                  return "New password and Confirm password should match";
                }
                return true;
              },
            }}
          />

          <Box
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setProfileFormMode(PROFILE_FORM_MODES.INITIAL)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                padding: "4px 24px",
              }}
              type="submit"
              disabled={
                !isValid || isSubmitting || resetPasswordMutation.isPending
              }
            >
              {resetPasswordMutation.isPending ? (
                <CircularProgress size={24} />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPassword;
