import { useQuery } from "@tanstack/react-query";

import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";
import { fetch } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { QUERY_KEY } from "../utils/queryKeys";

// This is when All cloud filter is selected on dashboard
const getTop5AccountComputeEfficiency = async () => {
  try {
    const response = await fetch(
      "/dashboard/top-five-account-compute-efficiency/"
    );
    return response.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

// This is when particular cloud is selected on dashboard
const getAccountComputeEfficiency = async (keyId, cloudProvider) => {
  try {
    const response = await fetch(
      `/dashboard/account-compute-efficiency/${cloudProvider}/?key_id=${keyId}`
    );
    return response.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useSpendEffeciencyV2 = (keyId = null, cloudProvider = null) => {
  const getTop5AccountComputeEfficiencyQuery = useQuery({
    queryKey: [QUERY_KEY.TOP_5_ACC_COMPUTE_EFFECIENCY_V2],
    queryFn: getTop5AccountComputeEfficiency,
    enabled: !keyId && !cloudProvider,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  const getAccountComputeEffeciencyQuery = useQuery({
    queryKey: [QUERY_KEY.ACCOUNT_COMPUTE_EFFECIENCY_V2, keyId, cloudProvider],
    queryFn: () => getAccountComputeEfficiency(keyId, cloudProvider),
    enabled: Boolean(keyId && cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return {
    getTop5AccountComputeEfficiencyQuery,
    getAccountComputeEffeciencyQuery,
  };
};
