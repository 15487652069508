import React from "react";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";

import { H4 } from "../../../ui/H4";
import VmsSection from "./VmsSection";
import RecommendationsSection from "./RecommendationsSection";
import EmptyStateImg from "../../../assets/svg/edge-cases/recommendations-error-state.svg";

const RecommendationArea = ({
  isLoading,
  vmListToShow,
  recommendationDataError,
  selectedVm,
  selectedCloudProvider,
  selectedAccessKey,
  handleSelectVm,
  recommendationData,
  accessKeys,
  areAllVmsOptimal,
  selectedCloudService,
}) => {
  if (accessKeys && accessKeys.length === 0) {
    return (
      <Box
        style={{
          gridColumn: "span 6",
          height: "calc(100vh - 270px)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            placeItems: "center",
          }}
        >
          <Box component="img" src={EmptyStateImg} alt="Empty State" />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            Recommendations cannot be generated. Consider adding the key.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <>
        <Box
          style={{
            gridColumn: "span 2",
            minHeight: "calc(100vh - 290px)",
            maxHeight: "calc(100vh - 290px)",
          }}
        >
          <Skeleton
            animation={"wave"}
            variant="rectangular"
            height="100%"
            style={{ gridColumn: "span 2" }}
          />
        </Box>
        <Box
          style={{
            gridColumn: "span 4",
            minHeight: "calc(100vh - 290px)",
            maxHeight: "calc(100vh - 290px)",
          }}
        >
          <Skeleton
            animation={"wave"}
            variant="rectangular"
            height="100%"
            style={{ gridColumn: "span 4" }}
          />
        </Box>
      </>
    );
  }

  if (!vmListToShow || vmListToShow?.length === 0) {
    return (
      <Box
        style={{
          gridColumn: "span 6",
          height: "calc(100vh - 270px)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            placeItems: "center",
          }}
        >
          <Box component="img" src={EmptyStateImg} alt="Empty State" />
          <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
            {recommendationData?.message ||
              recommendationDataError?.message ||
              "No Vms found under this key."}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        style={{
          gridColumn: "span 2",
          minHeight: "calc(100vh - 290px)",
          maxHeight: "calc(100vh - 290px)",
        }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent style={{ height: "100%" }}>
            <VmsSection
              vmsList={vmListToShow}
              vmsError={recommendationDataError}
              selectedVm={selectedVm}
              handleSelectVm={handleSelectVm}
              selectedCloudService={selectedCloudService}
            />
          </CardContent>
        </Card>
      </Box>
      {selectedAccessKey && selectedVm ? (
        <RecommendationsSection
          cloudProvider={selectedCloudProvider}
          recommendations={selectedVm.recommendations}
          selectedVm={selectedVm}
          accessToken={selectedAccessKey}
          areAllVmsOptimal={areAllVmsOptimal}
          selectedCloudService={selectedCloudService}
        />
      ) : (
        <>
          <Card
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gridColumn: "span 4",
            }}
          >
            <CardContent>
              <H4>
                {recommendationData?.message
                  ? recommendationData?.message
                  : "Select a VM to see the recommendations"}
              </H4>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default RecommendationArea;
