import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { destroy, fetch, store, update } from "../utils/httpUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import { CACHED_DATA_CACHE_TIME, CACHED_DATA_STALE_TIME } from "../config/config";

const getAnomaliesDetected = async (cloudProvider, key_id, selectedTimePeriodId, selectedMonitorNameId) => {
  try {
    const url = `/anomalies/detected/?cloud_name=${cloudProvider}&key_id=${key_id}&days=${selectedTimePeriodId}&monitor_id=${selectedMonitorNameId}`;
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useAnomaliesDetected = (cloudProvider, key_id, selectedTimePeriodId, selectedMonitorNameId) => {
  const getAnomaliesDetectedQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALIES_DETECTED, cloudProvider, key_id, selectedTimePeriodId, selectedMonitorNameId],
    queryFn: () => getAnomaliesDetected(cloudProvider, key_id, selectedTimePeriodId, selectedMonitorNameId),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudProvider && !!key_id && !!selectedMonitorNameId,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getAnomaliesDetectedQuery };
};

const getFetchedAnomalies = async (cloudProvider) => {
  try {
    const resp = await fetch(`/anomalies/list/?cloud_name=${cloudProvider}`);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetFetchedAnomalies = (cloudProvider, shouldFetch = false) => {
  const getFetchedAnomaliesQuery = useQuery({
    queryKey: [QUERY_KEY.FETCHED_ANOMALIES, cloudProvider],
    queryFn: () => getFetchedAnomalies(cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudProvider && shouldFetch,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getFetchedAnomaliesQuery };
};

const deleteAnomalies = async (ids = []) => {
  try {
    const resp = await destroy(`/anomalies/?ids=[${ids}]`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useDeleteAnomalies = () => {
  const queryClient = useQueryClient();
  const deleteAnomaliesMutation = useMutation({
    mutationFn: ({ idsToDelete }) => deleteAnomalies(idsToDelete),
    onSuccess: (data, variables) => {
      const anomalyIds = [...variables.idsToDelete];
      queryClient.setQueryData([QUERY_KEY.ANOMALIES_DETECTED, variables.selectedCloudProvider, variables.selectedAccessKey], (oldData) => {
        if (oldData && oldData.anomalies) {
          return Object.entries(oldData.anomalies).map(([key, value]) => {
            return value.filter((anomaly) => !anomalyIds.includes(anomaly.id));
          });
        }
      });

      queryClient.setQueryData([QUERY_KEY.FETCHED_ANOMALIES, variables.selectedCloudProvider], (oldData) => {
        if (oldData) {
          return oldData?.filter((anomaly) => !anomalyIds.includes(anomaly.id));
        }
      });
      return data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { deleteAnomaliesMutation };
};

const getMonitorTypes = async () => {
  try {
    const resp = await fetch(`/anomalies/monitor-types/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetMonitorTypes = () => {
  const getMonitorTypesQuery = useQuery({
    queryKey: [QUERY_KEY.MONITOR_TYPES],
    queryFn: getMonitorTypes,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getMonitorTypesQuery };
};

const getFrequencyList = async () => {
  try {
    const resp = await fetch(`/anomalies/frequency-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useFrequencyList = () => {
  const getFrequencyListQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALIES_FREQUENCY_LIST],
    queryFn: getFrequencyList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getFrequencyListQuery };
};

const getTriggerOnList = async () => {
  try {
    const resp = await fetch(`/anomalies/triggeron-list/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useTriggerOnList = () => {
  const getTriggerOnListQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALIES_TRIGGER_ON_LIST],
    queryFn: getTriggerOnList,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getTriggerOnListQuery };
};

const createAnomaly = async (formData) => {
  try {
    const url = `/anomalies/`;
    const resp = await store(url, formData);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useCreateAnomaly = () => {
  const queryClient = useQueryClient();
  const createAnomalyMutation = useMutation({
    mutationFn: (formData) => createAnomaly(formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.ANOMALIES_DETECTED]);
      return data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { createAnomalyMutation };
};

const getAnomaly = async (anomalyId, cloudProvider) => {
  try {
    const resp = await fetch(`/anomalies/${anomalyId}/?cloud_name=${cloudProvider}`);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetAnomaly = (anomalyId, cloudProvider) => {
  const getAnomalyQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALY, cloudProvider, anomalyId],
    queryFn: () => getAnomaly(anomalyId, cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!anomalyId && !!cloudProvider,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getAnomalyQuery };
};

const updateAnomaly = async (formData) => {
  const { anomalyId, ...restFormData } = formData;
  try {
    const resp = await update(`/anomalies/${anomalyId}/`, restFormData);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useUpdateAnomaly = () => {
  const queryClient = useQueryClient();
  const updateAnomalyMutation = useMutation({
    mutationFn: (formData) => updateAnomaly(formData),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([QUERY_KEY.ANOMALIES_DETECTED]);
      queryClient.invalidateQueries([QUERY_KEY.FETCHED_ANOMALIES]);
      return data.data;
    },
    onError: (error) => {
      return error;
    },
  });

  return { updateAnomalyMutation };
};

const getMonitorNames = async (cloudProvider, accessKeyId) => {
  try {
    const resp = await fetch(`/anomalies/monitor-name/?cloud_name=${cloudProvider}&key_id=${accessKeyId}`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useMonitorNames = (cloudProvider, accessKeyId, shouldFetch = false) => {
  const getMonitorNamesQuery = useQuery({
    queryKey: [QUERY_KEY.FETCHED_MONITOR_NAMES, cloudProvider, accessKeyId],
    queryFn: () => getMonitorNames(cloudProvider, accessKeyId),
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!cloudProvider && !!accessKeyId && shouldFetch,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getMonitorNamesQuery };
};

const getTimePeriods = async () => {
  try {
    const resp = await fetch(`/anomalies/days/`);
    return resp?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useTimePeriods = (shouldFetch = false) => {
  const getTimePeriodsQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALY_TIME_PERIODS],
    queryFn: () => getTimePeriods(),
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getTimePeriodsQuery };
};

const getLinkedAccounts = async (cloudProvider, accessKeyId, monitorTypeId) => {
  try {
    const url = `/anomalies/dimension-values/?cloud_name=${cloudProvider}&key_id=${accessKeyId}&dimension_id=${monitorTypeId}`;
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useLinkedAccounts = (cloudProvider, accessKeyId, monitorTypeId, shouldFetch = false) => {
  const getLinkedAccountsQuery = useQuery({
    queryKey: [QUERY_KEY.ANOMALY_LINKED_ACCOUNTS, cloudProvider, accessKeyId, monitorTypeId],
    queryFn: () => getLinkedAccounts(cloudProvider, accessKeyId, monitorTypeId),
    refetchOnWindowFocus: false,
    enabled: !!cloudProvider && !!accessKeyId && !!monitorTypeId && shouldFetch,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return { getLinkedAccountsQuery };
};

